import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
// import { createTrans } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { createTrans } from '../redux/actions/user.actions';

const CreateTransaction = ({ userCon }) => {
  const dispatch = useDispatch();
  const [percent, setPercent] = useState(0);
  const [coin, setCoin] = useState('');
  const [deposit, setDeposit] = useState(0);
  const [returned, setReturned] = useState('');

  const email = userCon?.email;

  const name = userCon?.name;

  const username = userCon?.username;

  const user = userCon?._id;

  const { loading, error, res, success } = useSelector(
    (state) => state?.createTrans
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (deposit === 0 || percent === 0) {
      alert('Enter a valid Investment amount and plan.');
    } else {
      dispatch(
        createTrans(email, deposit, percent, name, username, user, coin)
      );
    }
  };
  const handleCalculate = () => {
    if (deposit !== 0 && percent !== 0) {
      const answer = (Number(deposit) * Number(percent)).toFixed(2);
      setReturned(answer);
    } else {
      alert('Enter a valid Investment amount and plan.');
    }
  };

  useEffect(() => {
    if (success) {
      setReturned('');
      setDeposit(0);
      setPercent(0);
    }
  }, [success]);
  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Row className="justify-content-center">
      <Col className="py-2 my-2" md={6}>
        <div className="form-container">
          <Form onSubmit={handleSubmit} className="form">
            {success && <Message variant="success">{res?.message}</Message>}
            <Form.Group controlId="formGridInvestment">
              <Form.Label>($) Investment Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Amount"
                onChange={(e) => setDeposit(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGridInvestmentCoin" className="mt-3">
              <Form.Label>Coin</Form.Label>
              <Form.Control
                as="select"
                type="number"
                placeholder="Select Crypto name"
                onChange={(e) => {
                  setCoin(e.target.value);
                }}
              >
                <option>Choose...</option>
                <option value="bitcoin">Bitcoin</option>
                {/* <option value='litecoin'>Litecoin</option> */}
                <option value="ethereum">Ethereum</option>
                <option value='doge'>Dogecoin</option>
                <option value="btccash">Bitcoincash</option>
                <option value="usdt">USDT</option>
                <option value="tron">Tron</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>($) Investment Amount</Form.Label>
              <Form.Control
                as="select"
                defaultValue="1.10"
                onChange={(e) => {
                  setPercent(e.target.value);
                }}
              >
                {/* <option>Choose...</option> */}
                <option value="1.10"> Beginner Plan </option>
                <option value="1.15"> Premium Plan</option>
                <option value="1.35"> Silver Plan</option>
                <option value="1.45"> Gold Plan</option>
              </Form.Control>
            </Form.Group>
            <Button
              type="button"
              onClick={() => handleCalculate()}
              variant="outline-info"
              className="my-4"
            >
              {' '}
              Calculate Deposit
            </Button>
            <Form.Group controlId="formGridReturn">
              <Form.Label>($) Return</Form.Label>
              <Form.Control
                type="number"
                placeholder="Return amount"
                readOnly
                value={returned}
              />
            </Form.Group>

            {/* <Button
              type="button"
              onClick={() => handleCalculate()}
              size="sm"
              variant="secondary"
            >
              calculate Investment
            </Button> */}
            <br />
            <Button
              type="submit"
              size="lg"
              className="d-block mx-auto"
              variant="outline-primary"
            >
              Create investment
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default CreateTransaction;
