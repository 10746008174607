import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { variables } from '../../../data/variables';
import {
  userProfile,
  logoutActions,
} from '../../../redux/actions/user.actions';

import Loader from '../../Loader';

const ViewDetails = () => {
  const dispatch = useDispatch();

  const { loading, success, res, error } = useSelector(
    (state) => state.userProfile
  );

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);

  return (
    <section
      className=' flex flex-wrap'
      // style={{ minHeight: 'calc(100vh - 61px)' }}
    >
      <div className='w-full max-w-[700px] py-6 bg-white rounded-xl m-4'>
        <div className='border-b p-2'>User Details</div>
        {loading ? (
          <Loader />
        ) : (
          <div className='p-2 my-6 '>
            <h1 className='text-lg sm:text-xl font-bold text-center'>
              {res?.user?.name}
            </h1>

            <div className='text-center my-3 font-medium'>
              <p className='border border-r-0 border-l-0 py-3'>
                Email: {res?.user?.email}
              </p>
              <p className='border border-r-0 border-l-0 py-3'>
                Phone: {res?.user?.phone}
              </p>
              <p className='border border-r-0 border-l-0 py-3'>
                Address: {res?.user?.address}
              </p>
              <p className='border border-r-0 border-l-0 py-3'>
                Referral Link: {variables?.clientUrl}/register?ref=
                {res?.user?._id}
              </p>
              <p className='border border-r-0 border-l-0 py-3'>
                Reference Account: - Account
              </p>
              <p className='border border-r-0 border-l-0 py-3'>
                Username: {res?.user?.username}
              </p>

              <div className='text-center'>
                <Link
                  to='/dashboard/edit-details'
                  className='border border-r-0 border-l-0 py-3 bg-teal-500 text-white flex w-full justify-center'
                >
                  Edit User
                </Link>
              </div>
              <div className='text-center'>
                <Link
                  to='/dashboard/edit-details'
                  className='border border-r-0 border-l-0 py-3 bg-red-500 text-white flex w-full justify-center'
                >
                  Change Password
                </Link>
              </div>
              <div className='text-center'>
                <p
                  to='/dashboard/edit-details'
                  className='border border-r-0 border-l-0 py-3 bg-red-700 cursor-pointer text-white flex w-full justify-center'
                  onClick={() => {
                    dispatch(logoutActions());
                  }}
                >
                  Logout
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div></div>
    </section>
  );
};

export default ViewDetails;
