import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../components/Header';
import { faqData } from './faqData';

const Faq = () => {
  return (
    <section className='py-10 pb-16'>
      <Header text='Frequently Asked Questions' />
      <Container>
        <div className='flex gap-10 flex-col'>
          {faqData?.map((x, i) => {
            return (
              <details key={i}>
                <summary className='text-lg md:text-xl text-slate-800 font-medium'>
                  {x?.header}
                </summary>
                <p
                  dangerouslySetInnerHTML={{ __html: x?.paragraph }}
                  className='py-2'
                />
              </details>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Faq;
