import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userRegisterReducer,
  userLoginReducer,
  userListReducer,
  userDeleteReducer,
  makeUserAdminReducer,
  createUserReducer,
  getUserByIdReducer,
  createTransAlertReducer,
  getTransAlertReducer,
  adminGetTransAlertReducer,
  createTransReducer,
  adminGetTransReducer,
  markTransAllReducer,
  markTransPartReducer,
  userProfileReducer,
  createTransFromBReducer,
  createBonusReducer,
  userUpdateProfileReducer,
  userUpdatePictureReducer,
  requestWithdrawalReducer,
  withdrawalRequestsReducer,
  approveWithdrawalRequestsReducer,
  getTotalPaidReducer,
  addWalletReducer,
  getWalletReducer,
  getTransReducer,
  updateIdCardReducer,
  userLoanReducer,
  pendingLoanRequestsReducer,
  approveLoanRequestsReducer,
  allApprovedLoansReducer,
  allUserLoansReducer,
  userVerifyReducer,
  resendVerificationReducer,
  getUserDetailsReducer,
  kycVerificationReducer,
  editUserDetailsReducer,
  adminGetTransCurrentReducer,
  topUpTransReducer,
} from './reducers/user.reducers';

const reducer = combineReducers({
  registerUser: userRegisterReducer,
  userLogin: userLoginReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  makeUserAdmin: makeUserAdminReducer,
  userCreate: createUserReducer,
  getUser: getUserByIdReducer,
  userCreateTransAlert: createTransAlertReducer,
  userGetTransAlert: getTransAlertReducer,
  adminGetTransAlert: adminGetTransAlertReducer,
  adminGetTransCurrent: adminGetTransCurrentReducer,
  createTrans: createTransReducer,
  topUpTrans: topUpTransReducer,
  adminGetTrans: adminGetTransReducer,
  markTransAll: markTransAllReducer,
  markTransPart: markTransPartReducer,
  userProfile: userProfileReducer,
  createTransFromB: createTransFromBReducer,
  createBonus: createBonusReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdatePicture: userUpdatePictureReducer,
  requestWithdrawal: requestWithdrawalReducer,
  withdrawalRequests: withdrawalRequestsReducer,
  approveWithdrawalRequests: approveWithdrawalRequestsReducer,
  getTotalPaid: getTotalPaidReducer,
  addWallet: addWalletReducer,
  getWallets: getWalletReducer,
  getTrans: getTransReducer,
  idCardUpdate: updateIdCardReducer,
  loanUser: userLoanReducer,
  loanRequests: pendingLoanRequestsReducer,
  loanApproval: approveLoanRequestsReducer,
  loansApprovedAll: allApprovedLoansReducer,
  loansUserAll: allUserLoansReducer,
  userVerify: userVerifyReducer,
  resendVerification: resendVerificationReducer,

  userDetails: getUserDetailsReducer,
  editUserDetails: editUserDetailsReducer,
  kycVerification: kycVerificationReducer,
});

// Session storage matters
const userInfoFromStorage = sessionStorage.getItem('userInfo')
  ? JSON.parse(sessionStorage.getItem('userInfo'))
  : null;

// initial state
const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
