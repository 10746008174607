export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL ';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL';

export const RESEND_VERIFICATION_REQUEST = 'RESEND_VERIFICATION_REQUEST';
export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_FAIL = 'RESEND_VERIFICATION_FAIL';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_MAKE_ADMIN_REQUEST = 'USER_MAKE_ADMIN_REQUEST';
export const USER_MAKE_ADMIN_SUCCESS = 'USER_MAKE_ADMIN_SUCCESS';
export const USER_MAKE_ADMIN_FAIL = 'USER_MAKE_ADMIN_FAIL';

export const USER_CREATE_USER_REQUEST = 'USER_CREATE_USER_REQUEST';
export const USER_CREATE_USER_SUCCESS = 'USER_CREATE_USER_SUCCESS';
export const USER_CREATE_USER_FAIL = 'USER_CREATE_USER_FAIL';

export const USER_GET_BY_ID_REQUEST = 'USER_GET_BY_ID_REQUEST';
export const USER_GET_BY_ID_SUCCESS = 'USER_GET_BY_ID_SUCCESS';
export const USER_GET_BY_ID_FAIL = 'USER_GET_BY_ID_FAIL';

export const USER_CREATE_ALERT_REQUEST = 'USER_CREATE_ALERT_REQUEST';
export const USER_CREATE_ALERT_SUCCESS = 'USER_CREATE_ALERT_SUCCESS';
export const USER_CREATE_ALERT_FAIL = 'USER_CREATE_ALERT_FAIL';

export const USER_GET_TRANS_ALERTS_REQUEST = 'USER_GET_TRANS_ALERTS_REQUEST';
export const USER_GET_TRANS_ALERTS_SUCCESS = 'USER_GET_TRANS_ALERTS_SUCCESS';
export const USER_GET_TRANS_ALERTS_FAIL = 'USER_GET_TRANS_ALERTS_FAIL';

export const ADMIN_GET_TRANS_ALERTS_REQUEST = 'ADMIN_GET_TRANS_ALERTS_REQUEST';
export const ADMIN_GET_TRANS_ALERTS_SUCCESS = 'ADMIN_GET_TRANS_ALERTS_SUCCESS';
export const ADMIN_GET_TRANS_ALERTS_FAIL = 'ADMIN_GET_TRANS_ALERTS_FAIL';

export const ADMIN_GET_TRANS_CURRENT_REQUEST =
  'ADMIN_GET_TRANS_CURRENT_REQUEST';
export const ADMIN_GET_TRANS_CURRENT_SUCCESS =
  'ADMIN_GET_TRANS_CURRENT_SUCCESS';
export const ADMIN_GET_TRANS_CURRENT_FAIL = 'ADMIN_GET_TRANS_CURRENT_FAIL';

export const ADMIN_CREATE_TRANS_REQUEST = 'ADMIN_CREATE_TRANS_REQUEST';
export const ADMIN_CREATE_TRANS_SUCCESS = 'ADMIN_CREATE_TRANS_SUCCESS';
export const ADMIN_CREATE_TRANS_FAIL = 'ADMIN_CREATE_TRANS_FAIL';
export const ADMIN_CREATE_TRANS_RESET = 'ADMIN_CREATE_TRANS_RESET';

export const ADMIN_TOPUP_TRANS_REQUEST = 'ADMIN_TOPUP_TRANS_REQUEST';
export const ADMIN_TOPUP_TRANS_SUCCESS = 'ADMIN_TOPUP_TRANS_SUCCESS';
export const ADMIN_TOPUP_TRANS_FAIL = 'ADMIN_TOPUP_TRANS_FAIL';
export const ADMIN_TOPUP_TRANS_RESET = 'ADMIN_TOPUP_TRANS_RESET';

export const ADMIN_GET_TRANS_REQUEST = 'ADMIN_GET_TRANS_REQUEST';
export const ADMIN_GET_TRANS_SUCCESS = 'ADMIN_GET_TRANS_SUCCESS';
export const ADMIN_GET_TRANS_FAIL = 'ADMIN_GET_TRANS_FAIL';

export const MARK_TRANS_ALL_REQUEST = 'MARK_TRANS_ALL_REQUEST';
export const MARK_TRANS_ALL_SUCCESS = 'MARK_TRANS_ALL_SUCCESS';
export const MARK_TRANS_ALL_FAIL = 'MARK_TRANS_ALL_FAIL';

export const MARK_TRANS_PART_REQUEST = 'MARK_TRANS_PART_REQUEST';
export const MARK_TRANS_PART_SUCCESS = 'MARK_TRANS_PART_SUCCESS';
export const MARK_TRANS_PART_FAIL = 'MARK_TRANS_PART_FAIL';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';

export const CREATE_TRANS_FROMB_REQUEST = 'CREATE_TRANS_FROMB_REQUEST';
export const CREATE_TRANS_FROMB_SUCCESS = 'CREATE_TRANS_FROMB_SUCCESS';
export const CREATE_TRANS_FROMB_FAIL = 'CREATE_TRANS_FROMB_FAIL';
export const CREATE_TRANS_FROMB_RESET = 'CREATE_TRANS_FROMB_RESET';

export const CREATE_BONUS_REQUEST = 'CREATE_BONUS_REQUEST';
export const CREATE_BONUS_SUCCESS = 'CREATE_BONUS_SUCCESS';
export const CREATE_BONUS_FAIL = 'CREATE_BONUS_FAIL';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_UPDATE_PICTURE_REQUEST = 'USER_UPDATE_PICTURE_REQUEST';
export const USER_UPDATE_PICTURE_SUCCESS = 'USER_UPDATE_PICTURE_SUCCESS';
export const USER_UPDATE_PICTURE_FAIL = 'USER_UPDATE_PICTURE_FAIL';

export const USER_WITHDRAW_REQUEST = 'USER_WITHDRAW_REQUEST';
export const USER_WITHDRAW_SUCCESS = 'USER_WITHDRAW_SUCCESS';
export const USER_WITHDRAW_FAIL = 'USER_WITHDRAW_FAIL';

export const GET_WITHDRAWALS_REQUEST = 'GET_WITHDRAWALS_REQUEST';
export const GET_WITHDRAWALS_SUCCESS = 'GET_WITHDRAWALS_SUCCESS';
export const GET_WITHDRAWALS_FAIL = 'GET_WITHDRAWALS_FAIL';

export const APPROVE_WITHDRAWAL_REQUEST = 'APPROVE_WITHDRAWAL_REQUEST';
export const APPROVE_WITHDRAWAL_SUCCESS = 'APPROVE_WITHDRAWAL_SUCCESS';
export const APPROVE_WITHDRAWAL_FAIL = 'APPROVE_WITHDRAWAL_FAIL';

export const GET_TOTAL_PAID_REQUEST = 'GET_TOTAL_PAID_REQUEST';
export const GET_TOTAL_PAID_SUCCESS = 'GET_TOTAL_PAID_SUCCESS';
export const GET_TOTAL_PAID_FAIL = 'GET_TOTAL_PAID_FAIL';

export const ADD_WALLET_REQUEST = 'ADD_WALLET_REQUEST';
export const ADD_WALLET_SUCCESS = 'ADD_WALLET_SUCCESS';
export const ADD_WALLET_FAIL = 'ADD_WALLET_FAIL';

export const GET_WALLETS_REQUEST = 'GET_WALLETS_REQUEST';
export const GET_WALLETS_SUCCESS = 'GET_WALLETS_SUCCESS';
export const GET_WALLETS_FAIL = 'GET_WALLETS_FAIL';

export const GET_TRANS_REQUEST = 'GET_TRANS_REQUEST';
export const GET_TRANS_SUCCESS = 'GET_TRANS_SUCCESS';
export const GET_TRANS_FAIL = 'GET_TRANS_FAIL';

export const USER_ID_UPDATE_REQUEST = 'USER_ID_UPDATE_REQUEST';
export const USER_ID_UPDATE_SUCCESS = 'USER_ID_UPDATE_SUCCESS';
export const USER_ID_UPDATE_FAIL = 'USER_ID_UPDATE_FAIL';

export const USER_lOAN_REQUEST = 'USER_lOAN_REQUEST';
export const USER_lOAN_SUCCESS = 'USER_lOAN_SUCCESS';
export const USER_lOAN_FAIL = 'USER_lOAN_FAIL';

export const USERS_PENDING_LOAN_REQUEST = 'USERS_PENDING_LOAN_REQUEST';
export const USERS_PENDING_LOAN_SUCCESS = 'USERS_PENDING_LOAN_SUCCESS';
export const USERS_PENDING_LOAN_FAIL = 'USERS_PENDING_LOAN_FAIL';

export const USERS_APPROVE_LOAN_REQUEST = 'USERS_APPROVE_LOAN_REQUEST';
export const USERS_APPROVE_LOAN_SUCCESS = 'USERS_APPROVE_LOAN_SUCCESS';
export const USERS_APPROVE_LOAN_FAIL = 'USERS_APPROVE_LOAN_FAIL';

export const USERS_APPROVED_LOANS_REQUEST = 'USERS_APPROVED_LOANS_REQUEST';
export const USERS_APPROVED_LOANS_SUCCESS = 'USERS_APPROVED_LOANS_SUCCESS';
export const USERS_APPROVED_LOANS_FAIL = 'USERS_APPROVED_LOANS_FAIL';

export const USER_LOANS_REQUEST = 'USER_LOANS_REQUEST';
export const USER_LOANS_SUCCESS = 'USER_LOANS_SUCCESS';
export const USER_LOANS_FAIL = 'USER_LOANS_FAIL';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_EDIT_DETAILS_REQUEST = 'USER_EDIT_DETAILS_REQUEST';
export const USER_EDIT_DETAILS_SUCCESS = 'USER_EDIT_DETAILS_SUCCESS';
export const USER_EDIT_DETAILS_FAIL = 'USER_EDIT_DETAILS_FAIL';
export const USER_EDIT_DETAILS_RESET = 'USER_EDIT_DETAILS_RESET';

export const KYC_VERIFICATION_REQUEST = 'KYC_VERIFICATION_REQUEST';
export const KYC_VERIFICATION_SUCCESS = 'KYC_VERIFICATION_SUCCESS';
export const KYC_VERIFICATION_FAIL = 'KYC_VERIFICATION_FAIL';
export const KYC_VERIFICATION_RESET = 'KYC_VERIFICATION_RESET';
