import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { variables } from '../data/variables';
import { verifyActions } from '../redux/actions/user.actions';

const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, error, success, userInfo } = useSelector(
    (state) => state.userVerify
  );

  useEffect(() => {
    if (location) {
      dispatch(verifyActions(location?.pathname?.split('/')[2]));
    }
  }, [location, dispatch]);

  useEffect(() => {
    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        navigate('/login');
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [success, navigate]);

  return (
    <div>
      <Helmet>
        <title>Verification - {variables.companyName}</title>
        <meta name='description' content='Verification' />
      </Helmet>
      <Container>
        <div className='w-full max-w-[450px] mx-auto shadow-3xl bg-slate-200 px-4'>
          <Header text='VERIFY ACCOUNT' />

          {userInfo ? (
            <div className='min-h-[200px]'>
              <Message variant='success'>{userInfo?.message}</Message>
            </div>
          ) : (
            <div className='min-h-[200px]'>
              {error && <Message variant='danger'>{error}</Message>}
              {loading && <Loader height={50} width={50} />}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Verify;
