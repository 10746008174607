import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AllTickets,
  Announcement,
  ChangePassword,
  CreateTicket,
  DepositHistory,
  DepositInWallet,
  EditDetails,
  InvestmentHistory,
  InvestmentProfit,
  KycVerification,
  NewInvestment,
  NewWithdrawalRequest,
  Plans,
  ViewDetails,
  WithdrawalHistory,
} from './components/Dashboard';
import DashboardLayout from './components/Dashboard/DashboardLayout';
import AddWallet from './components/Dashboard/wallet/AddWallet';
import Layout from './components/Layout';
import {
  About,
  Contact,
  Dashboard,
  ExplainAffiliate,
  Faq,
  ForgotPassword,
  Home,
  InvestmentPlans,
  Login,
  Privacy,
  Register,
  ResendVerification,
  ResetPassword,
  Security,
  Services,
  Terms,
  Trading,
  UserEdit,
  Verify,
} from './screens';
import Admin from './screens/Admin';
import NotFound from './screens/NotFound';

const App = () => {
  const [user, setUser] = useState(null);
  const {
    loading: loadingUserLogin,
    error: errorUserLogin,
    success: successUserLogin,
    userInfo: userInfoUserLogin,
  } = useSelector((state) => state.userLogin);

  useEffect(() => {
    setUser(userInfoUserLogin);
  }, [userInfoUserLogin]);

  return (
    <>
      <Routes>
        <Route path='' element={<Layout />}>
          <Route
            path='/'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Home />
            }
          />

          <Route
            path='/about'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <About />
            }
          />
          <Route
            path='/service'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Services />
            }
          />
          <Route
            path='/trading'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Trading />
            }
          />
          <Route
            path='/affiliate'
            element={
              user?.username ? (
                <Navigate to={`/dashboard `} />
              ) : (
                <ExplainAffiliate />
              )
            }
          />
          <Route
            path='/referral'
            element={
              user?.username ? (
                <Navigate to={`/dashboard `} />
              ) : (
                <ExplainAffiliate />
              )
            }
          />
          <Route
            path='/contact'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Contact />
            }
          />
          <Route
            path='/faq'
            element={user?.username ? <Navigate to={`/dashboard `} /> : <Faq />}
          />
          <Route
            path='/terms'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Terms />
            }
          />
          <Route
            path='/security'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Security />
            }
          />
          <Route
            path='/privacy'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Privacy />
            }
          />
          <Route
            path='/investment-plan'
            element={
              user?.username ? (
                <Navigate to={`/dashboard `} />
              ) : (
                <InvestmentPlans />
              )
            }
          />
          <Route
            path='/register'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Register />
            }
          />
          <Route
            path='/verify/:token'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Verify />
            }
          />
          <Route
            path='/request-verification'
            element={
              user?.username ? (
                <Navigate to={`/dashboard `} />
              ) : (
                <ResendVerification />
              )
            }
          />
          <Route
            path='/login'
            element={
              user?.username ? <Navigate to={`/dashboard `} /> : <Login />
            }
          />
          <Route
            path='/forgot-password'
            element={
              user?.username ? (
                <Navigate to={`/dashboard `} />
              ) : (
                <ForgotPassword />
              )
            }
          />

          <Route
            path='/reset/password/:token'
            element={
              user?.username ? (
                <Navigate to={`/dashboard `} />
              ) : (
                <ResetPassword />
              )
            }
          />
        </Route>
        <Route element={<DashboardLayout />}>
          <Route
            path='/dashboard'
            element={user?.username ? <Dashboard /> : <Navigate to={`/`} />}
          />
          <Route
            path='/dashboard/edit-details'
            element={user?.username ? <EditDetails /> : <Navigate to={`/`} />}
          />

          <Route
            path='/dashboard/view-details'
            element={user?.username ? <ViewDetails /> : <Navigate to={`/`} />}
          />
          <Route
            path='/dashboard/kyc-verification'
            element={
              user?.username ? <KycVerification /> : <Navigate to={`/`} />
            }
          />
          <Route
            path='/dashboard/plans'
            element={user?.username ? <Plans /> : <Navigate to={`/`} />}
          />
          <Route
            path='/dashboard/deposit-in-wallet'
            element={
              user?.username ? <DepositInWallet /> : <Navigate to={`/`} />
            }
          />
          <Route
            path='/dashboard/deposit-history'
            element={
              user?.username ? <DepositHistory /> : <Navigate to={`/`} />
            }
          />
          <Route
            path='/dashboard/add-wallet'
            element={user?.username ? <AddWallet /> : <Navigate to={`/`} />}
          />
          <Route
            path='/dashboard/new-investment'
            element={user?.username ? <NewInvestment /> : <Navigate to={`/`} />}
          />
          <Route
            path='/dashboard/investment-history'
            element={
              user?.username ? <InvestmentHistory /> : <Navigate to={`/`} />
            }
          />
          <Route
            path='/dashboard/investment-profit'
            element={
              user?.username ? <InvestmentProfit /> : <Navigate to={`/`} />
            }
          />
          <Route
            path='/dashboard/new-withdrawal-request'
            element={
              user?.username ? <NewWithdrawalRequest /> : <Navigate to={`/`} />
            }
          />
          <Route
            path='/dashboard/withdrawal-history'
            element={
              user?.username ? <WithdrawalHistory /> : <Navigate to={`/`} />
            }
          />
          {/* <Route
            path='/dashboard/create-ticket'
            element={user?.username ? <CreateTicket /> : <Navigate to={`/`} />}
          />
          <Route
            path='/dashboard/all-tickets'
            element={user?.username ? <AllTickets /> : <Navigate to={`/`} />}
          />
          <Route
            path='/dashboard/announcements'
            element={user?.username ? <Announcement /> : <Navigate to={`/`} />}
          />
          <Route
            path='/dashboard/change-password'
            element={
              user?.username ? <ChangePassword /> : <Navigate to={`/`} />
            }
          /> */}
        </Route>
        <Route
          path='/dashboard-2'
          element={user?.isAdmin ? <Admin /> : <Navigate to='/' />}
        />

        <Route
          path='/admin/user/:id/edit'
          element={user?.username ? <UserEdit /> : <Navigate to={`/`} />}
        />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
