import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const WorkTogether = () => {
  return (
    <section className='py-10 bg-slate-300'>
      <Container className=''>
        <div className='flex flex-wrap justify-center'>
          <div className='left-work w-full max-w-[500px] py-10 px-8'>
            <h2 className='text-xl sm:text-3xl font-bold text-blue-900'>
              How can we help
            </h2>
            <h3 className='text-lg sm:text-2xl font-bold text-slate-700 pb-3'>
              you trade or invest?
            </h3>
            <p className='text-lg md:text-xl text-slate-700'>
              We work with you to transform your finance, driving bold ideas and
              pragmatic solutions.
            </p>
            <div className='py-4'>
              <Link
                to='/register'
                className='btn btn-danger text-lg rounded-none'
              >
                LET'S WORK TOGETHER
              </Link>
            </div>
          </div>
          <div className='right-work w-full max-w-[500px]  py-10 px-8 bg-slate-50'>
            <h2 className='text-xl sm:text-3xl font-bold text-blue-900'>
              Our people are our
            </h2>
            <h3 className='text-lg sm:text-2xl font-bold text-slate-700 pb-3'>
              greatest assets
            </h3>
            <p className='text-lg md:text-xl text-slate-700'>
              We work with you to transform your finance, driving bold ideas and
              pragmatic solutions.
            </p>
            <div className='py-4'>
              <Link
                to='/register'
                className='btn btn-secondary text-lg rounded-none '
              >
                Join Us
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WorkTogether;
