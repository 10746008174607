import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './Navbar';
import SideBarMenu from './SideBarMenu';

const DashboardLayout = () => {
  const [showSideBarMenu, setShowSideBarMenu] = useState(false);
  return (
    <div className='selection:bg-green-800 selection:text-white'>
      <NavBar
        showSideBarMenu={showSideBarMenu}
        setShowSideBarMenu={setShowSideBarMenu}
      />
      <main className='flex'>
        <SideBarMenu showSideBarMenu={showSideBarMenu} />
        <div className=' py-6 pb-10 px-3 sm:px-5 flex-1 bg-slate-200 min-h-[80vh] w-full max-w-[1500px] overflow-x-hidden '>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default DashboardLayout;
