import {
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_MAKE_ADMIN_REQUEST,
  USER_MAKE_ADMIN_SUCCESS,
  USER_MAKE_ADMIN_FAIL,
  USER_CREATE_USER_REQUEST,
  USER_CREATE_USER_SUCCESS,
  USER_CREATE_USER_FAIL,
  USER_GET_BY_ID_REQUEST,
  USER_GET_BY_ID_SUCCESS,
  USER_GET_BY_ID_FAIL,
  USER_CREATE_ALERT_REQUEST,
  USER_CREATE_ALERT_SUCCESS,
  USER_CREATE_ALERT_FAIL,
  USER_GET_TRANS_ALERTS_REQUEST,
  USER_GET_TRANS_ALERTS_SUCCESS,
  USER_GET_TRANS_ALERTS_FAIL,
  ADMIN_GET_TRANS_ALERTS_REQUEST,
  ADMIN_GET_TRANS_ALERTS_SUCCESS,
  ADMIN_GET_TRANS_ALERTS_FAIL,
  ADMIN_CREATE_TRANS_REQUEST,
  ADMIN_CREATE_TRANS_SUCCESS,
  ADMIN_CREATE_TRANS_FAIL,
  ADMIN_CREATE_TRANS_RESET,
  ADMIN_GET_TRANS_REQUEST,
  ADMIN_GET_TRANS_SUCCESS,
  ADMIN_GET_TRANS_FAIL,
  MARK_TRANS_ALL_REQUEST,
  MARK_TRANS_ALL_SUCCESS,
  MARK_TRANS_ALL_FAIL,
  MARK_TRANS_PART_REQUEST,
  MARK_TRANS_PART_SUCCESS,
  MARK_TRANS_PART_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  CREATE_TRANS_FROMB_REQUEST,
  CREATE_TRANS_FROMB_SUCCESS,
  CREATE_TRANS_FROMB_FAIL,
  CREATE_BONUS_REQUEST,
  CREATE_BONUS_SUCCESS,
  CREATE_BONUS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PICTURE_REQUEST,
  USER_UPDATE_PICTURE_SUCCESS,
  USER_UPDATE_PICTURE_FAIL,
  USER_WITHDRAW_REQUEST,
  USER_WITHDRAW_SUCCESS,
  USER_WITHDRAW_FAIL,
  GET_WITHDRAWALS_REQUEST,
  GET_WITHDRAWALS_SUCCESS,
  GET_WITHDRAWALS_FAIL,
  APPROVE_WITHDRAWAL_REQUEST,
  APPROVE_WITHDRAWAL_SUCCESS,
  APPROVE_WITHDRAWAL_FAIL,
  GET_TOTAL_PAID_FAIL,
  GET_TOTAL_PAID_REQUEST,
  GET_TOTAL_PAID_SUCCESS,
  ADD_WALLET_REQUEST,
  ADD_WALLET_SUCCESS,
  ADD_WALLET_FAIL,
  GET_WALLETS_FAIL,
  GET_WALLETS_REQUEST,
  GET_WALLETS_SUCCESS,
  GET_TRANS_REQUEST,
  GET_TRANS_SUCCESS,
  GET_TRANS_FAIL,
  USER_lOAN_REQUEST,
  USER_lOAN_SUCCESS,
  USER_lOAN_FAIL,
  USER_ID_UPDATE_REQUEST,
  USER_ID_UPDATE_SUCCESS,
  USER_ID_UPDATE_FAIL,
  USERS_PENDING_LOAN_REQUEST,
  USERS_PENDING_LOAN_SUCCESS,
  USERS_PENDING_LOAN_FAIL,
  USERS_APPROVE_LOAN_REQUEST,
  USERS_APPROVE_LOAN_SUCCESS,
  USERS_APPROVE_LOAN_FAIL,
  USERS_APPROVED_LOANS_REQUEST,
  USERS_APPROVED_LOANS_SUCCESS,
  USERS_APPROVED_LOANS_FAIL,
  USER_LOANS_REQUEST,
  USER_LOANS_SUCCESS,
  USER_LOANS_FAIL,
  USER_VERIFY_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  RESEND_VERIFICATION_FAIL,
  RESEND_VERIFICATION_REQUEST,
  RESEND_VERIFICATION_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_RESET,
  RESET_PASSWORD_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_EDIT_DETAILS_FAIL,
  USER_EDIT_DETAILS_REQUEST,
  USER_EDIT_DETAILS_RESET,
  USER_EDIT_DETAILS_SUCCESS,
  KYC_VERIFICATION_FAIL,
  KYC_VERIFICATION_REQUEST,
  KYC_VERIFICATION_RESET,
  KYC_VERIFICATION_SUCCESS,
  ADMIN_GET_TRANS_CURRENT_REQUEST,
  ADMIN_GET_TRANS_CURRENT_SUCCESS,
  ADMIN_GET_TRANS_CURRENT_FAIL,
  ADMIN_TOPUP_TRANS_REQUEST,
  ADMIN_TOPUP_TRANS_SUCCESS,
  ADMIN_TOPUP_TRANS_FAIL,
  ADMIN_TOPUP_TRANS_RESET,
} from '../constants/user.constants';

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_REQUEST:
      return { loading: true };
    case USER_VERIFY_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_VERIFY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const resendVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case RESEND_VERIFICATION_REQUEST:
      return { loading: true };
    case RESEND_VERIFICATION_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case RESEND_VERIFICATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, success: true, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return { users: [] };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const makeUserAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_MAKE_ADMIN_REQUEST:
      return { loading: true };
    case USER_MAKE_ADMIN_SUCCESS:
      return { loading: false, success: true };
    case USER_MAKE_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const createUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_USER_REQUEST:
      return { loading: true };
    case USER_CREATE_USER_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case USER_CREATE_USER_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const getUserByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_GET_BY_ID_REQUEST:
      return { loading: true };
    case USER_GET_BY_ID_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_GET_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const createTransAlertReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_ALERT_REQUEST:
      return { loading: true };
    case USER_CREATE_ALERT_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case USER_CREATE_ALERT_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const getTransAlertReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_GET_TRANS_ALERTS_REQUEST:
      return { loading: true };
    case USER_GET_TRANS_ALERTS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USER_GET_TRANS_ALERTS_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const adminGetTransAlertReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_TRANS_ALERTS_REQUEST:
      return { loading: true };
    case ADMIN_GET_TRANS_ALERTS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case ADMIN_GET_TRANS_ALERTS_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const adminGetTransCurrentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_TRANS_CURRENT_REQUEST:
      return { loading: true };
    case ADMIN_GET_TRANS_CURRENT_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case ADMIN_GET_TRANS_CURRENT_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const createTransReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CREATE_TRANS_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_TRANS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case ADMIN_CREATE_TRANS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CREATE_TRANS_RESET:
      return { loading: false, success: false };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const topUpTransReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_TOPUP_TRANS_REQUEST:
      return { loading: true };
    case ADMIN_TOPUP_TRANS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case ADMIN_TOPUP_TRANS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_TOPUP_TRANS_RESET:
      return { loading: false, success: false };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const adminGetTransReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_TRANS_REQUEST:
      return { loading: true };
    case ADMIN_GET_TRANS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case ADMIN_GET_TRANS_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const markTransAllReducer = (state = {}, action) => {
  switch (action.type) {
    case MARK_TRANS_ALL_REQUEST:
      return { loading: true };
    case MARK_TRANS_ALL_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case MARK_TRANS_ALL_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const markTransPartReducer = (state = {}, action) => {
  switch (action.type) {
    case MARK_TRANS_PART_REQUEST:
      return { loading: true };
    case MARK_TRANS_PART_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case MARK_TRANS_PART_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { loading: true };
    case USER_PROFILE_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USER_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
export const createTransFromBReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TRANS_FROMB_REQUEST:
      return { loading: true };
    case CREATE_TRANS_FROMB_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case CREATE_TRANS_FROMB_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
export const createBonusReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BONUS_REQUEST:
      return { loading: true };
    case CREATE_BONUS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case CREATE_BONUS_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userUpdatePictureReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PICTURE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PICTURE_SUCCESS:
      return { loading: false, success: true, resp: action.payload };
    case USER_UPDATE_PICTURE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const requestWithdrawalReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_WITHDRAW_REQUEST:
      return { loading: true };
    case USER_WITHDRAW_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USER_WITHDRAW_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const withdrawalRequestsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WITHDRAWALS_REQUEST:
      return { loading: true };
    case GET_WITHDRAWALS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case GET_WITHDRAWALS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approveWithdrawalRequestsReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVE_WITHDRAWAL_REQUEST:
      return { loading: true };
    case APPROVE_WITHDRAWAL_SUCCESS:
      return { loading: false, success: true, resp: action.payload };
    case APPROVE_WITHDRAWAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTotalPaidReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TOTAL_PAID_REQUEST:
      return { loading: true };
    case GET_TOTAL_PAID_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case GET_TOTAL_PAID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addWalletReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_WALLET_REQUEST:
      return { loading: true };
    case ADD_WALLET_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case ADD_WALLET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getWalletReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WALLETS_REQUEST:
      return { loading: true };
    case GET_WALLETS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case GET_WALLETS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTransReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TRANS_REQUEST:
      return { loading: true };
    case GET_TRANS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case GET_TRANS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userLoanReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_lOAN_REQUEST:
      return { loading: true };
    case USER_lOAN_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USER_lOAN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateIdCardReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ID_UPDATE_REQUEST:
      return { loading: true };
    case USER_ID_UPDATE_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USER_ID_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pendingLoanRequestsReducer = (state = {}, action) => {
  switch (action.type) {
    case USERS_PENDING_LOAN_REQUEST:
      return { loading: true };
    case USERS_PENDING_LOAN_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USERS_PENDING_LOAN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approveLoanRequestsReducer = (state = {}, action) => {
  switch (action.type) {
    case USERS_APPROVE_LOAN_REQUEST:
      return { loading: true };
    case USERS_APPROVE_LOAN_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USERS_APPROVE_LOAN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allApprovedLoansReducer = (state = {}, action) => {
  switch (action.type) {
    case USERS_APPROVED_LOANS_REQUEST:
      return { loading: true };
    case USERS_APPROVED_LOANS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USERS_APPROVED_LOANS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allUserLoansReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOANS_REQUEST:
      return { loading: true };
    case USER_LOANS_SUCCESS:
      return { loading: false, success: true, res: action.payload };
    case USER_LOANS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const kycVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case KYC_VERIFICATION_REQUEST:
      return { loading: true };
    case KYC_VERIFICATION_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case KYC_VERIFICATION_FAIL:
      return { loading: false, error: action.payload };
    case KYC_VERIFICATION_RESET:
      return {};

    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case FORGOT_PASSWORD_RESET:
      return {};

    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true };
    case RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case RESET_PASSWORD_RESET:
      return {};

    default:
      return state;
  }
};

export const editUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EDIT_DETAILS_REQUEST:
      return { loading: true };
    case USER_EDIT_DETAILS_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_EDIT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_EDIT_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};
