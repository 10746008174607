import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import { variables } from '../data/variables';

const Terms = () => {
  return (
    <div className='py-10 pb-16 text-slate-600 terms'>
      <Header text='Our Terms and Conditions' />
      <section>
        <Container>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>Risk Notice</h2>
            <p className='py-2'>
              Bitcoin is a not backed or value guaranteed by any financial
              institution; when purchasing bitcoins the customer assumes all
              risk the bitcoins may become worthless in value. Customers should
              research and consider the risks before purchasing any bitcoins.
              The company makes absolutely no guarantee about the future value
              of the bitcoins purchased.
            </p>
          </article>

          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>Severability</h2>
            <p className='py-2'>
              In the event any court shall declare any section or sections of
              this Agreement invalid or void, such declaration shall not
              invalidate the entire Agreement and all other paragraphs of the
              Agreement shall remain in full force and effect. As withdrawal
              processes and policies as followed efficiently ,authorizing
              effective withdrawal to investor&apos;s external wallet address .
            </p>
          </article>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>
              Binding Agreement
            </h2>
            <p className='py-2'>
              The terms and provisions of this Agreement are binding upon Your
              heirs, successors, assigns, and other representatives. This
              Agreement may be executed in counterparts, each of which shall be
              considered to be an original, but both of which constitute the
              same Agreement.
            </p>
          </article>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>Choice of Law</h2>
            <p className='py-2'>
              This Agreement, and its application and interpretation, shall be
              governed exclusively by the laws of the State of United Kingdom,
              without regard to its conflict of law rules. You consent to the
              exclusive jurisdiction of the federal and state courts located in
              or near United Kingdom for any dispute arising under this
              Agreement.
            </p>
          </article>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>Security</h2>
            <p className='py-2'>
              We have implemented security measures designed to secure your
              information from accidental loss and from unauthorized access,
              use, alteration or disclosure. However, we cannot guarantee that
              unauthorized persons will never gain access to your information,
              and you acknowledge that you provide your information at your own
              risk, except as otherwise provided by applicable law.
            </p>
          </article>

          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>Expired Orders</h2>
            <p className='py-2'>
              If the company receives payment for an order that has already
              expired, the company reserves the right to recalculate the Bitcoin
              to Dollar exchange rate at the time of processing the transfer to
              the customer. This may result in the customer receiving less
              bitcoins than the original ordered amount.
            </p>
          </article>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>
              Customer input errors
            </h2>
            <p className='py-2'>
              It is the sole responsibility of the customer to check the
              accuracy of information entered and saved on the website. Account
              details displayed on the order summary webpage will be the final
              transfer destination. In the case that this information is
              incorrect, and funds are transferred to an unintended destination,
              the company shall not reimburse the customer and shall not
              transfer additional funds. As such customers must ensure the
              Bitcoin address and bank information they enter is completely
              correct.
            </p>
          </article>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>Disclaimer</h2>
            <p className='py-2'>
              All the material published electronically on this site is for
              information purposes only. {variables.companyNameFull} solely
              adhere to its policy and not following them leads to automatic
              deactivating clients portfolio account. {variables?.companyFull}{' '}
              does not accept any responsibility for loss or damage suffered by
              any person or body relying directly or indirectly on any
              information contained from external source.{' '}
              {variables?.companyFull} does not accept any liability for any
              financial decisions made based on the information provided. This
              website does not constitute financial advice and should not be
              taken as such. {variables?.companyFull} urges you to obtain
              professional advice, and consider your investment objectives,
              financial situation and individual needs before proceeding with
              any financial or investment decisions.
            </p>
          </article>
        </Container>
      </section>
    </div>
  );
};

export default Terms;
