import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { variables } from '../../data/variables';
import { icon1, icon2, icon3, icon4 } from '../../images';

const items = [
  {
    image: icon1,
    header: 'Access',
    text: 'We take the power of trading with you from any location, on any device, at any time you wish.',
  },
  {
    image: icon2,
    header: 'Secure',
    text: 'We take security seriously with a range of fraud protection involving a lower level of risks.',
  },
  {
    image: icon3,
    header: 'Support',
    text: 'We take security seriously with a range of fraud protection involving a lower level of risks.',
  },
  {
    image: icon4,
    header: 'Credibility',
    text: 'We take security seriously with a range of fraud protection involving a lower level of risks.',
  },
];

const MiniAbout = () => {
  return (
    <div className='pb-8 pt-14 bg-slate-600 text-slate-200'>
      <Container>
        <div>
          <div className='flex flex-col md:flex-row gap-4'>
            <div>
              <h2 className='text-xl sm:text-2xl font-bold text-red-400'>
                Our Company
              </h2>
              <p className='max-w-[600px] my-2 text-lg sm:text-xl'>
                Beyond operating the world&apos;s leading trading company,{' '}
                {variables.companyNameFull} spins 100+ transactions per second.
              </p>
            </div>

            <p className='max-w-[600px] my-2 text-[14px] sm:text-base'>
              Founded in 2011, Fathom trading portfolio management has built one
              of the Leading brands in both the Crypto industry and the forex
              industry by offering secure, reliable services to customers in
              over 200 countries for nearly 10years. Venture backed since 2013,
              fathom has raised $200m of equity, valued at $2.2B. Both the
              consumer and institutional businesses are highly profitable with
              $3.6B+ transacted and 1/3 of all BTC transactions through our
              retail platform since inception. Beyond operating the world’s
              leading trading company, fathom trade and investment portfolio
              spins on entire ecosystem. 1,600,000+ transactions per second
            </p>
          </div>
        </div>
        <div className='flex flex-wrap gap-4 items-center justify-center my-8 bg-slate-500 px-6 py-10'>
          {items?.map((x) => {
            return (
              <div className='max-w-[400px] flex flex-col items-center border gap-3 bg-slate-200 text-slate-700 py-4'>
                <div className='min-w-[100px] min-h-[100px]'>
                  <img src={x?.image} alt='' width={100} height={100} />
                </div>
                <h2 className='text-center text-xl'>{x?.header}</h2>
                <p className='text-center'>{x?.text}</p>
              </div>
            );
          })}
        </div>

        <div className='bg-slate-700 h-[100px] flex items-center justify-center'>
          Quit sitting idle and cash on innovation.{' '}
          <Link to='/login' className='px-3 text-white underline'>
            Join us Now
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default MiniAbout;
