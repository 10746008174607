import React from 'react';
import { Container } from 'react-bootstrap';
import { business, corperate, growth } from '../../images';

const Featured = () => {
  //     const script = document.createElement('script');

  //     script.src =
  //       'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
  //     script.async = true;
  //     script.innerHTML = {
  //       colorTheme: 'light',
  //       dateRange: '12M',
  //       showChart: true,
  //       locale: 'en',
  //       largeChartUrl: '',
  //       isTransparent: false,
  //       showSymbolLogo: true,
  //       showFloatingTooltip: false,
  //       width: '400',
  //       height: '360',
  //       plotLineColorGrowing: 'rgba(41, 98, 255, 1)',
  //       plotLineColorFalling: 'rgba(41, 98, 255, 1)',
  //       gridLineColor: 'rgba(240, 243, 250, 0)',
  //       scaleFontColor: 'rgba(106, 109, 120, 1)',
  //       belowLineFillColorGrowing: 'rgba(41, 98, 255, 0.12)',
  //       belowLineFillColorFalling: 'rgba(41, 98, 255, 0.12)',
  //       belowLineFillColorGrowingBottom: 'rgba(41, 98, 255, 0)',
  //       belowLineFillColorFallingBottom: 'rgba(41, 98, 255, 0)',
  //       symbolActiveColor: 'rgba(41, 98, 255, 0.12)',
  //       tabs: [
  //         {
  //           title: 'Indices',
  //           symbols: [
  //             {
  //               s: 'FOREXCOM:SPXUSD',
  //               d: 'S&P 500',
  //             },
  //             {
  //               s: 'FOREXCOM:NSXUSD',
  //               d: 'US 100',
  //             },
  //             {
  //               s: 'FOREXCOM:DJI',
  //               d: 'Dow 30',
  //             },
  //             {
  //               s: 'INDEX:NKY',
  //               d: 'Nikkei 225',
  //             },
  //             {
  //               s: 'INDEX:DEU40',
  //               d: 'DAX Index',
  //             },
  //             {
  //               s: 'FOREXCOM:UKXGBP',
  //               d: 'UK 100',
  //             },
  //           ],
  //           originalTitle: 'Indices',
  //         },
  //         // {
  //         //   title: 'Futures',
  //         //   symbols: [
  //         //     {
  //         //       s: 'CME_MINI:ES1!',
  //         //       d: 'S&P 500',
  //         //     },
  //         //     {
  //         //       s: 'CME:6E1!',
  //         //       d: 'Euro',
  //         //     },
  //         //     {
  //         //       s: 'COMEX:GC1!',
  //         //       d: 'Gold',
  //         //     },
  //         //     {
  //         //       s: 'NYMEX:CL1!',
  //         //       d: 'Crude Oil',
  //         //     },
  //         //     {
  //         //       s: 'NYMEX:NG1!',
  //         //       d: 'Natural Gas',
  //         //     },
  //         //     {
  //         //       s: 'CBOT:ZC1!',
  //         //       d: 'Corn',
  //         //     },
  //         //   ],
  //         //   originalTitle: 'Futures',
  //         // },
  //         // {
  //         //   title: 'Bonds',
  //         //   symbols: [
  //         //     {
  //         //       s: 'CME:GE1!',
  //         //       d: 'Eurodollar',
  //         //     },
  //         //     {
  //         //       s: 'CBOT:ZB1!',
  //         //       d: 'T-Bond',
  //         //     },
  //         //     {
  //         //       s: 'CBOT:UB1!',
  //         //       d: 'Ultra T-Bond',
  //         //     },
  //         //     {
  //         //       s: 'EUREX:FGBL1!',
  //         //       d: 'Euro Bund',
  //         //     },
  //         //     {
  //         //       s: 'EUREX:FBTP1!',
  //         //       d: 'Euro BTP',
  //         //     },
  //         //     {
  //         //       s: 'EUREX:FGBM1!',
  //         //       d: 'Euro BOBL',
  //         //     },
  //         //   ],
  //         //   originalTitle: 'Bonds',
  //         // },
  //         // {
  //         //   title: 'Forex',
  //         //   symbols: [
  //         //     {
  //         //       s: 'FX:EURUSD',
  //         //       d: 'EUR/USD',
  //         //     },
  //         //     {
  //         //       s: 'FX:GBPUSD',
  //         //       d: 'GBP/USD',
  //         //     },
  //         //     {
  //         //       s: 'FX:USDJPY',
  //         //       d: 'USD/JPY',
  //         //     },
  //         //     {
  //         //       s: 'FX:USDCHF',
  //         //       d: 'USD/CHF',
  //         //     },
  //         //     {
  //         //       s: 'FX:AUDUSD',
  //         //       d: 'AUD/USD',
  //         //     },
  //         //     {
  //         //       s: 'FX:USDCAD',
  //         //       d: 'USD/CAD',
  //         //     },
  //         //   ],
  //         //   originalTitle: 'Forex',
  //         // },
  //       ],
  //     };

  //     document.querySelector('.tradingview-widget-container').appendChild(script);
  //   }, []);
  return (
    <section className="featured-home py-10">
      <Container className="flex flex-col md:flex-row gap-6">
        <div className="flex flex-col gap-6">
          {' '}
          <div>
            <h2 className="text-xl sm:text-3xl font-bold text-slate-200">
              <b>Featured </b>Content
            </h2>
            <p className="py-3 text-slate-300 text-lg md:text-xl">
              By unlocking the potential for growth in our people, clients and
              our communities we believe we can help shape a vibrant economy for
              the investor
            </p>
          </div>
          <div className="flex gap-4 text-red-400">
            <div>
              <img src={corperate} alt="" />
            </div>
            <p className="font-medium text-lg md:text-2xl">
              Corporate Strategy
            </p>
          </div>
          <div className="flex gap-4 text-red-400">
            <div>
              <img src={growth} alt="" />
            </div>
            <p className="font-medium text-lg md:text-2xl text-green-400">
              Growth Strategy
            </p>
          </div>
          <div className="flex gap-4 text-red-400">
            <div>
              <img src={business} alt="" />
            </div>
            <p className="font-medium text-lg md:text-2xl text-cyan-400">
              Business Unit Strategy
            </p>
          </div>
        </div>
        <div className="max-h-[500px] overflow-hidden w-full max-w-[400px]">
          <iframe
            allowtransparency="true"
            Border="0"
            src="https://s.tradingview.com/embed-widget/market-overview/#%7B%22width%22%3A400%2C%22height%22%3A650%2C%22isTransparent%22%3Afalse%2C%22dateRange%22%3A%2212M%22%2C%22showSymbolLogo%22%3Atrue%2C%22utm_source%22%3A%22localhost%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22market-overview%22%2C%22page-uri%22%3A%22localhost%3A3000%2F%22%7D"
            title="serv"
            className="h-[400px] w-full max-w-[400px]"
          ></iframe>
        </div>
      </Container>
    </section>
  );
};

export default Featured;
