import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminGetTransCurrent,
  createTrans,
} from '../redux/actions/user.actions';
import { percentHelper } from '../utility/percentChecker';
import Loader from './Loader';
import Message from './Message';
import TopUp from './TopUp';

const CurrentTrans = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  //   const [depositState, setDepositState] = useState();

  const { loading, success, res, error } = useSelector(
    (state) => state.adminGetTransCurrent
  );

  const { success: successC, res: resC } = useSelector(
    (state) => state.createTrans
  );

  const {
    loading: loadingTopUp,
    success: successTopUp,
    error: errorTopUp,
    res: resTopUp,
  } = useSelector((state) => state.topUpTrans);

  useEffect(() => {
    dispatch(adminGetTransCurrent());
  }, [dispatch, successC, successTopUp]);

  useEffect(() => {
    if (success) {
      console.log(res);
      setList(res);
    }
  }, [success, res]);

  //   const handleTopup = (
  //     e,
  //     email,
  //     deposit,
  //     percent,
  //     name,
  //     username,
  //     user,
  //     coin,
  //     _id
  //   ) => {
  //     e.preventDefault();

  //     if (!/^[0-9]*$/.test(depositState?.trim())) {
  //       alert('Invalid top up amount');
  //       return;
  //     }

  //     const pending = 'pending';
  //     if (
  //       window.confirm('Are you sure you want to topup investment for this user?')
  //     ) {
  //       dispatch(
  //         createTrans(
  //           email,
  //           depositState,
  //           percent,
  //           name,
  //           username,
  //           user,
  //           coin,
  //           pending,
  //           _id
  //         )
  //       );
  //     }
  //   };

  return (
    <div>
      <h3 className='text-center  text-xl font-bold py-4'>
        Current Transactions
      </h3>
      {error && <Message varianty='error'>{error}</Message>}
      {successC && <Message variant='success'>{resC.message}</Message>}

      {errorTopUp && <Message varianty='error'>{errorTopUp}</Message>}
      {successTopUp && <Message variant='success'>{resTopUp.message}</Message>}

      {loadingTopUp ? <Loader /> : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          {list && list.length > 0 && (
            <Table
              striped
              bordered
              hover
              responsive
              className='table-sm trans-alert'
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>USERNAME</th>
                  <th>DEPOSIT</th>
                  <th>PLAN</th>
                  <th>CREATED DATE</th>
                  <th>DUE DATE</th>
                  <th>TOP UP</th>
                  {/* <th>ACTION_2</th> */}
                </tr>
              </thead>
              <tbody>
                {list.map((trans, index) => {
                  return <TopUp trans={trans} index={index} key={index} />;
                })}
              </tbody>
            </Table>
          )}
          {list.length === 0 && (
            <Message variant='danger'>No transaction at the moment</Message>
          )}
        </>
      )}
    </div>
  );
};

export default CurrentTrans;
