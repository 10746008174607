import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { topUpTrans } from '../redux/actions/user.actions';
import { ADMIN_TOPUP_TRANS_RESET } from '../redux/constants/user.constants';
import { percentHelper } from '../utility/percentChecker';

const TopUp = ({ trans, index }) => {
  const {
    username,
    deposit,
    percent,
    createdAt,
    _id,
    name,
    user,
    coin,
    email,
    withdrawalDate,
  } = trans;

  const dispatch = useDispatch();
  const [depositState, setDepositState] = useState();

  const {
    loading: loadingTopUp,
    success: successTopUp,
    error: errorTopUp,
    res: resTopUp,
  } = useSelector((state) => state.topUpTrans);

  const handleTopup = (
    e,
    email,
    deposit,
    percent,
    name,
    username,
    user,
    coin,
    _id
  ) => {
    e.preventDefault();

    if (!/^[0-9]*$/.test(depositState?.trim())) {
      alert('Invalid top up amount');
      return;
    }

    const pending = 'pending';
    if (
      window.confirm('Are you sure you want to topup investment for this user?')
    ) {
      dispatch(
        topUpTrans(
          email,
          depositState,
          percent,
          name,
          username,
          user,
          coin,
          pending,
          _id
        )
      );
    }
  };

  useEffect(() => {
    if (successTopUp) {
      setDepositState('');
      dispatch({ type: ADMIN_TOPUP_TRANS_RESET });
    }
  }, [successTopUp, dispatch]);

  return (
    <tr key={_id} className='whitespace-nowrap'>
      <td>{index + 1}</td>
      <td>{username}</td>
      <td>${deposit}</td>
      <td>{percentHelper(percent)}</td>
      <td>{new Date(createdAt).toDateString()}</td>
      <td>{new Date(withdrawalDate).toDateString()}</td>
      <td>
        <form
          onSubmit={handleTopup}
          className='flex items-center justify-center'
        >
          <input
            type='text'
            className='inline-flex border-2 border-gray-300 mx-1 p-2 max-w-[200px]'
            pattern='/0-9'
            onChange={(e) => {
              setDepositState(e.target.value);
            }}
            value={depositState}
            required
            readOnly={loadingTopUp}
          />
          <Button
            disabled={loadingTopUp}
            onClick={(e) =>
              handleTopup(
                e,
                email,
                deposit,
                percent,
                name,
                username,
                user,
                coin,
                _id
              )
            }
            variant='outline-primary '
            size='sm'
            type='submit'
          >
            Top-Up
          </Button>
        </form>
      </td>
      {/* <td>
        <Button variant='outline-danger'>Delete</Button>
      </td> */}
    </tr>
  );
};

export default TopUp;
