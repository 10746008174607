import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getTrans } from '../../../redux/actions/user.actions';
import Message from '../../../components/Message';
import Loader from '../../../components/Loader';
import Header from '../../../components/Header';

import { percentHelper } from '../../../utility/percentChecker';

const UserTrans = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);

  const { loading, success, res, error } = useSelector(
    (state) => state.getTrans
  );

  useEffect(() => {
    dispatch(getTrans());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setList(res);
    }
  }, [success, res]);

  return (
    <div>
      <Container fluid>
        <Header text="Investment history" />
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="table-sm trans-alert"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>USERNAME</th>
                  <th>DEPOSIT</th>
                  <th>PLAN</th>
                  <th>COIN</th>
                  <th>WITHDRAWAL</th>
                  <th className="whitespace-nowrap">CREATED DATE</th>
                  {/* <th className='whitespace-nowrap'>WITHDRAWAL DATE</th> */}
                  {/* <th>PAID</th> */}
                </tr>
              </thead>
              <tbody>
                {list.length > 0 &&
                  list.map((list, index) => {
                    const {
                      username,
                      deposit,
                      percent,
                      coin,
                      withdrawal,
                      createdAt,
                      withdrawalDate,
                      isWithdrawalPaid,

                      _id,
                    } = list;

                    return (
                      <tr key={_id}>
                        <td>{index + 1}</td>
                        <td>{username}</td>
                        <td>{deposit}</td>
                        <td>{percentHelper(percent)}</td>
                        <td>{coin}</td>
                        <td>${withdrawal}</td>
                        <td>{new Date(createdAt).toLocaleDateString()}</td>
                        {/* <td>{new Date(withdrawalDate).toLocaleDateString()}</td> */}
                        {/* <td>
                          {isWithdrawalPaid ? (
                            <FaCheck size={25} color="green" />
                          ) : (
                            <FaTimes size={25} color="red" />
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            {list.length === 0 && (
              <Message variant="danger">
                You have no investments at the moment. Create a new one.
              </Message>
            )}
          </>
        )}
      </Container>
    </div>
  );
};
export default UserTrans;
