import React, { useRef, useState } from 'react';

import useClipboard from 'react-use-clipboard';
import { Helmet } from 'react-helmet';

import { Container, Form, Button, Row, div } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../../components/Message';
import Loader from '../../../components/Loader';
import { variables } from '../../../data/variables';
import { FaInfoCircle } from 'react-icons/fa';
import { createTransAlert } from '../../../redux/actions/user.actions';
import { useEffect } from 'react';
import Header from '../../../components/Header';

const DepositInWallet = () => {
  const focusDiv = useRef(null);

  useEffect(() => {
    focusDiv.current.focus();
  }, []);

  const dispatch = useDispatch();
  const [coin, setCoin] = useState('');

  const [copiedBtc, setCopiedBtc] = useClipboard(variables.bitcoinWallet, {
    successDuration: 9000,
  });
  const [copiedLite, setCopiedLite] = useClipboard(variables.litecoinWallet, {
    successDuration: 9000,
  });
  const [copiedEth, setCopiedEth] = useClipboard(variables.ethereumWallet, {
    successDuration: 9000,
  });
  const [copiedDoge, setCopiedDoge] = useClipboard(variables.dogecoinWallet, {
    successDuration: 9000,
  });
  const [copiedTron, setCopiedTron] = useClipboard(variables.dogecoinWallet, {
    successDuration: 9000,
  });
  const [copiedCash, setCopiedCash] = useClipboard(
    variables.bitcoincashWallet,
    {
      successDuration: 9000,
    }
  );
  const [copiedUSDT, setCopiedUSDT] = useClipboard(variables.USDT, {
    successDuration: 9000,
  });

  const [percent, setPercent] = useState(0);
  const [amount, setAmount] = useState(0);
  const [returned, setReturned] = useState('');

  const { loading, success, message, error } = useSelector(
    (state) => state.userCreateTransAlert
  );

  const handleCalculate = () => {
    if (amount !== 0 && percent !== 0) {
      const answer = (Number(amount) * Number(percent)).toFixed(2);
      setReturned(answer);
    } else {
      alert('Enter a valid Investment amount and plan.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (coin && percent && amount) {
      if (window.confirm('Are you sure your input is correct?')) {
        dispatch(createTransAlert(coin, percent, amount));
      }
    }
  };

  useEffect(() => {
    if (success) {
      setAmount('');
      setPercent('');
      setCoin('');
    }
  }, [success]);

  return (
    <>
      <Helmet>
        <title>Deposit - {variables?.companyName}</title>
        <meta name="description" content="Deposit page" />
      </Helmet>
      <Container className="deposit" ref={focusDiv} tabIndex="-1">
        <Header text="Create Your Investment Plan" />

        <p className="special">
          <i className="flex gap-1">
            <FaInfoCircle divor="blue" size={25} /> Copy Any of our Wallet
            Address, Make a deposit and return to fill the form below.
          </i>
        </p>
        <br />
        <div className="flex flex-wrap gap-4">
          <div className="shadow-xl w-[250px] md:w-[400px] bg-white h-[200px] rounded py-6 px-2">
            <p className="break-words text-center font-medium text-lg">
              Bitcoin Wallet
            </p>
            <p className="break-words py-4 font-medium ">
              {variables.bitcoinWallet}
            </p>
            <Button
              onClick={setCopiedBtc}
              className="mx-auto d-block"
              variant="outline-primary"
            >
              {copiedBtc ? <span>Copied! 👍 </span> : <span>Copy</span>}
            </Button>
          </div>
          {/* <div className=' w-[250px] md:w-[400px] bg-white h-[200px] rounded py-6 px-2'>
            <p className='break-words text-center font-medium text-lg'>
              Litecoin Wallet
            </p>
            <p className='break-words py-4 font-medium '>
              {variables.litecoinWallet}
            </p>
            <Button
              onClick={setCopiedLite}
              className='mx-auto d-block'
              variant='outline-primary'
            >
              {copiedLite ? <span>Copied! 👍 </span> : <span>Copy</span>}
            </Button>
          </div> */}
          <div className=" w-[250px] md:w-[400px] bg-white h-[200px] rounded py-6 px-2">
            <p className="break-words text-center font-medium text-lg">
              Ethereum Wallet
            </p>
            <p className="break-words py-4 font-medium ">
              {variables.ethereumWallet}
            </p>
            <Button
              onClick={setCopiedEth}
              className="mx-auto d-block"
              variant="outline-primary"
            >
              {copiedEth ? <span>Copied! 👍 </span> : <span>Copy</span>}
            </Button>
          </div>
          <div className=' w-[250px] md:w-[400px] bg-white h-[200px] rounded py-6 px-2'>
            <p className='break-words text-center font-medium text-lg'>
              Dogecoin Wallet
            </p>
            <p className='break-words py-4 font-medium '>
              {variables.dogecoinWallet}
            </p>
            <Button
              onClick={setCopiedDoge}
              className='mx-auto d-block'
              variant='outline-primary'
            >
              {copiedDoge ? <span>Copied! 👍 </span> : <span>Copy</span>}
            </Button>
          </div>
          <div className=" w-[250px] md:w-[400px] bg-white h-[200px] rounded py-6 px-2">
            <p className="break-words text-center font-medium text-lg">
              Bitcoincash Wallet
            </p>
            <p className="break-words py-4 font-medium ">
              {variables.bitcoincashWallet}
            </p>
            <Button
              onClick={setCopiedCash}
              className="mx-auto d-block"
              variant="outline-primary"
            >
              {copiedCash ? <span>Copied! 👍 </span> : <span>Copy</span>}
            </Button>
          </div>
          <div className=" w-[250px] md:w-[400px] bg-white h-[200px] rounded py-6 px-2">
            <p className="break-words text-center font-medium text-lg">
              USDT Wallet
            </p>
            <p className="break-words py-4 font-medium ">{variables.USDT}</p>
            <Button
              onClick={setCopiedUSDT}
              className="mx-auto d-block"
              variant="outline-primary"
            >
              {copiedUSDT ? <span>Copied! 👍 </span> : <span>Copy</span>}
            </Button>
          </div>
          <div className=" w-[250px] md:w-[400px] bg-white h-[200px] rounded py-6 px-2">
            <p className="break-words text-center font-medium text-lg">
              Tron Wallet
            </p>
            <p className="break-words py-4 font-medium ">{variables.tronWallet}</p>
            <Button
              onClick={setCopiedTron}
              className="mx-auto d-block"
              variant="outline-primary"
            >
              {copiedTron ? <span>Copied! 👍 </span> : <span>Copy</span>}
            </Button>
          </div>
        </div>

        <div className="form-container my-5">
          <Form onSubmit={handleSubmit} className="form flex flex-col gap-4">
            {error && <Message variant="danger">{error}</Message>}
            {success && <Message variant="success">{message.message}</Message>}
            {loading && <Loader />}
            <Form.Group>
              <Form.Label>Choose Cryptocurrency</Form.Label>
              <Form.Control
                as="select"
                defaultValue="Choose..."
                onChange={(e) => {
                  setCoin(e.target.value);
                }}
                value={coin}
              >
                <option>Choose...</option>
                <option value="bitcoin">Bitcoin</option>
                {/* <option value='litecoin'>Litecoin</option> */}
                <option value="ethereum">Ethereum</option>
                <option value='doge'>Dogecoin</option>
                <option value="btccash">Bitcoincash</option>
                <option value="usdt">USDT</option>
                <option value="tron">Tron</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formGridInvestment">
              <Form.Label>($) Investment Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Amount"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plan</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setPercent(e.target.value);
                }}
                value={percent}
              >
                <option value="1.10"> Plan 1</option>
                <option value="1.15">Plan 2</option>
                <option value="1.35">Plan 3</option>
                <option value="1.40"> Plan 4</option>
              </Form.Control>
            </Form.Group>
            <Button
              type="button"
              onClick={() => handleCalculate()}
              variant="outline-info"
            >
              {' '}
              Calculate Deposit
            </Button>
            <Form.Group controlId="formGridReturn">
              <Form.Label>($) Return</Form.Label>
              <Form.Control
                type="number"
                placeholder="Return amount"
                readOnly
                value={returned}
              />
            </Form.Group>

            <Button
              variant="info"
              type="submit"
              className=" d-block mx-auto mt-2 bg-[#17a2b8] text-white"
              size="lg"
            >
              Save investment
            </Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default DepositInWallet;
