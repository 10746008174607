import React from 'react';
import { Container } from 'react-bootstrap';

const Header = ({ text = 'About Us' }) => {
  return (
    <header>
      <Container>
        <h1 className='text-2xl sm:text-3xl md:text-4xl font-bold py-6'>
          {text}
        </h1>
      </Container>
    </header>
  );
};

export default Header;
