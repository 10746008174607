import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { loginActions } from '../redux/actions/user.actions';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';
import { variables } from '../data/variables';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const {
    loading: loadingUserLogin,
    error: errorUserLogin,
    success: successUserLogin,
    userInfo: userInfoUserLogin,
  } = useSelector((state) => state.userLogin);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(loginActions(username, password));
  };

  useEffect(() => {
    let timeout;
    if (userInfoUserLogin?.data) {
      setUsername('');
      setPassword('');

      timeout = setTimeout(() => {
        if (userInfoUserLogin?.data?.isAdmin) {
          navigate('/dashboard-2');
        } else {
          navigate('/dashboard');
        }
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [userInfoUserLogin, navigate]);

  return (
    <section className='mt-4 '>
      <Helmet>
        <title>Login - {variables.companyName}</title>
        <meta name='description' content='Login' />
      </Helmet>

      <Container>
        <div className='w-full max-w-[450px] mx-auto py-8 shadow-3xl bg-slate-200 px-4'>
          <Header text='LOG IN' />

          {errorUserLogin && (
            <Message variant='danger'>{errorUserLogin}</Message>
          )}
          {successUserLogin && (
            <Message variant='success'>{userInfoUserLogin?.message}</Message>
          )}
          {loadingUserLogin && <Loader />}

          <Form onSubmit={handleSubmit} className='flex flex-col gap-4 pb-6'>
            <Form.Group controlId='formBasicUsername'>
              <Form.Control
                type='text'
                placeholder='Username'
                className='about-form'
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId='formBasicPassword'>
              <Form.Control
                type='password'
                placeholder='Password'
                className='about-form'
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button
              // variant='primary'
              type='submit'
              className='bg-blue-500'
              disabled={loadingUserLogin}
            >
              {loadingUserLogin ? <Loader /> : 'Login'}
            </Button>

            <p className='about-p  text-center'>
              Don't have an account?{' '}
              <Link
                to='/register'
                className='register-btn underline text-blue-600'
              >
                Register
              </Link>
            </p>

            <p className='about-p  text-center'>
              Forgot password?{' '}
              <Link
                to='/forgot-password'
                className='register-btn underline text-blue-600'
              >
                Reset password
              </Link>
            </p>

            {/* <p className='about-p  text-center pb-5'>
              Did not receive a verification code?{' '}
              <Link
                to='/request-verification'
                className='register-btn underline text-blue-600'
              >
                Request
              </Link>
            </p> */}
          </Form>
        </div>
      </Container>
    </section>
  );
};

export default Login;
