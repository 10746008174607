import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FaCheck, FaTimes } from 'react-icons/fa';

import {
  adminGetTrans,
  markTransAll,
  markTransPart,
} from '../redux/actions/user.actions';
import { percentHelper } from '../utility/percentChecker';
import Loader from './Loader';
import Message from './Message';
import Paginate from './Pagination';

const AdminGetTrans = ({ id }) => {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);

  const [part, setPart] = useState(null);

  const { loading, success, res, error } = useSelector(
    (state) => state.adminGetTrans
  );

  const { user } = useSelector((state) => state.getUser);

  const {
    success: successAll,
    res: resAll,
    error: errorAll,
  } = useSelector((state) => state.markTransAll);

  const {
    success: successPart,
    res: resPart,
    error: errorPart,
  } = useSelector((state) => state?.markTransPart);

  useEffect(() => {
    if (user) {
      dispatch(adminGetTrans(user?.user?._id));
    }
  }, [dispatch, user, successAll, successPart]);

  useEffect(() => {
    if (success) {
      setList(res?.trans);
      setPage(res?.page);
      setPages(res?.pages);
    }
  }, [res, success]);

  const handlePart = (id) => {
    if (
      window.confirm(
        'Are you sure you want to mark some part of this transaction as paid?'
      )
    ) {
      dispatch(markTransPart(id, part));
    }
  };

  const handleAll = (id) => {
    if (
      window.confirm('Are you sure you want to mark this transaction as paid?')
    ) {
      dispatch(markTransAll(id));
    }
  };

  return (
    <Container fluid>
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {errorAll && <Message variant="danger">{errorAll}</Message>}
      {successAll && <Message variant="success">{resAll?.message}</Message>}
      {errorPart && <Message variant="danger">{errorPart}</Message>}
      {successPart && <Message variant="success">{resPart?.message}</Message>}
      <Table striped bordered hover responsive className="table-sm trans-alert">
        <thead>
          <tr>
            <th>#</th>
            <th>USERNAME</th>
            <th>COIN</th>
            <th>PLAN</th>
            <th>DEPOSIT($)</th>
            <th>WITHDRAWAL($)</th>
            <th>TOTAL WITHDRAWAL($)</th>
            <th>REMAINING BALANCE($)</th>
            <th>PAID</th>
            <th>DUE DATE</th>
            <th>PAY ALL</th>
            <th>PAY PART</th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list?.length > 0 &&
            list?.map((tran, index) => {
              const {
                username,
                coin,
                percent,
                deposit,
                withdrawal,
                withdrawalDate,
                isWithdrawalPaid,
                totalWithdrawal,
                remainingAmount,
                paidAll,
                _id,
              } = tran;
              return (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{username}</td>
                  <td>{coin}</td>
                  <td>{percentHelper(percent)}</td>
                  <td>{deposit}</td>
                  <td>{withdrawal}</td>
                  <td>{totalWithdrawal}</td>
                  <td>{remainingAmount}</td>
                  <td>
                    {isWithdrawalPaid ? (
                      <FaCheck color="green" size={25} />
                    ) : (
                      <FaTimes color="red" size={25} />
                    )}
                  </td>
                  <td>{new Date(withdrawalDate).toLocaleDateString()}</td>
                  <td>
                    {isWithdrawalPaid ? (
                      <Button variant="success" disabled={isWithdrawalPaid}>
                        Paid
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="outline-warning"
                        onClick={() => handleAll(_id)}
                        className="no-wrap"
                        disabled={isWithdrawalPaid}
                      >
                        Pay All
                      </Button>
                    )}
                  </td>
                  <td className="td-part">
                    {paidAll ? (
                      <Button variant="success" disabled={paidAll}>
                        Paid
                      </Button>
                    ) : (
                      <>
                        <input
                          className="input-number"
                          type="number"
                          onChange={(e) => setPart(e.target.value)}
                          required
                          placeholder="enter amount"
                          disabled={paidAll}
                        />{' '}
                        <Button
                          type="button"
                          variant="outline-secondary"
                          onClick={() => handlePart(_id)}
                          className="no-wrap"
                          disabled={paidAll}
                        >
                          Pay Part
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {list?.length === 0 && (
        <Message variant="danger">No transaction at the moment</Message>
      )}
      {user && <Paginate adminUserTrans page={page} pages={pages} />}
    </Container>
  );
};

export default AdminGetTrans;
