import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addWallet } from '../../../redux/actions/user.actions';
import Message from '../../../components/Message';
import Loader from '../../../components/Loader';
import Header from '../../../components/Header';

const AddWallet = () => {
  const dispatch = useDispatch();

  const [walletId, setWalletId] = useState('');
  const [password, setPassword] = useState('');
  const [load, setLoad] = useState(false);

  const { loading, error, success } = useSelector((state) => state.addWallet);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(addWallet(walletId, password));
  };

  useEffect(() => {
    if (success) {
      setLoad(true);
    }
  }, [success]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setLoad(false);
      }, 7000);
    }
  }, [success]);

  return (
    <Container className='deposit '>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}

      <Header text='Link Wallet' />
      <div className='form-container my-5'>
        <Form onSubmit={handleSubmit} className='flex flex-col gap-4'>
          {load && <Message variant='info'>Linking...</Message>}
          {!load && success && (
            <Message variant='success'>Wallet Linked successfully.</Message>
          )}
          <Form.Group controlId='formGridInvestmentWalletID'>
            <Form.Label>Wallet ID</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Wallet ID'
              onChange={(e) => setWalletId(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId='formGridInvestmentPasssowrd'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button
            type='submit'
            size='lg'
            className='d-block mx-auto'
            variant='outline-primary'
          >
            Submit
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default AddWallet;
