import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AdminGetTrans from '../components/AdminGetTrans';
import Message from '../components/Message';
import CreateBonus from '../components/CreateBonus';
import CreateTransaction from '../components/CreateTransaction';
import { getUserById } from '../redux/actions/user.actions';
import UserPendingTrans from '../components/UserPendingTrans';
import { useLocation, useParams } from 'react-router-dom';

const UserEdit = () => {
  const dispatch = useDispatch();

  const [trans, setTrans] = useState([]);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [_id, setId] = useState('');
  const [theUser, setTheUser] = useState('');
  const [totalEarned, setTotalEarned] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [lBalance, setLBalance] = useState(0);
  const [aBalance, setABalance] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [referral, setReferral] = useState(0);

  //   const id = match.url.split('er/')[1].split('/edit')[0];
  const location = useLocation();
  const path = location?.pathname?.split('/')[3];
  const id = path;
  console.log(id);

  const { loading, success, user, error } = useSelector(
    (state) => state.getUser
  );

  //   console.log(user);

  const { success: successC } = useSelector((state) => state.createTrans);
  const { success: successA } = useSelector(
    (state) => state.approveWithdrawalRequests
  );
  const { success: successMA } = useSelector((state) => state.markTransAll);
  const { success: successTA } = useSelector((state) => state.markTransPart);

  useEffect(() => {
    dispatch(getUserById(id));
  }, [dispatch, id, successC, successA, successMA, successTA]);

  useEffect(() => {
    if (success) {
      setTrans(user?.pendingTrans);
      setEmail(user?.user?.email);
      setName(user?.user?.name);
      setUsername(user?.user?.username);
      setId(user?.user?._id);
      setTheUser(user);
      setTotalEarned(user?.earnedTotal);
      setTotalDeposit(user?.depositTotal);
      setABalance(user?.user?.balance);
      setLBalance(user?.user?.totalBalance);
      setBonus(user?.user?.bonus);
      setReferral(user?.user?.referral);
    }
  }, [success, user]);

  useEffect(() => {
    // console.log(location);
  }, [location]);
  return (
    <Container
      fluid
      className='justify-content-center align-items-center user-edit deposit dashboard'
    >
      <h2 className='main-header'>Edit User</h2>
      {error && <Message variant='danger'>{error}</Message>}
      <h4 className='text-center'>User Overview</h4>
      <Table striped bordered hover responsive className='table-sm trans-alert'>
        <thead>
          <tr>
            <th>NAME</th>
            <th>USERNAME</th>
            <th>EMAIL</th>
            <th>TOTAL EARNED</th>
            <th>TOTAL DEPOSIT</th>
            <th>LEDGER BALANCE</th>
            <th>AVAILABLE BALANCE</th>
            <th>BONUS</th>
            <th>REFERRALS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{name}</td>
            <td>{username}</td>
            <td>{email}</td>
            <td>${totalEarned}</td>
            <td>${totalDeposit}</td>
            <td>${lBalance}</td>
            <td>${aBalance}</td>
            <td>${bonus}</td>
            <td>
              {referral} - ${referral * 5}
            </td>
          </tr>
        </tbody>
      </Table>
      <div>
        <div className='my-5'>
          <h4 className='text-center my-3'>Pending Investments</h4>
          <Container fluid className='my-5'>
            <UserPendingTrans trans={trans} loading={loading} />
          </Container>
          <h4 className='text-center my-3'>Create Investments</h4>
          {theUser && <CreateTransaction userCon={user ? user?.user : {}} />}
        </div>
        <div>
          <h4 className='text-center'>Transactions</h4>
          {theUser && <AdminGetTrans />}
        </div>
        <div>
          <h4 className='text-center'>Create Bonus</h4>
          {theUser && <CreateBonus _id={_id} />}
        </div>
      </div>
    </Container>
  );
};

export default UserEdit;
