import React from 'react';
import CompanyStatistic from '../components/HomeComponents/CompanyStatistic';
import Converter from '../components/HomeComponents/Converter';
import Featured from '../components/HomeComponents/Featured';
import Features from '../components/HomeComponents/Features';
import Hero from '../components/HomeComponents/Hero';
import MarketChart from '../components/HomeComponents/MarketChart';
import MiniAbout from '../components/HomeComponents/MiniAbout';
import Mission from '../components/HomeComponents/Mission';
import ServiceSub from '../components/HomeComponents/ServiceSub';
import Testimonials from '../components/HomeComponents/Testimonials';
import WorkTogether from '../components/HomeComponents/WorkTogether';

const Home = () => {
  return (
    <div>
      <Hero />
      <MiniAbout />
      <Mission />
      <CompanyStatistic />
      <Converter />
      <ServiceSub />
      <MarketChart />
      <Featured />
      <Features />
      <WorkTogether />
      <Testimonials />
    </div>
  );
};

export default Home;
