import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheck } from 'react-icons/fa';

import {
  getUserDetailsActions,
  kycVerificationActions,
} from '../../../redux/actions/user.actions';
import Loader from '../../Loader';
import Message from '../../Message';

const KycVerification = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState('');

  const {
    loading: loadingKYC,
    success: successKYC,
    userInfo: userInfoKYC,
    error: errorKYC,
  } = useSelector((state) => state.kycVerification);

  const { loading, success, userInfo, error } = useSelector(
    (state) => state.userDetails
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(kycVerificationActions());
  };

  useEffect(() => {
    if (!success) {
      dispatch(getUserDetailsActions());
    }
  }, [success]);
  return (
    <div className='w-full max-w-[600px] py-6 bg-white rounded-xl m-4'>
      <h1 className='text-xl sm:text-2xl text-center'>KYC Verification</h1>

      {userInfo?.data?.kyc?.status ? (
        <div className='flex flex-col my-5 items-center'>
          <FaCheck color='green' size={40} />
          <Message variant='success'>
            Your KYC was successful. Your account has been verified
          </Message>
        </div>
      ) : (
        <div>
          <Form
            onSubmit={handleSubmit}
            className='mb-3 flex flex-col gap-3 px-4'
          >
            {errorKYC ? <Message variant='danger'>{errorKYC}</Message> : null}
            <Form.Group controlId='formBasicName'>
              <Form.Label>Upload ID card: </Form.Label>
              {image ? (
                <>
                  <img
                    src={URL.createObjectURL(image)}
                    alt=''
                    className='w-full max-w-[300px] h-full max-h-[300px] object-cover my-4'
                  />
                </>
              ) : null}
              <Form.Control
                type='file'
                accept='image/*'
                placeholder='Name'
                className='about-form'
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
                required
                disabled={loadingKYC}
              />
            </Form.Group>
            {loadingKYC ? (
              <Loader />
            ) : (
              <Button type='submit' className='my-4 bg-blue-500 text-white '>
                Submit KYC
              </Button>
            )}
          </Form>
        </div>
      )}
    </div>
  );
};

export default KycVerification;
