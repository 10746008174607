import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({ height = 40, width = 40 }) => {
  return (
    <Spinner
      animation='border'
      role='status'
      style={{
        width: `${height}px`,
        height: `${width}px`,
        margin: 'auto',
        display: 'block',
      }}
    >
      {/* <span className="sr-only">Loading...</span> */}
    </Spinner>
  );
};

export default Loader;
