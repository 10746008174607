import React from 'react';
import { Container } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { bronze, savers, silver } from '../images';

const InvestmentPlans = () => {
  return (
    <section className='py-10 bg-slate-100'>
      <Container>
        <Header text='Investment Plans' />

        <div>
          <h2 className='text-xl md:text-2xl py-3 text-slate-800 font-medium'>
            Returns on Investment
          </h2>

          <p className='text-base md:text-lg py-1 text-slate-600'>
            The profit from an activity for a particular period compared with
            the amount invested in it and paid on Daily basis.
          </p>

          <p className='text-base md:text-lg py-1 text-slate-600'>
            ROI is a popular metric because of its versatility and simplicity.
            Essentially, ROI can be used as a rudimentary gauge of an
            investment&apos;s profitability. This could be the ROI on a stock
            investment, the ROI a company expects on expanding to the next
            level, and the ROIs are generated in real term transactions. The
            calculation itself is not too complicated, and it is relatively easy
            to interpret for its wide range of applications. If an
            investment&apos;s ROI is net positive, it is probably worthwhile.
            But if other opportunities with higher ROIs are available, these
            signals can help investors eliminate or select the best options.
            Likewise, investors should avoid negative ROIs, those platforms that
            corrupt the markets, and also imply a net loss to all their
            investors. Study and grow with Fathom Portfolio and explore to
            control and earn your financial status.
          </p>

          <p className='text-base md:text-lg py-1 text-slate-600'>
            Fathom Portfolio prices you the smart and four various investment
            packages for you to earn fixed daily ROIs on your investment. Please
            review our investment packages which are stated below.
          </p>
        </div>

        <div className='flex flex-wrap justify-center gap-10 my-6'>
          <article className='flex flex-col gap-4 w-full max-w-[400px] shadow-2xl px-4 py-4 rounded-3xl bg-slate-300 '>
            <h3 className='text-center text-xl md:text-2xl py-3 text-slate-800 font-medium'>
              Beginner
            </h3>
            <div className='flex justify-center'>
              <img src={savers} alt='Savers' className='w-[80px] h-[80px]' />
            </div>
            <hr className='border-4' />
            <div className='flex flex-col gap-3'>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>100% capital insurance</p>
              </div>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>We offer Equity guarantee</p>
              </div>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>Automatic payout/withdrawal</p>
              </div>

              <div className='flex items-center justify-between'>
                <FaCheckCircle />

                <p>Free consultation</p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex gap-2'>
                  <FaCheckCircle />
                  Duration
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  90 Days
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex  gap-2'>
                  <FaCheckCircle />
                  Daily Profit
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  0.9%
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex  gap-2'>
                  <FaCheckCircle />
                  Referral
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  6.0%
                </p>
              </div>
            </div>
            <hr className='border-4' />
            <div className='flex flex-col justify-center gap-3 items-center'>
              <h3 className='font-bold text-xl md:text-2xl'>$ 100 - $ 2,999</h3>
              <Link
                to='/register'
                className='w-full py-1 text-slate-200 bg-blue-900 font-bold rounded-2xl hover:scale-105 text-center hover:text-slate-200'
              >
                SIGN UP
              </Link>
            </div>
          </article>

          <article className='flex flex-col gap-4 w-full max-w-[400px] shadow-2xl px-4 py-4 rounded-3xl bg-zinc-300 '>
            <h3 className='text-center text-xl md:text-2xl py-3 text-slate-800 font-medium'>
              Premium
            </h3>
            <div className='flex justify-center'>
              <img src={bronze} alt='bronze' className='w-[80px] h-[80px]' />
            </div>
            <hr className='border-4' />
            <div className='flex flex-col gap-3'>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>100% capital insurance</p>
              </div>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>We offer Equity guarantee</p>
              </div>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>Automatic payout/withdrawal</p>
              </div>

              <div className='flex items-center justify-between'>
                <FaCheckCircle />

                <p>Free consultation</p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex gap-2'>
                  <FaCheckCircle />
                  Duration
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  90 Days
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex  gap-2'>
                  <FaCheckCircle />
                  Daily Profit
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  1.26%
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex  gap-2'>
                  <FaCheckCircle />
                  Referral
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  6.00%
                </p>
              </div>
            </div>
            <hr className='border-4' />
            <div className='flex flex-col justify-center gap-3 items-center'>
              <h3 className='font-bold text-xl md:text-2xl'>
                $ 3,000 - $ 9,999
              </h3>
              <Link
                to='/register'
                className='w-full py-1 text-slate-200 bg-blue-900 font-bold rounded-2xl hover:scale-105 text-center hover:text-slate-200'
              >
                SIGN UP
              </Link>
            </div>
          </article>

          <article className='flex flex-col gap-4 w-full max-w-[400px] shadow-2xl px-4 py-4 rounded-3xl bg-stone-300 '>
            <h3 className='text-center text-xl md:text-2xl py-3 text-slate-800 font-medium'>
              SILVER
            </h3>
            <div className='flex justify-center'>
              <img src={silver} alt='silver' className='w-[80px] h-[80px]' />
            </div>
            <hr className='border-4' />
            <div className='flex flex-col gap-3'>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>100% capital insurance</p>
              </div>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>We offer Equity guarantee</p>
              </div>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>Automatic payout/withdrawal</p>
              </div>

              <div className='flex items-center justify-between'>
                <FaCheckCircle />

                <p>Free consultation</p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex gap-2'>
                  <FaCheckCircle />
                  Duration
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  90 Days
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex  gap-2'>
                  <FaCheckCircle />
                  Daily Profit
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  1.46%
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex  gap-2'>
                  <FaCheckCircle />
                  Referral
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  6.00%
                </p>
              </div>
            </div>
            <hr className='border-4' />
            <div className='flex flex-col justify-center gap-3 items-center'>
              <h3 className='font-bold text-xl md:text-2xl'>
                $ 10,000 - $ 39,999
              </h3>
              <Link
                to='/register'
                className='w-full py-1 text-slate-200 bg-blue-900 font-bold rounded-2xl hover:scale-105 text-center hover:text-slate-200'
              >
                SIGN UP
              </Link>
            </div>
          </article>

          <article className='flex flex-col gap-4 w-full max-w-[400px] shadow-2xl px-4 py-4 rounded-3xl bg-slate-200 '>
            <h3 className='text-center text-xl md:text-2xl py-3 text-slate-800 font-medium'>
              GOLD
            </h3>
            <div className='flex justify-center'>
              <img src={bronze} alt='bronze' className='w-[80px] h-[80px]' />
            </div>
            <hr className='border-4' />
            <div className='flex flex-col gap-3'>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>100% capital insurance</p>
              </div>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>We offer Equity guarantee</p>
              </div>
              <div className='flex items-center justify-between'>
                <FaCheckCircle />
                <p>Automatic payout/withdrawal</p>
              </div>

              <div className='flex items-center justify-between'>
                <FaCheckCircle />

                <p>Free consultation</p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex gap-2'>
                  <FaCheckCircle />
                  Duration
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  90 Days
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex  gap-2'>
                  <FaCheckCircle />
                  Daily Profit
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  1.65%
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex  gap-2'>
                  <FaCheckCircle />
                  Referral
                </div>
                <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                  6.00%
                </p>
              </div>
            </div>
            <hr className='border-4' />
            <div className='flex flex-col justify-center gap-3 items-center'>
              <h3 className='font-bold text-xl md:text-2xl'>
                $ 40,000 - $ Unlimited
              </h3>
              <Link
                to='/register'
                className='w-full py-1 text-slate-200 bg-blue-900 font-bold rounded-2xl hover:scale-105 text-center hover:text-slate-200'
              >
                SIGN UP
              </Link>
            </div>
          </article>
        </div>
      </Container>
    </section>
  );
};

export default InvestmentPlans;
