import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveWithdrawalRequests,
  withdrawalRequests,
} from '../redux/actions/user.actions';
import Loader from './Loader';
import Message from './Message';

const Withdrawals = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);

  const { loading, success, res, error } = useSelector(
    (state) => state.withdrawalRequests
  );
  const {
    // loading: loadingA,
    success: successA,
    resp,
    error: errorA,
  } = useSelector((state) => state.approveWithdrawalRequests);

  useEffect(() => {
    dispatch(withdrawalRequests());
  }, [dispatch, successA]);

  useEffect(() => {
    if (success) {
      setList(res);
    }
  }, [success, res]);

  const handleClick = (id) => {
    dispatch(approveWithdrawalRequests(id));
  };
  return (
    <div>
      <h3 className='text-center  text-xl font-bold py-4'>
        Pending Withdrawal Requests
      </h3>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}
      {errorA && <Message variant='danger'>{errorA}</Message>}
      {successA && <Message variant='success'>{resp.message}</Message>}

      <>
        <Table
          striped
          bordered
          hover
          responsive
          className='table-sm trans-alert'
        >
          <thead>
            <tr>
              <th>#</th>
              <th>USERNAME</th>
              <th>AMOUNT</th>
              <th>REQUEST DATE </th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 &&
              list.map((x, i) => {
                const { username, amount, _id, createdAt } = x;
                return (
                  <tr key={_id}>
                    <td>{i + 1}</td>
                    <td>{username}</td>
                    <td>${amount}</td>
                    <td>{new Date(createdAt).toLocaleDateString()}</td>
                    <td>
                      <Button
                        variant='outline-primary'
                        onClick={() => handleClick(_id)}
                      >
                        Approve
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {list.length === 0 && (
          <Message variant='danger'>
            There are no pending requests at the moment.
          </Message>
        )}
      </>
    </div>
  );
};

export default Withdrawals;
