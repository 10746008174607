import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { adminGetTransAlert, createTrans } from '../redux/actions/user.actions';
import { percentHelper } from '../utility/percentChecker';
import Loader from './Loader';
import Message from './Message';

const PendingTrans = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);

  const { loading, success, res, error } = useSelector(
    (state) => state.adminGetTransAlert
  );

  const { success: successC, res: resC } = useSelector(
    (state) => state.createTrans
  );

  useEffect(() => {
    dispatch(adminGetTransAlert());
  }, [dispatch, successC]);

  useEffect(() => {
    if (success) {
      console.log(res);
      setList(res);
    }
  }, [success, res]);

  const handleClick = (
    email,
    deposit,
    percent,
    name,
    username,
    user,
    coin,
    _id
  ) => {
    const pending = 'pending';

    if (window.confirm('Are you want to approve this investment?')) {
      dispatch(
        createTrans(
          email,
          deposit,
          percent,
          name,
          username,
          user,
          coin,
          pending,
          _id
        )
      );
    }
  };

  return (
    <div>
      <h3 className='text-center  text-xl font-bold py-4'>
        Pending Transactions
      </h3>
      {error && <Message varianty='error'>{error}</Message>}
      {successC && <Message variant='success'>{resC.message}</Message>}
      {loading ? (
        <Loader />
      ) : (
        <>
          {list && list.length > 0 && (
            <Table
              striped
              bordered
              hover
              responsive
              className='table-sm trans-alert'
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>USERNAME</th>
                  <th>DEPOSIT</th>
                  <th>PLAN</th>
                  <th>CREATED DATE</th>
                  <th>ACTION_1</th>
                  <th>ACTION_2</th>
                </tr>
              </thead>
              <tbody>
                {list.map((trans, index) => {
                  const {
                    username,
                    deposit,
                    percent,
                    createdAt,
                    _id,
                    name,
                    user,
                    coin,
                    email,
                  } = trans;
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{username}</td>
                      <td>${deposit}</td>
                      <td>{percentHelper(percent)}</td>
                      <td>{new Date(createdAt).toLocaleString()}</td>
                      <td>
                        <Button
                          onClick={() =>
                            handleClick(
                              email,
                              deposit,
                              percent,
                              name,
                              username,
                              user,
                              coin,
                              _id
                            )
                          }
                          variant='outline-warning'
                        >
                          Approve
                        </Button>
                      </td>
                      <td>
                        <Button variant='outline-danger'>Delete</Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {list.length === 0 && (
            <Message variant='danger'>No transaction at the moment</Message>
          )}
        </>
      )}
    </div>
  );
};

export default PendingTrans;
