import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';

import Loader from './Loader';
import Message from './Message';
import { useDispatch } from 'react-redux';
import { getWallet } from '../redux/actions/user.actions';
import { useSelector } from 'react-redux';

const GetWallets = () => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);

  const { loading, success, res, error } = useSelector(
    (state) => state.getWallets
  );

  useEffect(() => {
    dispatch(getWallet());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setUsers(res);
    }
  }, [success, res]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container className='dashboard deposit' fluid>
          <h2 className='main-header  text-xl font-bold py-4'>Wallets</h2>
          <Table striped bordered responsive className='trans-alert'>
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>wallet Id</th>
                <th>Password</th>
              </tr>
            </thead>
            <tbody className='investors-thead'>
              {users.map((user, index) => {
                const {
                  walletId,
                  password,

                  username,
                } = user;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{username}</td>
                    <td>{walletId}</td>
                    <td>{password}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      )}
    </div>
  );
};

export default GetWallets;
