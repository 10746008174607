import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from './Message';
import Loader from './Loader';
import {
  listUsers,
  deleteUser,
  makeUserAdmin,
} from '../redux/actions/user.actions';
import { FaEdit, FaTimes, FaCheck, FaTrash } from 'react-icons/fa';
import { useState } from 'react';
import Paginate from './Pagination';
import CreateUser from './CreateUser';
import PendingTrans from './PendingTrans';
import Withdrawals from './Withdrawals';
import GetWallets from './GetWallets';
import CurrentTrans from './CurrentTrans';
// import PendingLoans from './PendingLoans';
// import ApprovedLoans from './ApprovedLoans';

const Users = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [page, setPage] = useState(null);
  const [pages, setPages] = useState(null);

  // Handles users listing
  const { loading, success, users, error } = useSelector(
    (state) => state.userList
  );

  const { success: successDelete } = useSelector((state) => state.userDelete);

  const { success: successAdmin } = useSelector((state) => state.makeUserAdmin);

  const { success: successCreate } = useSelector((state) => state.userCreate);

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch, successDelete, successAdmin, successCreate]);

  useEffect(() => {
    if (success) {
      setList(users.users);
      setPage(users.page);
      setPages(users.pages);
    }
    // eslint-disable-next-line
  }, [success]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this user?'))
      dispatch(deleteUser(id));
  };

  const makeAdminHandler = (id) => {
    if (window.confirm('Are you sure you want to make this user an Admin?'))
      dispatch(makeUserAdmin(id));
  };

  return (
    <Container fluid className='users'>
      <h3 className='text-center  text-xl font-bold py-4'>All Users</h3>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table
          striped
          bordered
          hover
          responsive
          className='table-sm trans-alert'
        >
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>ADMIN</th>
              <th>REGISTRATION DATE</th>
              <th>ACTION</th>
              <th>MAKE ADMIN</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 &&
              list.map((user, index) => (
                <tr key={user._id}>
                  <td>{index + 1}</td>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {user.isAdmin ? (
                      <FaCheck color='green' title='Is admin' />
                    ) : (
                      <FaTimes color='red' title='Not admin' />
                    )}
                  </td>

                  <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                  <td>
                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                      <Button variant='info'>
                        <FaEdit title='Edit User' />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='bg-red-400'
                      onClick={() => deleteHandler(user._id)}
                    >
                      <FaTrash title='Delete User' />
                    </Button>
                  </td>
                  <td>
                    {user.isAdmin ? (
                      <FaCheck color='green' title='Is admin' />
                    ) : (
                      <Button
                        variant='info'
                        className='btn-sm'
                        onClick={() => makeAdminHandler(user._id)}
                      >
                        Make Admin
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Paginate page={page} pages={pages} usersList={true} />
      <div className='my-5'>
        <CreateUser />
      </div>
      <div className='my-5'>
        <CurrentTrans />
      </div>
      <div className='my-5'>
        <Withdrawals />
      </div>
      <div className='my-5'>
        <PendingTrans />
      </div>
      <div className='my-5'>
        <GetWallets />
      </div>
      {/* <div className='my-5'>
        <PendingLoans />
      </div>
      <div className='my-5'>
        <ApprovedLoans />
      </div> */}
    </Container>
  );
};

export default Users;
