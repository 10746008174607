import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { hero1, hero2, hero3 } from '../../images';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Hero = () => {
  const [liveUpdate, setLiveUpdate] = useState([]);

  const getCryptoRate = async () => {
    const { data } = await axios.get(
      'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false'
    );

    setLiveUpdate(data);
  };

  useEffect(() => {
    getCryptoRate();
  }, []);

  return (
    <section>
      <div className='hidden sm:block'>
        <Carousel
          infiniteLoop
          showArrows
          stopOnHover
          autoPlay
          showThumbs={false}
          interval={7000}
          animationHandler='fade'
          showIndicators={false}
          showStatus={false}
        >
          <div className='h-[500px]  sm:h-[600px]'>
            <img
              src={hero1}
              alt=''
              className='h-[500px]  sm:h-[600px] object-cover'
            />
            <div>
              <div
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '50%',
                  marginLeft: '-45%',
                }}
                className='text-black text-left bg-[rgba(255,255,255,0.8)] md:bg-transparent px-4 py-6'
              >
                <h1 className='text-3xl sm:text-5xl font-bold'>
                  Welcome to{' '}
                  <span className='text-red-400'>Fathom trade limited</span>
                </h1>
                <p className='max-w-[600px] my-4 text-xl sm:text-2xl'>
                  Fathom portfolio management is an online licensed registered
                  firm and asset management company with the top legal
                  authorities in investment that specializes in wide range of
                  FINANCIAL services (crypto,forex and real estate ) to its
                  investors.
                </p>
                <div>
                  <Link to='/register' className='btn btn-danger text-2xl'>
                    Join Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='h-[500px]  sm:h-[600px]'>
            <img
              src={hero2}
              alt=''
              className='h-[500px]  sm:h-[600px] object-cover border-2 border-red-500'
            />
            <div>
              <div
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '50%',
                  marginLeft: '-45%',
                }}
                className='text-slate-100 text-left'
              >
                <h1 className='text-3xl sm:text-5xl font-bold '>
                  Best passive fungible investment platform with{' '}
                  <span className='text-red-600'>Fathom trade limited</span>
                </h1>
                <p className='max-w-[600px] my-4 text-xl sm:text-2xl'>
                  We understand the needs of our customers very much that our
                  investment plans covers the widest range of benefits that is
                  suitable for every class.
                </p>
                <div>
                  <Link to='/register' className='btn btn-danger text-2xl'>
                    Join Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='h-[500px]  sm:h-[600px] '>
            <img
              src={hero3}
              alt=''
              className='h-[500px]  sm:h-[600px] object-contain'
            />

            <div>
              <div
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '50%',
                  marginLeft: '-45%',
                }}
                className='text-slate-900  text-left py-6 px-4 bg-[rgba(255,255,255,0.8)] md:bg-transparent'
              >
                <h1 className='text-3xl sm:text-5xl font-bold'>
                  Reinventing Trading with{' '}
                  <span className='text-red-600'>Fathom trade limited</span>
                </h1>
                <p className='max-w-[600px] my-4 text-xl sm:text-2xl'>
                  The most advanced intelligent monitoring technology with high
                  level of professionalism of our company provides safe returns
                  on your investments ensuring maximum efficiency.
                </p>
                <div>
                  <Link to='/register' className='btn btn-danger text-2xl'>
                    Join Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div className='block sm:hidden'>
        <Carousel
          infiniteLoop
          showArrows
          stopOnHover
          autoPlay
          showThumbs={false}
          interval={7000}
          animationHandler='fade'
          showIndicators={false}
          showStatus={false}
        >
          <div className='h-[500px]  sm:h-[600px] '>
            <img
              src={hero3}
              alt=''
              className='h-[500px]  sm:h-[600px] object-contain'
            />

            <div>
              <div
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '50%',
                  marginLeft: '-50%',
                }}
                className='text-slate-900  text-left py-6 px-4 bg-[rgba(255,255,255,0.8)] md:bg-transparent'
              >
                <h1 className='text-3xl sm:text-5xl font-bold'>
                  Reinventing Trading with{' '}
                  <span className='text-red-600'>Fathom trade limited</span>
                </h1>
                <p className='max-w-[600px] my-4 text-xl sm:text-2xl'>
                  The most advanced intelligent monitoring technology with high
                  level of professionalism of our company provides safe returns
                  on your investments ensuring maximum efficiency.
                </p>
                <div>
                  <Link to='/register' className='btn btn-danger text-2xl'>
                    Join Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div>
        <iframe
          src='https://bit2me.com/widget/crypto-carousel/v1'
          className='slider-again'
          frameborder='0'
          title='slider-again'
        ></iframe>
      </div>
      {/* <div className='slide-left-right'>
        <div className='flex gap-4 w-full overflow-hidden bg-[#212529] text-slate-900 items-center '>
          {liveUpdate &&
            liveUpdate.length > 0 &&
            liveUpdate?.map((x) => {
              const { name, image, current_price, price_change_24h, symbol } =
                x;

              return (
                <a
                  href='https://www.tradingview.com/symbols'
                  target='_blank'
                  rel='noopener noreferrer'
                  key={name}
                >
                  <div className='flex items-center border-r px-3 h-[80%]'>
                    <div className='min-w-[30px] min-h-[30px]'>
                      <img src={image} alt={name} width={30} height={30} />
                    </div>
                    <div className='flex flex-col px-1'>
                      <div className='uppercase text-[12px]'>{symbol}</div>
                      <small className='text-[8px]'>{name}</small>
                    </div>
                    <small className='text-[12px]'>
                      {current_price}/{price_change_24h}
                    </small>
                  </div>
                </a>
              );
            })}
        </div>
      </div> */}
    </section>
  );
};

export default Hero;
