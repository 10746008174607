import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { variables } from '../data/variables';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Header from '../components/Header';

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (window.confirm('Are you sure your input is correct?')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      setLoading(true);
      await axios
        .post(
          `${variables.backendLink}/api/users/forgot-password`,
          {
            email,
          },
          config
        )
        .then(function (response) {
          setLoading(false);
          setMessage(response.data.message);
          setSuccess(true);
        })
        .catch(function (error) {
          setError(true);
          console.log(error);
        });
    }
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <div className=''>
      <Helmet>
        <title>Forgot Password - {variables.companyName}</title>
        <meta name='description' content='Forgot Password page' />
      </Helmet>
      <Container
        fluid
        className='w-full max-w-[450px] m-auto mt-4 py-8 shadow-3xl bg-slate-200 px-4 rounded-[.25rem]'
      >
        <Row className='w-full  justify-center '>
          <Col className=' w-full m-auto' md={6}>
            {/* <h1 className="main-header my-3">Forgot Password</h1> */}

            <Header text='FORGOT PASSWORD' />

            <Form onSubmit={handleSubmit} className='flex flex-col gap-4'>
              {success && <Message variant='success'>{message}</Message>}
              <Form.Group controlId='formBasicEmail'>
                <Form.Control
                  type='email'
                  placeholder='Enter email address'
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                type='submit'
                variant='outline-primary'
                className=' d-block mx-auto mt-4 px-6'
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPasswordScreen;
