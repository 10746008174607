import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { userProfile } from '../redux/actions/user.actions';
import { variables } from '../data/variables';
import useClipboard from 'react-use-clipboard';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Header from '../components/Header';
import {
  FaCoins,
  FaMoneyBill,
  FaMoneyBillWave,
  FaPeopleCarry,
  FaUserFriends,
  FaWallet,
} from 'react-icons/fa';
import MarketChart from '../components/HomeComponents/MarketChart';
import Advanced from '../components/Trading/Advanced';

const Profile = () => {
  const dispatch = useDispatch();

  const [rec, setReceived] = useState(false);

  const { loading, success, res, error } = useSelector(
    (state) => state.userProfile
  );

  const [copiedReferral, setCopiedReferral] = useClipboard(
    `${variables.clientUrl}/register/?ref=${res?.user?.username}`,
    {
      successDuration: 9000,
    }
  );

  const { resFB, successFB } = useSelector((state) => state.createTransFromB);

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch, resFB, successFB]);

  useEffect(() => {
    if (success) {
      // console.log(res);
      setReceived(true);
    }
  }, [success, res, resFB]);

  return (
    <Container fluid>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}
      {/* {rec && res && (
        <h2 className='text-lg font-bold py-4'>
          {' '}
          Referral Link: {variables.clientUrl}/register/?ref=
          {res.user.username}
        </h2>
      )} */}

      {/* {rec && res && (
        <Table striped bordered hover responsive className='-z-10'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>USERNAME</th>
              <th>EMAIL</th>
              <th>REFERRALS</th> */}
      {/* <th>REFERRALS EARNINGS</th>
              <th>LEDGER BALANCE</th>
              <th>AVAILABLE BALANCE</th>
              <th>TOTAL EARNED</th>
              <th>TOTAL DEPOSIT</th>
              <th>BONUS</th> */}
      {/* </tr>
          </thead>
          <tbody>
            <tr>
              <td>{res?.user?.name}</td>
              <td>{res?.user?.username}</td>
              <td>{res?.user?.email}</td>
              <td>{res?.user?.referral}</td> */}
      {/* <td>${res?.user?.referral * 5}</td>
              <td>${res?.user?.totalBalance}</td>
              <td>${res?.user?.balance}</td>
              <td>${res?.earnedTotal}</td>
              <td>${res?.depositTotal}</td>
              <td>${res?.user?.bonus}</td> */}
      {/* </tr>
          </tbody>
        </Table>
      )}

      <br />
      <br />
      <br /> */}

      <div className='border border-red-500 flex flex-wrap  gap-6'>
        <div className='flex justify-between w-full max-w-[500px] shadow-md bg-slate-300 p-3 rounded-md'>
          <div>
            <div className='font-bold text-lg'>
              $
              {res?.user?.balance
                ? Number(res?.user?.balance)?.toLocaleString()
                : 0}
            </div>
            <div className='font-medium'>Total Available Balance</div>
          </div>
          <div className='bg-white rounded-full p-3 w-fit h-fit'>
            <FaMoneyBill size={40} color='green' />
          </div>
        </div>

        <div className='flex justify-between w-full max-w-[500px] shadow-md bg-slate-300 p-3 rounded-md'>
          <div>
            <div className='font-bold text-lg'>
              $
              {res?.user?.totalBalance
                ? Number(res?.user?.totalBalance)?.toLocaleString()
                : 0}
            </div>
            <div className='font-medium'>Total Ledger Balance</div>
          </div>
          <div className='bg-white rounded-full p-3 w-fit h-fit'>
            <FaMoneyBill size={40} color='gray' />
          </div>
        </div>

        <div className='flex justify-between w-full max-w-[500px] shadow-md bg-slate-300 p-3 rounded-md'>
          <div>
            <div className='font-bold text-lg'>
              $
              {res?.depositTotal
                ? Number(res?.depositTotal)?.toLocaleString()
                : 0}
            </div>
            <div className='font-medium'>Total Deposit</div>
          </div>
          <div className='bg-white rounded-full p-3 w-fit h-fit'>
            <FaWallet size={40} color='blue' />
          </div>
        </div>

        <div className='flex justify-between w-full max-w-[500px] shadow-md bg-slate-300 p-3 rounded-md'>
          <div>
            <div className='font-bold text-lg'>
              $
              {res?.earnedTotal
                ? Number(res?.earnedTotal)?.toLocaleString()
                : 0}
            </div>
            <div className='font-medium'>Total Earned</div>
          </div>
          <div className='bg-white rounded-full p-3 w-fit h-fit'>
            <FaMoneyBillWave size={40} color='orange' />
          </div>
        </div>

        <div className='flex justify-between w-full max-w-[500px] shadow-md bg-slate-300 p-3 rounded-md'>
          <div>
            <div className='font-bold text-lg'>
              $
              {res?.user?.bonus
                ? Number(res?.user?.bonus)?.toLocaleString()
                : 0}
            </div>
            <div className='font-medium'>Total Bonus</div>
          </div>
          <div className='bg-white rounded-full p-3 w-fit h-fit'>
            <FaCoins size={40} color='purple' />
          </div>
        </div>
        <div className='flex justify-between w-full max-w-[500px] shadow-md bg-slate-300 p-3 rounded-md'>
          <div>
            <div className='font-bold text-lg'>
              $
              {res?.recentBonus
                ? Number(res?.user?.recentBonus)?.toLocaleString()
                : 0}
            </div>
            <div className='font-medium'>Recent Bonus</div>
          </div>
          <div className='bg-white rounded-full p-3 w-fit h-fit'>
            <FaCoins size={40} color='black' />
          </div>
        </div>
        <div className='flex justify-between w-full max-w-[500px] shadow-md bg-slate-300 p-3 rounded-md'>
          <div>
            <div className='font-bold text-lg'>
              {res?.user?.referral}
              {/* $
              {res?.user?.referral
                ? Number(res?.user?.referral * 5)?.toLocaleString()
                : 0} */}
            </div>
            <div className='font-medium'>Total Referrals</div>
          </div>
          <div className='bg-white rounded-full p-3 w-fit h-fit'>
            <FaUserFriends size={40} color='cyan' />
          </div>
        </div>

        <div className='flex flex-col justify-between w-full max-w-[500px] shadow-md bg-slate-300 p-3 rounded-md'>
          <p className='break-words text-center font-medium text-lg'>
            Referral link
          </p>
          <p className='break-words py-4 font-medium '>
            {variables.clientUrl}/register/?ref=
            {res?.user?.username}
          </p>
          <Button
            onClick={setCopiedReferral}
            className='mx-auto d-block'
            variant='outline-primary'
          >
            {copiedReferral ? <span>Copied! 👍 </span> : <span>Copy</span>}
          </Button>
        </div>
      </div>
      <br />
      <br />
      <MarketChart />
      <Advanced />
    </Container>
  );
};

export default Profile;
