import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import UserList from '../components/Users';
import Header from '../components/Header';
import { useDispatch } from 'react-redux';
import { logoutActions } from '../redux/actions/user.actions';
import { FaSignOutAlt } from 'react-icons/fa';

const Admin = () => {
  const dispatch = useDispatch();
  return (
    <div className='admin'>
      <Helmet>
        <title>Admin </title>
        <meta name='description' content='Admin page' />
      </Helmet>
      <Container>
        <div className='flex justify-between'>
          <Header text='Administration' />
          <button
            className={`flex gap-2 items-center whitespace-nowrap  px-6 text-red-500`}
            onClick={() => dispatch(logoutActions())}
          >
            <span>
              <FaSignOutAlt size={16} color='red' />
            </span>
            <span>Logout</span>
          </button>
        </div>
        <Row>
          <Col>
            <UserList />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Admin;
