import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';

import { variables } from '../../../data/variables';
import {
  editUserDetailsActions,
  getUserDetailsActions,
  logoutActions,
  updateUserProfile,
  userProfile,
} from '../../../redux/actions/user.actions';
import Loader from '../../Loader';
import Message from '../../Message';

const EditDetails = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');

  const { loading, success, res, error } = useSelector(
    (state) => state.userProfile
  );

  const {
    loading: loadingEdit,
    success: successEdit,
    userInfo: userInfoEdit,
    error: errorEdit,
  } = useSelector((state) => state.userUpdateProfile);
  const {
    loading: loadingKYC,
    success: successKYC,
    userInfo: userInfoKYC,
    error: errorKYC,
  } = useSelector((state) => state.kycVerification);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateUserProfile({ name, email, phone, country, zipCode, address })
    );
  };

  useEffect(() => {
    dispatch(getUserDetailsActions());
  }, [dispatch, successEdit, successKYC]);

  useEffect(() => {
    const user = res?.user;

    setAddress(user?.address);
    setEmail(user?.email);
    setName(user?.name);
    setCountry(user?.country);
    setPhone(user?.phone);
    setZipCode(user?.zipCode);
  }, [success, res]);

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);

  return (
    <section className='flex flex-wrap'>
      <div className='w-full max-w-[400px] py-6 bg-white rounded-xl m-4'>
        <div className='border-b p-2'>User Details</div>
        {loading ? (
          <Loader />
        ) : (
          <div className='p-2 my-6 '>
            <h1 className='text-lg sm:text-xl font-bold text-center'>
              {res?.user?.name}
            </h1>

            <div className='text-center my-3 font-medium'>
              <p className='border border-r-0 border-l-0 py-3'>
                Referral Link: {variables?.clientUrl}/register?ref=
                {res?.user?._id}
              </p>
              <p className='border border-r-0 border-l-0 py-3'>
                Username: {res?.user?.username}
              </p>
              {/*
              <div className='text-center'>
                <Link
                  to='/dashboard/edit-details'
                  className='border border-r-0 border-l-0 py-3 bg-red-500 text-white flex w-full justify-center'
                >
                  Change Password
                </Link>
              </div> */}
              <div className='text-center'>
                <p
                  to='/dashboard/edit-details'
                  className='border border-r-0 border-l-0 py-3 bg-red-700 cursor-pointer text-white flex w-full justify-center'
                  onClick={() => {
                    dispatch(logoutActions());
                  }}
                >
                  Logout
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='w-full max-w-[600px] py-6 bg-white rounded-xl m-4'>
        <div className='border-b p-2'>Edit User Details</div>
        {loading ? (
          <Loader />
        ) : (
          <div className='p-2 my-6 '>
            <Form
              onSubmit={handleSubmit}
              className='mb-3 flex flex-col gap-3 px-4'
            >
              <Form.Group controlId='formBasicName'>
                <Form.Label>Name: </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  className='about-form'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>Email: </Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Email'
                  className='about-form'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>
              <Form.Group controlId='formBasicPhone'>
                <Form.Label>Phone: </Form.Label>
                <Form.Control
                  type='tel'
                  placeholder='Phone number'
                  className='about-form'
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </Form.Group>
              <Form.Group controlId='formBasicCountry'>
                <Form.Label>Country: </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Country'
                  className='about-form'
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                />
              </Form.Group>
              <Form.Group controlId='formBasicZipCode'>
                <Form.Label>Zip code: </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Zip Code'
                  className='about-form'
                  onChange={(e) => setZipCode(e.target.value)}
                  value={zipCode}
                />
              </Form.Group>
              <Form.Group controlId='formBasicZipCode'>
                <Form.Label>User Address: </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Address'
                  className='about-form'
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </Form.Group>
              {successEdit ? (
                <Message variant='success'>
                  Profile Updated successfully
                </Message>
              ) : null}
              {loadingEdit ? (
                <Loader />
              ) : (
                <Button
                  type='submit'
                  variant='primary'
                  className='bg-blue-500 text-slate-100'
                >
                  Update User
                </Button>
              )}
            </Form>
          </div>
        )}
      </div>
    </section>
  );
};

export default EditDetails;
