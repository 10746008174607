import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { userProfile } from '../../../redux/actions/user.actions';
import { variables } from '../../../data/variables';

import Message from '../../../components/Message';
import Loader from '../../../components/Loader';
import Header from '../../../components/Header';

const Profile = () => {
  const dispatch = useDispatch();

  const [rec, setReceived] = useState(false);

  const { resFB, successFB } = useSelector((state) => state.createTransFromB);

  const { loading, success, res, error } = useSelector(
    (state) => state.userProfile
  );

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch, resFB, successFB]);

  useEffect(() => {
    if (success) {
      setReceived(true);
    }
  }, [success, res, resFB]);
  return (
    <div>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}
      {rec && res && (
        <h2 className='text-lg font-bold py-4'>
          {' '}
          Referral Link: {variables.frontendLink}/register/?ref=
          {res.user.username}
        </h2>
      )}

      <div className='overflow-x-auto'>
        {rec && res && (
          <Table striped bordered hover responsive className='table-sm w-full'>
            <thead>
              <tr>
                <th>NAME</th>
                <th>USERNAME</th>
                <th>EMAIL</th>
                <th>REFERRALS</th>
                <th>REFERRALS EARNINGS</th>
                <th>LEDGER BALANCE</th>
                <th>AVAILABLE BALANCE</th>
                <th>TOTAL EARNED</th>
                <th>TOTAL DEPOSIT</th>
                <th>BONUS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{res.user.name}</td>
                <td>{res.user.username}</td>
                <td>{res.user.email}</td>
                <td>{res.user.referral}</td>
                <td>${res.user.referral * 5}</td>
                <td>${res.user.totalBalance}</td>
                <td>${res.user.balance}</td>
                <td>${res.earnedTotal}</td>
                <td>${res.depositTotal}</td>
                <td>${res.user.bonus}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Profile;
