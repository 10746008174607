import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { service1, service2, service3 } from '../../images';

const ServiceSub = () => {
  return (
    <section className='py-10'>
      <Container>
        <div className='flex items-center gap-2 md:gap-8 flex-wrap'>
          <h2 className='text-lg sm:text-3xl font-bold text-blue-900 text-center'>
            Services What We Offer to our Clients
          </h2>
          <p className='py-3 px-3 text-slate-800 max-w-[500px] text-base md:text-lg font-light'>
            Bring to the table win-win survival strategies to ensure proactive
            domination. At the end of the day, going forward, a new normal that
            has evolved from generation X is on the bulls run.
          </p>
        </div>
        <div className='flex gap-6 justify-between flex-wrap pt-4 '>
          <article className='mx-auto'>
            <div className='w-full max-w-[350px] h-[250px]'>
              <img
                src={service1}
                alt=''
                className='w-full h-full object-cover'
              />
            </div>
            <h2 className='bg-slate-700 text-slate-200 w-full max-w-[350px] text-base md:text-xl font-bold px-3 py-1'>
              CRYPTO CURRENCY TRADING
            </h2>
          </article>
          <article className='mx-auto'>
            <div className='w-full max-w-[350px] h-[250px]'>
              <img
                src={service2}
                alt=''
                className='w-full h-full object-cover'
              />
            </div>
            <h2 className='bg-slate-700 text-slate-200 w-full max-w-[350px] text-base md:text-xl font-bold px-3 py-1'>
              FOREX TRADING
            </h2>
          </article>
          <article className='mx-auto'>
            <div className='w-full max-w-[350px] h-[250px]'>
              <img
                src={service3}
                alt=''
                className='w-full h-full object-cover'
              />
            </div>
            <h2 className='bg-slate-700 text-slate-200 w-full max-w-[350px] text-base md:text-xl font-bold px-3 py-1'>
              COMMODITIES TRADING
            </h2>
          </article>
        </div>
      </Container>
      <div className='flex justify-center py-4'>
        <Link
          to='/service'
          className='btn btn-danger text-[14px] rounded-none py-2'
        >
          VIEW ALL SERVICES
        </Link>
      </div>
    </section>
  );
};

export default ServiceSub;
