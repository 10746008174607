import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createBonus } from '../redux/actions/user.actions';
// import { createBonus } from '../actions/userActions';
import Loader from './Loader';
import Message from './Message';

const CreateBonus = ({ _id }) => {
  const dispatch = useDispatch();

  const [bonus, setBonus] = useState(0);

  const { loading, error, res, success } = useSelector(
    (state) => state.createBonus
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (bonus === 0) {
      alert('Enter a valid Investment amount');
    } else {
      dispatch(createBonus(bonus, _id));
    }
  };

  useEffect(() => {
    if (success) {
      setBonus(0);
    }
  }, [success]);

  return (
    <Container>
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {success && <Message variant="success">{res?.message}</Message>}

      <Row className="justify-content-center">
        <Col className="py-2 my-2" md={6}>
          <div className="form-container">
            <Form onSubmit={handleSubmit} className="form">
              <Form.Group controlId="formGridInvestment">
                <Form.Label>($) Bonus Amount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Amount"
                  onChange={(e) => setBonus(e.target.value)}
                />
              </Form.Group>
              <Button
                type="submit"
                size="lg"
                className="d-block mx-auto my-4"
                variant="outline-primary"
              >
                Give Bonus
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateBonus;
