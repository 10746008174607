import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { variables } from '../../data/variables';
import { workers } from '../../images';

const Mission = () => {
  return (
    <section className='py-10 md:min-h-[500px]'>
      <Container className='flex flex-col md:flex-row items-center md:justify-center gap-4 md:items-start'>
        <div className='relative'>
          <div className='w-full max-w-[500px] '>
            <img src={workers} alt='mission' classname='w-full object-cover' />
          </div>
          <div className='absolute bottom-0 right-0 bg-blue-900 text-slate-200 w-full md:w-[150px] h-[40px] text-right text-xl font-bold flex items-center justify-end px-4'>
            About Us
          </div>
        </div>
        <div className='w-full max-w-[500px] justify-self-start '>
          <h2 className='text-lg sm:text-3xl font-bold text-blue-900'>
            Mission of our company to give best service to customers.
          </h2>
          <p className='text-base sm:text-lg my-3 font-medium'>
            We are a forex and crypto trading investment organization. With our
            years of experience in trading on the financial market, our experts
            have successfully built sustainable income for our partners and
            investors. We have our office in Marshall Islands and we are
            licensed and regulated by the financial bodies necessary.
          </p>
          <p className='mb-4'>
            At {variables.companyNameFull}, we understand that decentralized
            cryptocurrencies like Bitcoin and Ethereum will change the way the
            world views and uses money so, we are spearheading a new financial
            system being built in real-time, and we believe this new worldwide
            crypto financial system will accelerate humanity for a long time
            into the future.
          </p>
          <Link to='/about' className='text-red-400 font-bold'>
            FIND OUR MORE
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default Mission;
