import React, { useEffect } from 'react';
import { logo, logoFull } from '../../images';
import { variables } from '../../data/variables';

import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { userProfile } from '../../redux/actions/user.actions';
import { Link } from 'react-router-dom';

const NavBar = ({ showSideBarMenu = false, setShowSideBarMenu }) => {
  const dispatch = useDispatch();

  const { loading, success, res, error } = useSelector(
    (state) => state.userProfile
  );

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);

  return (
    <nav className='flex gap-20 px-8 py-2 items-center bg-slate-900'>
      <div>
        {showSideBarMenu && (
          <AiOutlineMenuFold
            size={25}
            onClick={() => setShowSideBarMenu((prev) => !prev)}
            className='cursor-pointer text-slate-200 hover:text-white'
          />
        )}
        {!showSideBarMenu && (
          <AiOutlineMenuUnfold
            size={25}
            onClick={() => setShowSideBarMenu((prev) => !prev)}
            className='cursor-pointer text-slate-200 hover:text-white'
          />
        )}
      </div>
      <div className='flex items-center flex-col'>
        <img
          src={logo}
          alt={variables?.companyNameFull}
          width={100}
          //   height={50}
          className='h-[40px] object-contain'
        />
        <div className='text-slate-400 text-sm '>
          {variables.companyNameFull}
        </div>
      </div>

      {res?.user?.isAdmin ? (
        <Link to='/dashboard-2' className='bg-white text-black py-4 px-8'>
          ADMIN DASHBOARD
        </Link>
      ) : null}
    </nav>
  );
};

export default NavBar;
