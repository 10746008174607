import React from 'react';
import { Container } from 'react-bootstrap';
import {
  ah,
  alexander,
  amelie,
  charloote,
  emiko,
  habib,
  hamia,
  nicola,
  prof,
  romano,
  schi,
} from '../images';

const list = [
  {
    image: nicola,
    name: 'Sandra Gates',
    position: 'UK',
  },
  {
    image: alexander,
    name: 'Philips Palee',
    position: 'Canadian',
  },
  {
    image: charloote,
    name: 'Magdalene Arthor',
    position: 'Australian',
  },
  {
    image: schi,
    name: 'Paul Hasley',
    position: 'German',
  },
  {
    image: romano,
    name: 'Grooks Michele',
    position: 'Italian',
  },
  {
    image: habib,
    name: 'krish Arya',
    position: 'Saudi Arabia',
  },
  {
    image: prof,
    name: 'Prof. Ferdinand Cooks',
    position: 'USA',
  },
  {
    image: amelie,
    name: 'Bridgeton Cage',
    position: 'France',
  },
  {
    image: ah,
    name: 'Billy Amanda',
    position: 'Thailand',
  },
  {
    image: emiko,
    name: 'Haru Asahi',
    position: 'Thailand',
  },
  {
    image: hamia,
    name: 'Maryam Takke',
    position: 'Morocco',
  },
];

const Team = () => {
  return (
    <section className='py-10  bg-slate-600 text-slate-200'>
      <Container>
        <h2 className='text-xl sm:text-2xl font-bold text-red-400 py-6'>
          Our Amazing Team
        </h2>
        <div
          className='flex flex-wrap'
          style={{
            rowGap: '5rem',
          }}
        >
          {list?.map((x) => {
            return (
              <div
                key={x.name}
                className='w-full max-w-[300px] h-[300px] mx-auto relative'
              >
                <div className='w-full max-w-[300px] h-[300px]'>
                  <img
                    src={x.image}
                    alt={x.name}
                    className='w-full h-full object-cover rounded-full'
                  />
                </div>
                <div className='text-center bg-slate-200 text-slate-800 absolute  bottom-0 right-0 left-0 w-[80%] mx-auto py-2'>
                  <h2 className='text-xl font-bold'>{x.name}</h2>
                  <p className='text-lg font-medium'>{x.position}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Team;
