import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  registerActions,
  resendVerificationActions,
} from '../redux/actions/user.actions';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';
import { variables } from '../data/variables';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
const ResendVerification = () => {
  const dispatch = useDispatch();

  const {
    loading: loadingResend,
    error: errorResend,
    success: successResend,
    userInfo: userInfoResend,
  } = useSelector((state) => state.resendVerification);

  const [email, setEmail] = useState('');

  const handleResend = (e) => {
    e.preventDefault();

    if (email) {
      dispatch(resendVerificationActions(email));
    }
  };

  useEffect(() => {
    if (successResend) {
      setEmail('');
    }
  }, [successResend]);
  return (
    <section className='py-10'>
      <Helmet>
        <title>Request verification - {variables.companyName}</title>
        <meta name='description' content='Register' />
      </Helmet>

      <Container>
        <div className='w-full max-w-[450px] mx-auto shadow-3xl bg-slate-200 px-4'>
          <Header text='REQUEST VERIFICATION' />

          <div className='min-h-[200px] py-4'>
            {userInfoResend ? (
              <Message variant='success'>{userInfoResend?.message}</Message>
            ) : null}

            {errorResend && <Message variant='danger'>{errorResend}</Message>}
            {loadingResend && <Loader />}
            <Form onSubmit={handleResend} className='flex flex-col gap-4'>
              <Form.Text>Didn't receive a verification code?</Form.Text>
              <Form.Group controlId='formBasicEmail'>
                <Form.Control
                  type='email'
                  placeholder='Email address'
                  className='about-form'
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  value={email}
                />
              </Form.Group>
              <Button
                // variant='primary'
                type='submit'
                className='bg-blue-500'
                disabled={loadingResend}
              >
                {loadingResend ? <Loader /> : 'Resend'}
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ResendVerification;
