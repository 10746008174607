import React, { useRef, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Container, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../../components/Message';
import Loader from '../../../components/Loader';
import Header from '../../../components/Header';
import { createTransFromB } from '../../../redux/actions/user.actions';
import { useEffect } from 'react';
import { variables } from '../../../data/variables';

const CreateTransFromB = () => {
  const focusDiv = useRef(null);

  const refForm = useRef(null);

  useEffect(() => {
    focusDiv.current.focus();
  }, []);

  const dispatch = useDispatch();

  const [coin, setCoin] = useState('');
  const [percent, setPercent] = useState(0);
  const [amount, setAmount] = useState(0);
  const [returned, setReturned] = useState('');

  const { loading, success, res, error } = useSelector(
    (state) => state.createTransFromB
  );

  const handleCalculate = () => {
    if (amount !== 0 && percent !== 0) {
      const answer = (Number(amount) * Number(percent)).toFixed(2);
      setReturned(answer);
    } else {
      alert('Enter a valid Investment amount and plan.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const deposit = amount;
    if (window.confirm('Are you sure your input is correct?')) {
      dispatch(createTransFromB(coin, percent, deposit));
    }
  };

  useEffect(() => {
    if (success) {
      setAmount(0);
      setPercent(0);
      setCoin('');
      refForm.current.reset();
      setReturned('');
    }
  }, [success, res]);

  return (
    <>
      <Helmet>
        <title>Deposit - {variables?.companyName}</title>
        <meta name="description" content="Deposit page" />
      </Helmet>
      <Container className="deposit" ref={focusDiv} tabIndex="-1">
        <Header text="Create Your Investment Plan From Balance" />

        <div className="form-container my-5">
          <Form
            onSubmit={handleSubmit}
            className=" flex flex-col gap-5"
            ref={refForm}
          >
            {error && <Message variant="danger">{error}</Message>}
            {success && <Message variant="success">{res.message}</Message>}
            {loading && <Loader />}
            <Form.Group>
              <Form.Label>Choose Cryptocurrency</Form.Label>
              <Form.Control
                as="select"
                defaultValue="bitcoin"
                onChange={(e) => {
                  setCoin(e.target.value);
                }}
              >
                 <option value="bitcoin">Bitcoin</option>
                {/* <option value='litecoin'>Litecoin</option> */}
                <option value="ethereum">Ethereum</option>
                <option value='doge'>Dogecoin</option>
                <option value="btccash">Bitcoincash</option>
                <option value="usdt">USDT</option>
                <option value="tron">Tron</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formGridInvestmentc">
              <Form.Label>($) Investment Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Amount"
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plan</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setPercent(e.target.value);
                }}
              >
                <option value="1.10"> Beginner Plan</option>
                <option value="1.15"> Premium Plan</option>
                <option value="1.35"> Silver Plan</option>
                <option value="1.40"> Gold Plan</option>
              </Form.Control>
            </Form.Group>
            <Button
              type="button"
              onClick={() => handleCalculate()}
              variant="outline-info"
            >
              {' '}
              Calculate Deposit
            </Button>
            <Form.Group controlId="formGridReturnc">
              <Form.Label>($) Return</Form.Label>
              <Form.Control
                type="number"
                placeholder="Return amount"
                readOnly
                value={returned}
              />
            </Form.Group>

            <Button
              variant="outline-info"
              type="submit"
              className=" d-block mx-auto mt-2"
              size="lg"
            >
              Save investment
            </Button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CreateTransFromB;
