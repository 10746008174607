import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

const Converter = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://cdn.jsdelivr.net/gh/dejurin/crypto-converter-widget@1.5.2/dist/latest.min.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);
  return (
    <section className="pt-10 bg-slate-700">
      <Container>
        <div className="flex  flex-wrap h-full justify-between py-6 ">
          <div className="w-full max-w-[500px] h-[400px]  mx-auto">
            <iframe
              src="https://bit2me.com/widget/chart/v1?currency=B2M&fiat=USDT"
              className="w-full h-[430px]"
              frameBorder="0"
              title="indicator"
            ></iframe>
          </div>
          <div className="w-full max-w-[500px]   mx-auto">
            <div>
              <h2 className="text-lg sm:text-3xl font-bold text-slate-200">
                NEW CURRENCY BITCOIN
              </h2>
              <p className="text-slate-200">
                Bitcoin is one of the most important inventions in all of human
                history. For the first time ever, anyone can send or receive any
                amount of money with anyone else, anywhere on the planet,
                conveniently and without restriction. Transactions are made with
                no middle men - meaning, no banks! There are no transaction fees
                and no need to give your real name. More merchants are beginning
                to accept them.
              </p>
            </div>
            <div>
              <h2 className="text-lg sm:text-3xl font-bold text-slate-200 pt-4 pb-2">
                CURRENCY CONVERTER
              </h2>
              <crypto-converter-widget
                shadow
                symbol
                live
                background-color="#F5F7DC"
                border-radius="0.60rem"
                fiat="united-states-dollar"
                crypto="bitcoin"
                amount="1"
                decimal-places="2"
              ></crypto-converter-widget>
            </div>
          </div>
        </div>
      </Container>
      <iframe
        src="https://bit2me.com/widget/crypto-carousel/v1"
        className="slider-again"
        frameborder="0"
        title="slider-again"
      ></iframe>
    </section>
  );
};

export default Converter;
