import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { createUser } from '../redux/actions/user.actions';

import Loader from './Loader';
import Message from './Message';

const CreateUser = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { loading, success, message, error } = useSelector(
    (state) => state.userCreate
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(createUser(name, username, password));
  };

  return (
    <Container fluid className='createUser py-5 mb-5'>
      <Row>
        <Col>
          <h3 className='text-center my-4  text-xl font-bold'>
            Create a new user
          </h3>
          {success && <Message variant='success'>{message.message}</Message>}
          {loading && <Loader />}
          {error && <Message variant='danger'>{error}</Message>}
          <div className='form-container'>
            <Form onSubmit={handleSubmit} className='form  flex flex-col gap-4'>
              <Form.Group controlId='formBasicName'>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  className='about-form'
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId='formBasicUsername'>
                <Form.Control
                  type='text'
                  placeholder='Username'
                  className='about-form'
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId='formBasicPassword'>
                <Form.Control
                  type='text'
                  placeholder='Password'
                  className='about-form'
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>

              <Button
                type='submit'
                variant='outline-primary'
                className='d-block mx-auto'
              >
                Submit
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateUser;
