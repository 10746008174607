import axios from 'axios';

import { variables } from '../../data/variables';

import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_MAKE_ADMIN_REQUEST,
  USER_MAKE_ADMIN_SUCCESS,
  USER_MAKE_ADMIN_FAIL,
  USER_CREATE_USER_REQUEST,
  USER_CREATE_USER_SUCCESS,
  USER_CREATE_USER_FAIL,
  USER_GET_BY_ID_REQUEST,
  USER_GET_BY_ID_SUCCESS,
  USER_GET_BY_ID_FAIL,
  USER_CREATE_ALERT_REQUEST,
  USER_CREATE_ALERT_SUCCESS,
  USER_CREATE_ALERT_FAIL,
  USER_GET_TRANS_ALERTS_REQUEST,
  USER_GET_TRANS_ALERTS_SUCCESS,
  USER_GET_TRANS_ALERTS_FAIL,
  ADMIN_GET_TRANS_ALERTS_REQUEST,
  ADMIN_GET_TRANS_ALERTS_SUCCESS,
  ADMIN_GET_TRANS_ALERTS_FAIL,
  ADMIN_CREATE_TRANS_REQUEST,
  ADMIN_CREATE_TRANS_SUCCESS,
  ADMIN_CREATE_TRANS_FAIL,
  ADMIN_CREATE_TRANS_RESET,
  ADMIN_GET_TRANS_REQUEST,
  ADMIN_GET_TRANS_SUCCESS,
  ADMIN_GET_TRANS_FAIL,
  MARK_TRANS_ALL_REQUEST,
  MARK_TRANS_ALL_SUCCESS,
  MARK_TRANS_ALL_FAIL,
  MARK_TRANS_PART_REQUEST,
  MARK_TRANS_PART_SUCCESS,
  MARK_TRANS_PART_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  CREATE_TRANS_FROMB_REQUEST,
  CREATE_TRANS_FROMB_SUCCESS,
  CREATE_TRANS_FROMB_FAIL,
  CREATE_BONUS_REQUEST,
  CREATE_BONUS_SUCCESS,
  CREATE_BONUS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PICTURE_REQUEST,
  USER_UPDATE_PICTURE_SUCCESS,
  USER_UPDATE_PICTURE_FAIL,
  USER_WITHDRAW_REQUEST,
  USER_WITHDRAW_SUCCESS,
  USER_WITHDRAW_FAIL,
  GET_WITHDRAWALS_REQUEST,
  GET_WITHDRAWALS_SUCCESS,
  GET_WITHDRAWALS_FAIL,
  APPROVE_WITHDRAWAL_REQUEST,
  APPROVE_WITHDRAWAL_SUCCESS,
  APPROVE_WITHDRAWAL_FAIL,
  GET_TOTAL_PAID_REQUEST,
  GET_TOTAL_PAID_SUCCESS,
  GET_TOTAL_PAID_FAIL,
  ADD_WALLET_REQUEST,
  ADD_WALLET_FAIL,
  ADD_WALLET_SUCCESS,
  GET_WALLETS_FAIL,
  GET_WALLETS_REQUEST,
  GET_WALLETS_SUCCESS,
  GET_TRANS_REQUEST,
  GET_TRANS_SUCCESS,
  GET_TRANS_FAIL,
  USER_ID_UPDATE_REQUEST,
  USER_ID_UPDATE_SUCCESS,
  USER_ID_UPDATE_FAIL,
  USER_lOAN_REQUEST,
  USER_lOAN_SUCCESS,
  USER_lOAN_FAIL,
  USERS_PENDING_LOAN_REQUEST,
  USERS_PENDING_LOAN_SUCCESS,
  USERS_PENDING_LOAN_FAIL,
  USERS_APPROVE_LOAN_REQUEST,
  USERS_APPROVE_LOAN_SUCCESS,
  USERS_APPROVE_LOAN_FAIL,
  USERS_APPROVED_LOANS_REQUEST,
  USERS_APPROVED_LOANS_SUCCESS,
  USERS_APPROVED_LOANS_FAIL,
  USER_LOANS_REQUEST,
  USER_LOANS_SUCCESS,
  USER_LOANS_FAIL,
  RESEND_VERIFICATION_FAIL,
  RESEND_VERIFICATION_REQUEST,
  RESEND_VERIFICATION_SUCCESS,
  USER_VERIFY_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  KYC_VERIFICATION_FAIL,
  KYC_VERIFICATION_REQUEST,
  KYC_VERIFICATION_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_EDIT_DETAILS_FAIL,
  USER_EDIT_DETAILS_REQUEST,
  USER_EDIT_DETAILS_SUCCESS,
  ADMIN_GET_TRANS_CURRENT_REQUEST,
  ADMIN_GET_TRANS_CURRENT_SUCCESS,
  ADMIN_GET_TRANS_CURRENT_FAIL,
  ADMIN_TOPUP_TRANS_REQUEST,
  ADMIN_TOPUP_TRANS_SUCCESS,
  ADMIN_TOPUP_TRANS_RESET,
  ADMIN_TOPUP_TRANS_FAIL,
} from '../../redux/constants/user.constants';

export const registerActions =
  ({
    name,
    email,
    username,
    password,
    bitcoinWalletAddress,
    ethereumWalletAddress,
    dogecoinWalletAddress,
    litecoinWalletAddress,
    bitcoinCashWalletAddress,
    referree,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${variables.backendLink}/api/users/register/?ref=${referree}`,
        {
          name,
          email,
          username,
          password,
          bitcoinWalletAddress,
          ethereumWalletAddress,
          dogecoinWalletAddress,
          litecoinWalletAddress,
          bitcoinCashWalletAddress,
        },
        config
      );
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const loginActions = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(
      `${variables.backendLink}/api/users/login`,
      { username, password },
      config
    );
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    sessionStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logoutActions = () => async (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
  });

  sessionStorage.clear();
};

export const forgotPasswordActions = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(
      `${variables?.serverUrl}/api/users/forgot-password/${email}`,
      {},
      config
    );
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const resetPasswordActions = (password, token) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(
      `${variables?.serverUrl}/api/users/reset-password/${token}`,
      { password },
      config
    );
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const verifyActions = (token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_VERIFY_REQUEST,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(
      `${variables?.serverUrl}/api/users/verify/${token}`,
      {},
      config
    );
    dispatch({
      type: USER_VERIFY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_VERIFY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resendVerificationActions = (email) => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_VERIFICATION_REQUEST,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(
      `${variables?.serverUrl}/api/users/resend-verification/${email}`,
      {},
      config
    );
    dispatch({
      type: RESEND_VERIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESEND_VERIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUsers = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${variables.backendLink}/api/users/all/?pageNumber=${page}`,
      config
    );
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`${variables.backendLink}/api/users/${id}`, config);
    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const makeUserAdmin = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_MAKE_ADMIN_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    await axios.put(
      `${variables.backendLink}/api/users/${id}/make-admin`,
      {},
      config
    );
    dispatch({
      type: USER_MAKE_ADMIN_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_MAKE_ADMIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createUser =
  (name, username, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CREATE_USER_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: ` Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${variables.backendLink}/api/users/create`,
        { username, name, password },
        config
      );

      dispatch({
        type: USER_CREATE_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_CREATE_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_GET_BY_ID_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${variables.backendLink}/api/users/${id}`,
      config
    );
    dispatch({
      type: USER_GET_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createTransAlert =
  (coin, percent, amount) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CREATE_ALERT_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: ` Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${variables.backendLink}/api/users/trans/create-alert`,
        { coin, percent, amount },
        config
      );

      dispatch({
        type: USER_CREATE_ALERT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_CREATE_ALERT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTransAlert = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_GET_TRANS_ALERTS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${variables.backendLink}/api/users/trans/alerts`,
      config
    );
    dispatch({
      type: USER_GET_TRANS_ALERTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_TRANS_ALERTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const adminGetTransAlert = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_GET_TRANS_ALERTS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${variables.backendLink}/api/users/trans/alerts/admin`,
      config
    );
    dispatch({
      type: ADMIN_GET_TRANS_ALERTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_TRANS_ALERTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const adminGetTransCurrent = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_GET_TRANS_CURRENT_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${variables.backendLink}/api/users/trans/current/admin`,
      config
    );
    dispatch({
      type: ADMIN_GET_TRANS_CURRENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_TRANS_CURRENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createTrans =
  (
    email,
    deposit,
    percent,
    name,
    username,
    user,
    coin,
    pending = null,
    _id = null
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_CREATE_TRANS_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: ` Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${variables.backendLink}/api/users/trans/create/?ref=${pending}`,
        { email, deposit, percent, name, username, user, coin, _id },
        config
      );
      dispatch({
        type: ADMIN_CREATE_TRANS_SUCCESS,
        payload: data,
      });

      setTimeout(() => {
        dispatch({
          type: ADMIN_CREATE_TRANS_RESET,
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: ADMIN_CREATE_TRANS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const topUpTrans =
  (
    email,
    deposit,
    percent,
    name,
    username,
    user,
    coin,
    pending = null,
    _id = null
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_TOPUP_TRANS_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: ` Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${variables.backendLink}/api/users/trans/top-up`,
        { email, deposit, percent, name, username, user, coin, _id },
        config
      );
      dispatch({
        type: ADMIN_TOPUP_TRANS_SUCCESS,
        payload: data,
      });

      setTimeout(() => {
        dispatch({
          type: ADMIN_TOPUP_TRANS_RESET,
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: ADMIN_TOPUP_TRANS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const adminGetTrans = (id, pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_GET_TRANS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.get(
      `${variables.backendLink}/api/users/trans/${id}/?pageNumber=${pageNumber}`,
      config
    );
    dispatch({
      type: ADMIN_GET_TRANS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_TRANS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const markTransAll = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MARK_TRANS_ALL_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.put(
      `${variables.backendLink}/api/users/trans/live/${id}`,
      {},
      config
    );
    dispatch({
      type: MARK_TRANS_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARK_TRANS_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const markTransPart = (id, part) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MARK_TRANS_PART_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(
      `${variables.backendLink}/api/users/trans/live/${id}/part`,
      { part },
      config
    );
    dispatch({
      type: MARK_TRANS_PART_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARK_TRANS_PART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userProfile = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: ` Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `${variables.backendLink}/api/users/user/profile`,
      config
    );
    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createTransFromB =
  (coin, percent, deposit) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_TRANS_FROMB_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: ` Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${variables.backendLink}/api/users/trans/create/from-balance`,
        { coin, percent, deposit },
        config
      );

      dispatch({
        type: CREATE_TRANS_FROMB_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_TRANS_FROMB_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const createBonus = (bonus, _id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_BONUS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${variables.backendLink}/api/users/trans/create/bonus`,
      { bonus, _id },
      config
    );

    dispatch({
      type: CREATE_BONUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_BONUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Update User Profile Details
export const updateUserProfile =
  (userDetails) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: ` Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${variables.backendLink}/api/users/update`,
        userDetails,
        config
      );
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//Update User PICTURE
export const updateUserPicture = (image) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PICTURE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const fData = new FormData();

    fData.append('image', image);

    const { data } = await axios.put(
      `${variables.backendLink}/api/users/picture`,
      fData,
      config
    );
    dispatch({
      type: USER_UPDATE_PICTURE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PICTURE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Request withdrawal
export const requestWithdrawal = (amount) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_WITHDRAW_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${variables.backendLink}/api/users/withdrawal`,
      { amount },
      config
    );
    dispatch({
      type: USER_WITHDRAW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_WITHDRAW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Get withdrawals
export const withdrawalRequests = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_WITHDRAWALS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${variables.backendLink}/api/users/withdrawals/all`,
      config
    );
    dispatch({
      type: GET_WITHDRAWALS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_WITHDRAWALS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//approve withdrawals
export const approveWithdrawalRequests = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPROVE_WITHDRAWAL_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${variables.backendLink}/api/users/withdrawals/all/${id}`,
      {},
      config
    );
    dispatch({
      type: APPROVE_WITHDRAWAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVE_WITHDRAWAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//approve withdrawals
export const getTotalPaid =
  (pageNumber = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_TOTAL_PAID_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${variables.backendLink}/api/users/transactions/top-paid/?pageNumber=${pageNumber}`,
        config
      );
      dispatch({
        type: GET_TOTAL_PAID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TOTAL_PAID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//ADD WALLET
export const addWallet = (walletId, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_WALLET_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${variables.backendLink}/api/users/wallet/add`,
      { walletId, password },
      config
    );
    dispatch({
      type: ADD_WALLET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_WALLET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
//GET WALLETS
export const getWallet = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_WALLETS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${variables.backendLink}/api/users/wallet/get`,
      config
    );
    dispatch({
      type: GET_WALLETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_WALLETS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//GET TRANS
export const getTrans = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TRANS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${variables.backendLink}/api/users/transaction/get`,
      config
    );
    dispatch({
      type: GET_TRANS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TRANS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateIdCard = (image) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ID_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const fData = new FormData();

    fData.append('image', image);

    const { data } = await axios.put(
      `${variables.backendLink}/api/users/loans/id`,
      fData,
      config
    );
    dispatch({
      type: USER_ID_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ID_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const requestLoan =
  ({ name, email, country, address, crypto, amount }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_lOAN_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: ` Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${variables.backendLink}/api/users/loans/request`,
        { name, email, country, address, crypto, amount },
        config
      );
      dispatch({
        type: USER_lOAN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_lOAN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const pendingLoanRequests = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USERS_PENDING_LOAN_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${variables.backendLink}/api/users/loans/pending/all/?pageNumber=${page}`,
      config
    );
    dispatch({
      type: USERS_PENDING_LOAN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USERS_PENDING_LOAN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const approveLoanRequests = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USERS_APPROVE_LOAN_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${variables.backendLink}/api/users/loans/approve/${id}`,
      {},
      config
    );
    dispatch({
      type: USERS_APPROVE_LOAN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USERS_APPROVE_LOAN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allApprovedLoans = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USERS_APPROVED_LOANS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${variables.backendLink}/api/users/loans/accepted/all/?pageNumber=${page}`,
      config
    );
    dispatch({
      type: USERS_APPROVED_LOANS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USERS_APPROVED_LOANS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allUserLoans = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOANS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${variables.backendLink}/api/users/loans/user/all`,
      config
    );
    dispatch({
      type: USER_LOANS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LOANS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Edit User Details
export const editUserDetailsActions =
  (details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_EDIT_DETAILS_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: ` Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${variables?.serverUrl}/api/users/profile`,
        details,
        config
      );
      dispatch({
        type: USER_EDIT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_EDIT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
// kyc
export const kycVerificationActions =
  (details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: KYC_VERIFICATION_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: ` Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${variables?.serverUrl}/api/users/kyc`,
        {},
        config
      );
      dispatch({
        type: KYC_VERIFICATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KYC_VERIFICATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//Get User Details
export const getUserDetailsActions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${variables?.serverUrl}/api/users/profile`,
      config
    );
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
