import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import { variables } from '../data/variables';

const Privacy = () => {
  return (
    <section className='py-10  bg-slate-600 text-slate-200'>
      <Container>
        <Header text='Disclaimer' />

        <div className='text-lg md:text-xl'>
          Disclaimer All the material published electronically on this site is
          for information purposes only. {variables.companyNameFull} solely
          adhere to its policy and not following them leads to automatic
          deactivating clients portfolio account. {variables.companyNameFull}{' '}
          does not accept any responsibility for loss or damage suffered by any
          person or body relying directly or indirectly on any information
          contained from external source. {variables.companyNameFull} does not
          accept any liability for any financial decisions made based on the
          information provided. This website does not constitute financial
          advice and should not be taken as such. {variables.companyNameFull}{' '}
          urges you to obtain professional advice, and consider your investment
          objectives, financial situation and individual needs before proceeding
          with any financial or investment decisions.
        </div>
      </Container>
    </section>
  );
};

export default Privacy;
