import React from 'react';
import { Outlet } from 'react-router-dom';
import AppFooter from './AppFooter';
import AppNavBar from './AppNavBar';

const Layout = () => {
  return (
    <>
      <AppNavBar />
      <main className='min-h-[80vh]'>
        <Outlet />
      </main>
      <AppFooter />
    </>
  );
};

export default Layout;
