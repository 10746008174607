import React from 'react';
import { Form, Nav, Container } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { variables } from '../data/variables';
import { certificate, logoCrop } from '../images';

const AppFooter = () => {
  return (
    <footer className='text-slate-200 bg-slate-600 py-6'>
      <Container className='flex flex-wrap gap-4 footer py-3 px-3' fluid>
        <div className='flex gap-3 flex-col justify-center'>
          <LinkContainer to='/'>
            <div>
              <img
                src={logoCrop}
                alt='Fathom trade ltd logo'
                width={200}
                height={100}
                className=' object-contain max-h-[80px]'
              />
              <div className='text-slate-400 text-sm text-center font-bold'>
                Fathom trade limited
              </div>
            </div>
          </LinkContainer>
          <div className='w-full max-w-[400px]'>
            We specialize in trading and managing accounts and funds for her
            investors; ensuring that investors get their promised returns on
            investment. A few years ago the platform specially traded for a few
            employed members of the sponsor organisations and kept pushing to
            take it global.
          </div>
          <div>
            <p>
              <strong>Talk to an expert</strong>
            </p>
            <p>{variables.phone}</p>
          </div>
        </div>
        <div>
          <h2 className='font-bold text-xl py-3 underline'>Useful Links</h2>
          <div className='flex flex-wrap'>
            <Nav className='flex flex-col  font-medium'>
              <LinkContainer to='/'>
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/about'>
                <Nav.Link>About Us</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/service'>
                <Nav.Link>Service</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/pricing'>
                <Nav.Link>Pricing</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/contact'>
                <Nav.Link title='contact'>Contact</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className='flex flex-col  font-medium'>
              <LinkContainer to='/faq'>
                <Nav.Link>FAQ</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/terms'>
                <Nav.Link>Terms and Condition</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/referral'>
                <Nav.Link>Referral</Nav.Link>
              </LinkContainer>

              {/* <LinkContainer to='/security'>
                <Nav.Link>Security</Nav.Link>
              </LinkContainer> */}
              <LinkContainer to='/privacy'>
                <Nav.Link>Privacy Policy</Nav.Link>
              </LinkContainer>
            </Nav>
          </div>
        </div>
        <div className='min-h-[300px] max-h-[300px] w-full max-w-[200px] mt-6'>
          <img
            src={certificate}
            className='w-full  object-contain'
            alt='certificate'
          />
        </div>
        <div>
          <h2 className='font-bold text-xl py-3 underline'>Newsletter</h2>
          <Form>
            <Form.Group>
              <Form.Label>Get latest updates and offers</Form.Label>
              <div className='flex'>
                <Form.Control
                  placeholder='Your email address'
                  className='rounded-none'
                />
                <div className='bg-blue-900 px-2 flex justify-center items-center'>
                  <FaEnvelope color='white' size={30} />
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
      </Container>
      <hr />
      <p className='text-center py-4'>
        &copy; {new Date().getFullYear()} {variables.companyName} All Rights
        Reserved
      </p>
    </footer>
  );
};

export default AppFooter;
