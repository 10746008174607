import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { requestWithdrawal } from '../../../redux/actions/user.actions';
import Message from '../../../components/Message';
import Loader from '../../../components/Loader';
import Header from '../../../components/Header';

const Withdrawal = () => {
  const dispatch = useDispatch();

  const [amount, setAmount] = useState(0);

  const { loading, error, res, success } = useSelector(
    (state) => state.requestWithdrawal
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount === 0) {
      alert('Enter a valid Investment amount');
    } else {
      dispatch(requestWithdrawal(amount));
    }
  };

  useEffect(() => {
    if (success) {
      setAmount(0);
    }
  }, [success]);

  return (
    <Container className="deposit ">
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {success && <Message variant="success">{res.message}</Message>}

      <Header text="Request Withdrawal" />
      <div className="form-container my-5">
        <Form onSubmit={handleSubmit} className="form flex flex-col gap-4">
          <Form.Group controlId="formGridInvestmentAWith">
            <Form.Label>($) Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Amount"
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Choose Cryptocurrency to receive </Form.Label>
            <Form.Control as="select" defaultValue="Choose...">
              <option>Choose...</option>
              <option value="bitcoin">Bitcoin</option>
              <option value="litecoin">Litecoin</option>
              <option value="ethereum">Ethereum</option>
              <option value="doge">Dogecoin</option>
              <option value="btccash">Bitcoincash</option>
            </Form.Control>
          </Form.Group>
          <Button
            type="submit"
            size="lg"
            className="d-block mx-auto"
            variant="outline-primary"
          >
            Request Amount
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default Withdrawal;
