import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const Plans = () => {
  return (
    <div>
      <div className='flex flex-wrap justify-center gap-10 my-6'>
        <article className='flex flex-col gap-4 w-full max-w-[400px] shadow-2xl px-4 py-4 rounded-3xl bg-slate-300 '>
          <h3 className='text-center text-xl md:text-2xl py-3 text-slate-800 font-medium'>
            Beginner
          </h3>
          <hr className='border-4' />
          <div className='flex flex-col gap-3'>
            <div className='flex items-center justify-between'>
              <div className='flex gap-2'>
                <FaCheckCircle />
                Duration
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                90 Days
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex  gap-2'>
                <FaCheckCircle />
                Daily Profit
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                0.9%
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex  gap-2'>
                <FaCheckCircle />
                Referral
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                6.0%
              </p>
            </div>
          </div>
          <hr className='border-4' />
          <div className='flex flex-col justify-center gap-3 items-center'>
            <h3 className='font-bold text-xl md:text-2xl'>$ 100 - $ 2,999</h3>
          </div>
        </article>

        <article className='flex flex-col gap-4 w-full max-w-[400px] shadow-2xl px-4 py-4 rounded-3xl bg-zinc-300 '>
          <h3 className='text-center text-xl md:text-2xl py-3 text-slate-800 font-medium'>
            Premium
          </h3>

          <hr className='border-4' />
          <div className='flex flex-col gap-3'>
            <div className='flex items-center justify-between'>
              <div className='flex gap-2'>
                <FaCheckCircle />
                Duration
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                90 Days
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex  gap-2'>
                <FaCheckCircle />
                Daily Profit
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                1.26%
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex  gap-2'>
                <FaCheckCircle />
                Referral
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                6.00%
              </p>
            </div>
          </div>
          <hr className='border-4' />
          <div className='flex flex-col justify-center gap-3 items-center'>
            <h3 className='font-bold text-xl md:text-2xl'>$ 3,000 - $ 9,999</h3>
          </div>
        </article>

        <article className='flex flex-col gap-4 w-full max-w-[400px] shadow-2xl px-4 py-4 rounded-3xl bg-stone-300 '>
          <h3 className='text-center text-xl md:text-2xl py-3 text-slate-800 font-medium'>
            SILVER
          </h3>

          <hr className='border-4' />
          <div className='flex flex-col gap-3'>
            <div className='flex items-center justify-between'>
              <div className='flex gap-2'>
                <FaCheckCircle />
                Duration
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                90 Days
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex  gap-2'>
                <FaCheckCircle />
                Daily Profit
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                1.46%
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex  gap-2'>
                <FaCheckCircle />
                Referral
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                6.00%
              </p>
            </div>
          </div>
          <hr className='border-4' />
          <div className='flex flex-col justify-center gap-3 items-center'>
            <h3 className='font-bold text-xl md:text-2xl'>
              $ 10,000 - $ 39,999
            </h3>
          </div>
        </article>

        <article className='flex flex-col gap-4 w-full max-w-[400px] shadow-2xl px-4 py-4 rounded-3xl bg-slate-200 '>
          <h3 className='text-center text-xl md:text-2xl py-3 text-slate-800 font-medium'>
            GOLD
          </h3>

          <hr className='border-4' />
          <div className='flex flex-col gap-3'>
            <div className='flex items-center justify-between'>
              <div className='flex gap-2'>
                <FaCheckCircle />
                Duration
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                90 Days
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex  gap-2'>
                <FaCheckCircle />
                Daily Profit
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                1.65%
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex  gap-2'>
                <FaCheckCircle />
                Referral
              </div>
              <p className='bg-slate-900 text-slate-200 p-1 rounded text-sm'>
                6.00%
              </p>
            </div>
          </div>
          <hr className='border-4' />
          <div className='flex flex-col justify-center gap-3 items-center'>
            <h3 className='font-bold text-xl md:text-2xl'>
              $ 40,000 - $ Unlimited
            </h3>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Plans;
