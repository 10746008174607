export const percentHelper = (percent) => {
  return percent === 1.03
    ? "Plan 1 - 3%"
    : percent === 1.05
    ? "Plan 2 - 5%"
    : percent === 1.1
    ? "Plan 3 - 10%"
    : percent === 1.15
    ? "Plan 4 - 15%"
    : 0;
};
