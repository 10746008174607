import React, { useEffect, useState } from 'react';

import { AiOutlineHome, AiOutlineEllipsis } from 'react-icons/ai';

import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  FaAnchor,
  FaBook,
  FaBookmark,
  FaEye,
  FaFileContract,
  FaIdCard,
  FaKey,
  FaMicrophoneAlt,
  FaMoneyBillWave,
  FaPen,
  FaSignOutAlt,
  FaTicketAlt,
  FaTrophy,
  FaWallet,
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { logoutActions } from '../../redux/actions/user.actions';

const SideBarMenu = ({ showSideBarMenu }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentUrl, setCurrentUrl] = useState('/dashboard');

  useEffect(() => {
    setCurrentUrl(location.pathname);
  }, [location]);

  return (
    <aside
      className={` fixed sm:relative bg-slate-900 text-sm py-16 text-slate-300 overflow-y-auto h-full z-50  ${
        showSideBarMenu ? 'w-full  sm:w-72' : 'w-0  hidden sm:block sm:w-20'
      }`}
    >
      <div className=' flex flex-col gap-3 border-b border-b-slate-500 py-3'>
        <Header text='MAIN' />
        <Link
          to='dashboard'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard' && ' text-white'
          }`}
        >
          <span>
            <AiOutlineHome size={18} />
          </span>
          {showSideBarMenu && <span>Dashboard</span>}
          {!showSideBarMenu && (
            <span>
              <AiOutlineEllipsis size={18} />
            </span>
          )}
        </Link>
      </div>

      <div className=' flex flex-col gap-3 border-b border-b-slate-500 py-3'>
        <Header text='USER' />
        <Link
          to='/dashboard/edit-details'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/edit-details' && ' text-white'
          }`}
        >
          <span>
            <FaPen size={16} />
          </span>
          {showSideBarMenu && <span>Edit Details</span>}
        </Link>
        {/* <Link
          to='/dashboard/view-details'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/view-details' && ' text-white'
          }`}
        >
          <span>
            <FaEye size={16} />
          </span>
          {showSideBarMenu && <span>View Details</span>}
        </Link> */}
        {/* <Link
          to='/dashboard/kyc-verification'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/kyc-verification' && ' text-white'
          }`}
        >
          <span>
            <FaBookmark size={16} />
          </span>
          {showSideBarMenu && <span>KYC Verification</span>}
        </Link> */}
      </div>

      <div className=' flex flex-col gap-3 border-b border-b-slate-500 py-3'>
        <Header text='TRANSACTIONS' />
        <Link
          to='/dashboard/plans'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/plans' && ' text-white'
          }`}
        >
          <span>
            <FaFileContract size={16} />
          </span>
          {showSideBarMenu && <span>Plans</span>}
        </Link>
      </div>

      <div className=' flex flex-col gap-3 border-b border-b-slate-500 py-3'>
        <Header text='WALLET' />
        <Link
          to='/dashboard/deposit-in-wallet'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/deposit-in-wallet' && ' text-white'
          }`}
        >
          <span>
            <FaWallet size={16} />
          </span>
          {showSideBarMenu && <span>Deposit in Wallet</span>}
        </Link>
        <Link
          to='/dashboard/deposit-history'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/deposit-history' && ' text-white'
          }`}
        >
          <span>
            <FaBook size={16} />
          </span>
          {showSideBarMenu && <span>Deposit History</span>}
        </Link>
        <Link
          to='/dashboard/add-wallet'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/add-wallet' && ' text-white'
          }`}
        >
          <span>
            <FaWallet size={16} />
          </span>
          {showSideBarMenu && <span>Add Wallet</span>}
        </Link>
      </div>

      <div className=' flex flex-col gap-3 border-b border-b-slate-500 py-3'>
        <Header text='INVESTMENT' />
        <Link
          to='/dashboard/new-investment'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/new-investment' && ' text-white'
          }`}
        >
          <span>
            <FaAnchor size={16} />
          </span>
          {showSideBarMenu && <span>New Investment</span>}
        </Link>
        <Link
          to='/dashboard/investment-history'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/investment-history' && ' text-white'
          }`}
        >
          <span>
            <FaBookmark size={16} />
          </span>
          {showSideBarMenu && <span>Investment History</span>}
        </Link>
        <Link
          to='/dashboard/investment-profit'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/investment-profit' && ' text-white'
          }`}
        >
          <span>
            <FaTrophy size={16} />
          </span>
          {showSideBarMenu && <span>Investment Profit</span>}
        </Link>
      </div>

      <div className=' flex flex-col gap-3 border-b border-b-slate-500 py-3'>
        <Header text='WITHDRAWAL' />
        <Link
          to='/dashboard/new-withdrawal-request'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/new-withdrawal-request' && ' text-white'
          }`}
        >
          <span>
            <FaMoneyBillWave size={16} />
          </span>
          {showSideBarMenu && <span>New Withdrawal Request</span>}
        </Link>
        {/* <Link
          to='/dashboard/withdrawal-history'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/withdrawal-history' && ' text-white'
          }`}
        >
          <span>
            <FaWallet size={16} />
          </span>
          {showSideBarMenu && <span>Withdrawal History</span>}
        </Link> */}
      </div>
      {/*
      <div className=' flex flex-col gap-3 border-b border-b-slate-500 py-3'>
        <Header text='SUPPORT' />
        <Link
          to='/dashboard/create-ticket'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/create-ticket' && ' text-white'
          }`}
        >
          <span>
            <FaTicketAlt size={16} />
          </span>
          {showSideBarMenu && <span>Create Ticket</span>}
        </Link>
        <Link
          to='/dashboard/all-tickets'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/all-tickets' && ' text-white'
          }`}
        >
          <span>
            <FaIdCard size={16} />
          </span>
          {showSideBarMenu && <span>All Tickets</span>}
        </Link>
        <Link
          to='/dashboard/announcements'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/announcements' && ' text-white'
          }`}
        >
          <span>
            <FaMicrophoneAlt size={16} />
          </span>
          {showSideBarMenu && <span>Anouncements</span>}
        </Link>
      </div> */}

      <div className=' flex flex-col gap-3 border-b border-b-slate-500 py-3'>
        <Header text='SETTINGS' />
        {/* <Link
          to='/dashboard/change-password'
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white ${
            currentUrl === '/dashboard/change-password' && ' text-white'
          }`}
        >
          <span>
            <FaKey size={16} />
          </span>
          {showSideBarMenu && <span>Change Password</span>}
        </Link> */}
        <button
          className={`flex gap-2 items-center whitespace-nowrap  px-6 hover:text-white text-red-500`}
          onClick={() => dispatch(logoutActions())}
        >
          <span>
            <FaSignOutAlt size={16} />
          </span>
          {showSideBarMenu && <span>Logout</span>}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </button>
      </div>
    </aside>
  );
};

const Header = ({ text }) => {
  return (
    <h2 className='text-[12px]  font-bold ml-6 w-[70%] truncate overflow-hidden'>
      {text}
    </h2>
  );
};

export default SideBarMenu;
