export const variables = {
  phone: '+447451277368',
  companyName: 'Fathomtradedltd.com',
  companyNameFull: 'Fathom trade limited',
  address:
    'The White House, 2 Meadrow, Godalming, Surrey, United Kingdom, GU7 3HN',
  email: 'admin@fathomtradeltd.com',

  // 
  // backendLink: 'http://localhost:4000',
  // serverUrl: 'http://localhost:4000',
  // backendLink: 'https://backend-c9dv.onrender.com',
  // serverUrl: 'https://backend-c9dv.onrender.com',
  backendLink: 'https://backend-1-7clp.onrender.com',
  serverUrl: 'https://backend-1-7clp.onrender.com',
  clientUrl: 'https://www.fathomtradeltd.com',

  // bitcoinWallet: '13vyqBPR2Y1eKwS3bXfYHe9gAcf2LwtwWT', // old
  bitcoinWallet: 'bc1qp4uhnejjtnunfxtwutd7hzg4c5e5ql7lgqgpse',
  // litecoinWallet:
  //   'LoremipsumdolorsitametconsecteturadipisicingelitSimiliqueobcaecati.',
  // ethereumWallet: '0x2cc74a217bc08b7c8adc0c39d8bb634e676c0828', // old
  ethereumWallet: '0x9962093d9c1f9E636aCcE7353EcCaEdf87b5ABA4',
  dogecoinWallet:
    'DToLPu94zoJFnsX5Yrm8S9vG4BgST8wE7h',
  bitcoincashWallet: '13vyqBPR2Y1eKwS3bXfYHe9gAcf2LwtwWT',

  // usdt
  // USDT: '0x2cc74a217bc08b7c8adc0c39d8bb634e676c0828',
  USDT: '0x9962093d9c1f9E636aCcE7353EcCaEdf87b5ABA4',
  tronWallet: 'TLv7c9hb4pxwVVne2q2rFmtqVm9mTgt9ge',

  plan: [
    {
      name: 'Plan 1',
      returnBal: '10% Roi in 24 Hours',
      ret: '1',
      minimum: 'Minumum - $200',
      maximum: 'Maximum - $2,999',
    },
    {
      name: 'Plan 2',
      returnBal: '15% Roi in 72 Hours',
      ret: '3',
      minimum: 'Minumum - $3000',
      maximum: 'Maximum - $9,999',
    },
    {
      name: 'Plan 3',
      returnBal: '35% Roi in 6 Days',
      ret: '6',
      minimum: 'Minumum - $10000',
      maximum: 'Maximum - $99,999',
    },
    {
      name: 'Plan 4',
      returnBal: '40% Roi in  30 Days',
      ret: '30',
      minimum: 'Minumum - $10000',
      maximum: 'Maximum - $500,000',
    },
  ],
};

export const testimonials = [
  {
    imageUrl: 'https://randomuser.me/api/portraits/women/61.jpg',
    text: 'Flex Market Finance is the best investment brooker in UK. Oh! So Amazing!!!.',
  },
  {
    text: "I can't believe my eyes. Got paid in just 72 hours of my investment. 10% return secured.",
    imageUrl: 'https://randomuser.me/api/portraits/women/25.jpg',
  },
  {
    text: "I thought that this wasn't real but my trail has proven them t be real. Thanks to the founder of FMF",
    imageUrl: 'https://randomuser.me/api/portraits/men/76.jpg',
  },
  {
    text: 'I am so happy. Flex market paid my referals... They are so reliable.',
    imageUrl: 'https://randomuser.me/api/portraits/men/23.jpg',
  },
  {
    text: "My family didn't believe me but look at what FMF has done for me",
    imageUrl: 'https://randomuser.me/api/portraits/men/73.jpg',
  },
  {
    text: 'A trial will prove your thoughts wrong. Flex market finance is a place to be.',
    imageUrl: 'https://randomuser.me/api/portraits/men/51.jpg',
  },
  {
    text: "I bought my wife a gucci bag after I received my returns. I can't thank Flex market finance enough.",
    imageUrl: 'https://randomuser.me/api/portraits/men/27.jpg',
  },
  {
    text: 'I am now a car owner. I just got a mercedes from my profit.',
    imageUrl: 'https://randomuser.me/api/portraits/men/77.jpg',
  },
  {
    text: "It's amazing how I was able to buy my boyfriend a car... Thanks to Flex market finance.",
    imageUrl: 'https://randomuser.me/api/portraits/women/59.jpg',
  },
];
