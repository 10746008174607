import React from 'react';
import Featured from '../components/HomeComponents/Featured';
import Features from '../components/HomeComponents/Features';
import ServiceSub from '../components/HomeComponents/ServiceSub';

const Services = () => {
  return (
    <section className='py-10'>
      <ServiceSub />
      <Featured />
      <Features />
    </section>
  );
};

export default Services;
