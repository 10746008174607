import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import { variables } from '../data/variables';

const ExplainAffiliate = () => {
  return (
    <section className='py-10 bg-slate-100'>
      <Container>
        <Header text='Affiliate' />
        <div>
          <h2 className='text-xl md:text-2xl py-3 text-slate-800 font-medium'>
            How Referral Works
          </h2>
          <div>
            <h2 className='text-xl md:text-2xl py-1 text-slate-700'>
              Refer Friends
            </h2>
            <p className='text-base md:text-lg py-1 text-slate-600'>
              Share your referral link or QR code with your friends and social
              media.
            </p>
          </div>
          <div>
            <h3 className='text-xl md:text-2xl pt-4 py-1 text-slate-700'>
              Earn Crypto
            </h3>
            <p className='text-base md:text-lg py-1 text-slate-600'>
              Earn up to 5% commission when your friends start trading.
            </p>
            <p className='text-base md:text-lg py-1 text-slate-600'>
              {variables.companyNameFull} has upgraded our Referral Program!
              Invite your friends to register and trade on{' '}
              {variables.companyName}, and you and your friends can share up to
              10%-5% of the referral commissions from their trading funds.
            </p>
          </div>
          <div>
            <h3 className='text-xl md:text-2xl pt-4 py-1 text-slate-600'>
              Rules
            </h3>
            <p className='text-base md:text-lg py-1 text-slate-600'>
              has upgraded our Referral Program! Invite your friends to register
              and trade on {variables?.companyName}, and you and your friends
              can share up to 10%-5% of the referral commissions from their
              trading funds.
            </p>

            <div>
              <ul>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  If the inviter&apos;s daily average Trading fund&apos;s
                  account balance is less than $10,000 and their base referral
                  rate is 5%, they can choose to share 0%, 3% or 4% with the
                  friends they invite.
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  If the inviter&apos;s daily average trading account balance is
                  $20,000 or more, their base referral rate is increased to 10%
                  and they can choose to share 0%, 3%, 5%, 7% or 8% with the
                  friends they invite.
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  Daily average Trading Balance Calculation Rules
                </li>
              </ul>
            </div>

            <p className='text-base md:text-lg py-1 text-slate-600 '>
              Effective as you register and start referring.
            </p>
            <div>
              <ol>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  Get Link
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  Invite Friends
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  Earn Crypto
                </li>
              </ol>
            </div>
          </div>
          <div>
            <h3 className='text-xl md:text-2xl pt-4 py-1 text-slate-600'>
              Details:
            </h3>
            <div>
              <ol>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  All referral commissions (both those received by inviters and
                  those shared with their invited friends) are calculated in
                  real-time and transferred to the respective{' '}
                  {variables.companyNameFull} accounts every time of fresh
                  deposit.
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  The settlement assets of referral commissions are the same as
                  the commission fee generated by the actual transaction of the
                  invited friend (invitee). If the invitee uses any
                  cryptocurrency, the settlement assets of referral commissions
                  and referral commissions returned are Cryptocurrency.
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  Referral rates are calculated anytime of fresh deposit.
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  In order for an invited friend to receive a kickback, they
                  must register using the corresponding referral link (or QR
                  code or referral ID). If they register using the old ID code
                  of the person who invited them, instead of via the link, they
                  will not receive any of the commission fee kickback offered to
                  them by their inviter.
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  There is no limit to the number of friends a single account
                  can invite.
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  If the inviter's daily average Trading balance is less than
                  $100 their base referral rate will be reduced from 5% to 3%.
                  At this time, the percentage allotted to the invited friend
                  will be given priority, and his/her rate will remain
                  unchanged. For instance, if the inviter's referral rate is
                  reduced to 3% at this time, the invited friend will continue
                  to maintain a normal return rate, and the inviter's referral
                  rate will be reduced to 3%.
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  Sub-accounts and margin accounts will take on the same
                  percentage rules that apply to the spot account. For instance,
                  the inviter will benefit from referring the invited
                  friend&apos;s sub-account and margin account. The invited
                  friend&apos;s sub-account and margin account will also enjoy
                  the same rate he/she would get for a spot account. In the
                  current set-up, the referral rate for the invited friend will
                  be directly settled to the corresponding sub-account or margin
                  account;
                </li>
                <li className='text-base md:text-lg py-1 text-slate-600 list-disc'>
                  {variables.companyNameFull} may adjust the proportion of
                  referrals at any point and reserves the right to adjust the
                  rules of the referral program.
                </li>
              </ol>
            </div>
          </div>
          <div>
            <h3 className='text-xl md:text-2xl pt-4 py-1 text-slate-600'>
              Notice:
            </h3>
            <p className='text-base md:text-lg py-1 text-slate-600 '>
              {variables.companyNameFull} reserves the right to change the terms
              of the referral program at any time due to changing market
              conditions, risk of fraud, or any other factors we deem relevant.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ExplainAffiliate;
