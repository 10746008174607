import React from 'react';
import { Container } from 'react-bootstrap';
import MarketChart from '../components/HomeComponents/MarketChart';
import Advanced from '../components/Trading/Advanced';
import Bitcoin from '../components/Trading/Bitcoin';
import Ethereum from '../components/Trading/Ethereum';
import Litecoin from '../components/Trading/Litecoin';

const Trading = () => {
  return (
    <section>
      <div className='py-10 bg-slate-200'>
        <Container>
          <h2 className='text-xl md:text-3xl font-bold py-6'>
            Crypto currency live prices
          </h2>
          <div className='flex flex-wrap gap-6 '>
            <Ethereum />
            <Bitcoin />
            <Litecoin />
          </div>
          <br />
          <hr />
        </Container>
      </div>
      <div>
        <Container className='py-8'>
          <h2 className='text-xl md:text-3xl font-bold py-6'>
            Advanced real-time
          </h2>
          <Advanced />
        </Container>
      </div>
      <MarketChart />
    </section>
  );
};

export default Trading;
