import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='flex justify-center items-center min-h-screen flex-col'>
      <h1 className='text-2xl my-6'>Page Not found</h1>
      <Link
        to='/'
        className='border border-slate-400 px-6 py-3 text-blue-600 bg-slate-200'
      >
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFound;
