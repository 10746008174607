import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaLock, FaKey } from 'react-icons/fa';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { logoCrop } from '../images';

const AppNavBar = () => {
  // Google Translate
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      'google_translate_element'
    );
  };

  // google translate
  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  // end of google translate
  return (
    <div>
      <div className=' bg-blue-800  text-slate-200 sm:bg-slate-600 py-3'>
        <Container className='flex flex-col sm:flex-row gap-2 items-center justify-between'>
          <div className='flex gap-4'>
            <Link
              to='/login'
              className='flex gap-2 items-center hover:text-black'
            >
              <FaLock />
              <span className='font-medium'>Login</span>
            </Link>
            <Link
              to='/register'
              className='flex gap-2 items-center hover:text-black'
            >
              <FaKey />
              <span className='font-medium'>Register</span>
            </Link>
          </div>
          <div className='max-w-[150px] border h-[25px] overflow-hidden cursor-pointer bg-white outline-none'>
            <div id='google_translate_element'></div>
          </div>
        </Container>
      </div>
      <Navbar
        bg='dark'
        expand='lg'
        variant='dark'
        sticky='top'
        collapseOnSelect
        className='my-navbar'
      >
        <Container>
          <Navbar.Brand className='cursor-pointer'>
            <LinkContainer to='/' className='w-fit'>
              <div>
                <img
                  src={logoCrop}
                  alt='Fathom trade ltd logo'
                  width={200}
                  height={100}
                  className=' object-contain max-h-[80px]'
                />
                <div className='text-slate-400 text-sm text-center font-bold'>
                  Fathom trade limited
                </div>
              </div>
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav'
            className='mx-3'
          />
          <Navbar.Collapse
            id='responsive-navbar-nav'
            className=' flex justify-end'
          >
            <Nav className='flex  sm:justify-end w-full font-medium'>
              <LinkContainer to='/'>
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/about'>
                <Nav.Link>About Us</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/service'>
                <Nav.Link>Service</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/investment-plan'>
                <Nav.Link>Investment Plan</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/trading'>
                <Nav.Link title='trading'>Trading</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/affiliate'>
                <Nav.Link title='affiliate'>Affiliate</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/contact'>
                <Nav.Link title='contact'>Contact</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer to='/account'>
                <Nav.Link title='account'>Account</Nav.Link>
              </LinkContainer> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNavBar;
