import React from 'react';
import { Container } from 'react-bootstrap';

const CompanyStatistic = () => {
  return (
    <section className='bg-slate-100 py-10'>
      <Container className='flex items-center flex-col'>
        <h2 className='text-lg sm:text-3xl font-bold text-blue-900 text-center'>
          Company Statistics
        </h2>

        <div className='font-light max-w-[500px] flex text-lg sm:text-xl my-2 mb-6'>
          Collaboratively administrate empowered markets via plug-and-play
          networks. Dynamically procrastinate B2C users after installed base
          benefits. Dramatically visualize customer di-rected convergence
          without revolutionary ROI.
        </div>
        <div className='flex gap-4 '>
          <div className='flex flex-col items-center justify-center py-4 text-center'>
            <h3 className='text-2xl sm:text-4xl'>15K+</h3>
            <div>Active Accounts</div>
          </div>
          <div className='flex flex-col items-center justify-center  py-4  text-center'>
            <h3 className='text-2xl sm:text-4xl'>97+</h3>
            <div>Countries Serve</div>
          </div>
          <div className='flex flex-col items-center justify-center  py-4 text-center'>
            <h3 className='text-2xl sm:text-4xl'>400+</h3>
            <div>Daily Transactions</div>
          </div>
          <div className='flex flex-col items-center justify-center  py-4 text-center'>
            <h3 className='text-2xl sm:text-4xl'>11k+</h3>
            <div>Daily Transactions</div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CompanyStatistic;
