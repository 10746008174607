import React from 'react';

const MarketChart = () => {
  return (
    <section>
      <h2 className="text-xl sm:text-3xl font-bold text-blue-900 text-center">
        Market Chart
      </h2>

      <div className="flex justify-center p-4">
        <iframe
          allowtransparency="true"
          frameBorder="0"
          src="https://www.tradingview-widget.com/embed-widget/crypto-mkt-screener/?locale=en#%7B%22width%22%3A1200%2C%22height%22%3A400%2C%22defaultColumn%22%3A%22overview%22%2C%22screener_type%22%3A%22crypto_mkt%22%2C%22displayCurrency%22%3A%22USD%22%2C%22colorTheme%22%3A%22dark%22%2C%22market%22%3A%22crypto%22%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22kingswayportfolioltd.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22cryptomktscreener%22%7D"
          title="market chart"
          className="h-[400px] w-full max-w-[1200px] mx-auto"
        ></iframe>
      </div>
    </section>
  );
};

export default MarketChart;
