import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { variables } from '../data/variables';

const Contact = () => {
  return (
    <section classNam='py-10 bg-slate-200'>
      <Container>
        <div className='flex flex-col sm:flex-row gap-10 w-full mx-auto py-10 justify-center'>
          <div className='w-full max-w-[600px]'>
            <h1 className='text-xl sm:text-2xl font-bold text-slate-600 py-3'>
              Get In touch with us
            </h1>

            <div>
              <Form>
                <Form.Group className='mb-3' controlId='contact-name'>
                  <Form.Control type='text' placeholder='Full Name' />
                </Form.Group>
                <Form.Group className='mb-3' controlId='contact-email'>
                  <Form.Control type='email' placeholder='Email address' />
                </Form.Group>
                <Form.Group className='mb-3' controlId='contact-phone'>
                  <Form.Control type='tell' placeholder='Phone number' />
                </Form.Group>
                <Form.Group className='mb-3' controlId='contact-message'>
                  <Form.Control as='textarea' placeholder='Message' rows={7} />
                </Form.Group>

                <Button
                  type='submit'
                  variant='primary'
                  size='lg'
                  className='bg-blue-700'
                >
                  SUBMIT NOW
                </Button>
              </Form>
            </div>
          </div>
          <div className='flex flex-col gap-4 bg-slate-100 px-6 py-4 w-full max-w-[400px] sm: mt-10'>
            <div>
              <h2 className='text-slate-800 text-xl md:text-2xl font-semibold'>
                Address:
              </h2>
              <p className='text-lg text-slate-700'>{variables.address}</p>
            </div>

            <div>
              <h2 className='text-slate-800 text-xl md:text-2xl font-semibold'>
                Phone:
              </h2>
              <p className='text-lg text-slate-700'>{variables.phone}</p>
            </div>

            <div>
              <h2 className='text-slate-800 text-xl md:text-2xl font-semibold'>
                Email:
              </h2>
              <p className='text-lg text-slate-700'>{variables.phone}</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
