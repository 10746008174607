import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import { variables } from '../data/variables';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const ResetPasswordScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (window.confirm('Are you sure your input is correct?')) {
      if (password !== password2) {
        setError(true);
        setMessage('Your passwords do not match.');
      } else {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        setLoading(true);
        await axios
          .post(
            `${variables.backendLink}/api/users/reset-password`,
            {
              password,
              token,
            },
            config
          )
          .then(function (response) {
            setLoading(false);
            setMessage(response.data.message);
            setSuccess(true);
          })
          .catch(function (error) {
            setLoading(false);
            setError(true);
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    setToken(location?.pathname?.split('rd/')[1]);
  }, [location.pathname]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>Reset Password - {variables.companyName}</title>
        <meta name='description' content='Reset Password page' />
      </Helmet>
      <Container
        fluid
        className='w-full max-w-[450px] m-auto mt-4 py-8 shadow-3xl bg-slate-200 px-4 rounded-[.25rem]'
      >
        <Row className='justify-content-center'>
          <Col className='pb-2 my-2' md={6}>
            <Header text='Reset Password' />
            <Form
              onSubmit={handleSubmit}
              className='my-5 center-form flex flex-col gap-4'
            >
              {success && <Message variant='success'>{message}</Message>}
              {error && <Message variant='danger'>{message}</Message>}
              <Form.Group controlId='formBasicPassword'>
                <Form.Control
                  type='password'
                  placeholder='Enter password'
                  className='about-form'
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId='formBasicPassword2'>
                <Form.Control
                  type='password'
                  placeholder='Confitm password'
                  className='about-form'
                  onChange={(e) => setPassword2(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                type='submit'
                variant='outline-primary'
                className=' d-block mx-auto mt-2'
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPasswordScreen;
