import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  registerActions,
  resendVerificationActions,
} from '../redux/actions/user.actions';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';
import { variables } from '../data/variables';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [message, setMessage] = useState(null);
  const [referralId, setReferralId] = useState('');

  const ref = location?.search.split('=')[1];

  const dispatch = useDispatch();

  const { loading, error, success, userInfo } = useSelector(
    (state) => state.registerUser
  );

  const {
    loading: loadingResend,
    error: errorResend,
    success: successResend,
    userInfo: userInfoResend,
  } = useSelector((state) => state.resendVerification);

  useEffect(() => {
    let userInfoFromStorage = sessionStorage.getItem('userInfo')
      ? JSON.parse(sessionStorage.getItem('userInfo'))
      : null;
    if (userInfoFromStorage) {
      navigate('/dashboard');
    } else {
      if (location?.search) {
        setReferralId(ref);
      } else {
        setReferralId('');
      }
    }
  }, [navigate, userInfo, location?.search, ref, referralId]);

  const handleSubmit = (e) => {
    // console.log({ name, email, username, password, referralId });
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    } else {
      dispatch(
        registerActions({
          name,
          email,
          username,
          password,
          referree: referralId,
        })
      );
    }
  };

  const handleResend = (e) => {
    e.preventDefault();

    if (email) {
      dispatch(resendVerificationActions(email));
    }
  };

  useEffect(() => {
    if (successResend) {
      setEmail('');
    }
  }, [successResend]);

  useEffect(() => {
    if (success) {
      navigate('/login');
    }
  }, [success]);

  return (
    <section className='py-10 '>
      <Helmet>
        <title>Registration - {variables.companyName}</title>
        <meta name='description' content='Register' />
      </Helmet>
      <Container>
        <div className='w-full max-w-[450px] mx-auto shadow-3xl bg-slate-200 px-4'>
          <Header text='OPEN AN ACCOUNT' />
          {userInfo ? (
            <div className='min-h-[200px] py-4'>
              {userInfoResend || errorResend || loadingResend ? null : (
                <Message variant='success'>{userInfo?.message}</Message>
              )}

              {userInfoResend ? (
                <Message variant='success'>{userInfoResend?.message}</Message>
              ) : null}

              {errorResend && <Message variant='danger'>{errorResend}</Message>}
              {loadingResend && <Loader />}
              <Form onSubmit={handleResend} className='flex flex-col gap-4'>
                <Form.Text>Didn't receive a verification code?</Form.Text>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Control
                    type='email'
                    placeholder='Email address'
                    className='about-form'
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    value={email}
                  />
                </Form.Group>
                <Button
                  // variant='primary'
                  type='submit'
                  className='bg-blue-500'
                  disabled={loadingResend}
                >
                  {loadingResend ? <Loader /> : 'Resend'}
                </Button>
              </Form>
            </div>
          ) : (
            <Form onSubmit={handleSubmit} className='flex flex-col gap-4'>
              {message && <Message variant='danger'>{message}</Message>}
              {error && <Message variant='danger'>{error}</Message>}
              {loading && <Loader />}

              <Form.Text>
                You can leave this field empty if you don't have it
              </Form.Text>
              <Form.Group controlId='formBasicReferal'>
                <Form.Control
                  type='text'
                  placeholder='Referral id'
                  className='about-form'
                  onChange={(e) => setReferralId(e.target.value)}
                  value={referralId}
                />
              </Form.Group>
              <Form.Group controlId='formBasicName'>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  className='about-form'
                  onChange={(e) => setName(e.target.value)}
                  required
                  autoFocus
                />
              </Form.Group>
              <Form.Group controlId='formBasicUsername'>
                <Form.Control
                  type='text'
                  placeholder='Username'
                  className='about-form'
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmail'>
                <Form.Control
                  type='email'
                  placeholder='Email address'
                  className='about-form'
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId='formBasicPassword'>
                <Form.Control
                  type='password'
                  placeholder='Password'
                  className='about-form'
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId='confirmPassword'>
                <Form.Control
                  type='password'
                  placeholder='Confirm password'
                  className='about-form'
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Check
                type='checkbox'
                id='default-checkbox'
                label='I agree with
               Terms and Conditions'
                required
              />

              <Button
                // variant='primary'
                type='submit'
                className='bg-blue-500'
                disabled={loading}
              >
                {loading ? <Loader /> : 'Submit'}
              </Button>
              <p className='about-p  text-center my-2'>
                Already have an account?{' '}
                <Link
                  to='/login'
                  className='register-btn underline text-blue-600'
                >
                  Login
                </Link>
              </p>

              {/* <p className='about-p  text-center pb-5'>
                Did not receive a verification code?{' '}
                <Link
                  to='/request-verification'
                  className='register-btn underline text-blue-600'
                >
                  Request
                </Link>
              </p> */}
            </Form>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Register;
