// TradingViewWidget.js

import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

export default function Ethereum() {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById('tradingview_d24da') &&
        'TradingView' in window
      ) {
        new window.TradingView.widget({
          //   autosize: true,
          symbol: 'BITSTAMP:ETHUSD',
          interval: 'D',
          timezone: 'Etc/UTC',
          theme: 'dark',
          style: '1',
          locale: 'us',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          allow_symbol_change: true,
          calendar: true,
          container_id: 'tradingview_d24da',
          height: '200px',
          width: '100%',
        });
      }
    }
  }, []);

  return (
    <div className='tradingview-widget-container  w-full max-w-[300px] mx-auto'>
      <div id='tradingview_d24da' />
      <div className='tradingview-widget-copyright'>
        <a
          href='https://in.tradingview.com/symbols/ETHUSD/?exchange=BITSTAMP'
          rel='noopener noreferrer'
          target='_blank'
        >
          <span className='blue-text'>Ethereum chart</span>
        </a>{' '}
        by TradingView
      </div>
    </div>
  );
}
