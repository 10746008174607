import React from 'react';
import Header from '../components/Header';
import CompanyStatistic from '../components/HomeComponents/CompanyStatistic';
import MiniAbout from '../components/HomeComponents/MiniAbout';
import Team from '../components/Team';

const About = () => {
  return (
    <section className=' py-10'>
      {/* <div className='about-bg'> */}
        <Header />
      {/* </div> */}
      <MiniAbout />
      <CompanyStatistic />
      <Team />
    </section>
  );
};

export default About;
