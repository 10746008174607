import React from 'react';
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  listUsers,
  adminGetTrans,
  getTotalPaid,
  pendingLoanRequests,
  allApprovedLoans,
} from '../redux/actions/user.actions';

const Paginate = ({
  pages,
  page,
  usersList = false,
  adminUserTrans = false,
  top = false,
  pendingLoans = false,
  approvedLoan = false,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getUser);

  const handlePagination = (e) => {
    if (usersList) {
      dispatch(listUsers(e.target.innerText));
    }

    if (adminUserTrans) {
      dispatch(adminGetTrans(user.user._id, e.target.innerText));
    }

    if (top) {
      dispatch(getTotalPaid(e.target.innerText));
    }

    if (pendingLoans) {
      dispatch(pendingLoanRequests(e.target.innerText));
    }
    if (approvedLoan) {
      dispatch(allApprovedLoans(e.target.innerText));
    }
  };

  return (
    <div
      style={{
        width: '270px',
        overflowX: pages <= 8 ? 'hidden' : 'scroll',
      }}
      className='mx-auto my-4'
    >
      <div style={{ display: 'inline-flex', height: '40px' }} className='mb-2'>
        {pages > 1 && (
          <Pagination>
            {[...Array(pages).keys()].map((x) => {
              return (
                <Pagination.Item
                  key={x + 1}
                  active={x + 1 === page}
                  onClick={(e) => handlePagination(e)}
                >
                  {x + 1}
                </Pagination.Item>
              );
            })}
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default Paginate;
