import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { createTrans } from '../redux/actions/user.actions';

import { percentHelper } from '../utility/percentChecker';
import Loader from './Loader';
import Message from './Message';

const UserPendingTrans = ({ trans, loading }) => {
  const dispatch = useDispatch();

  const {
    loading: loadingC,
    success,
    res,
    error,
  } = useSelector((state) => state.createTrans);

  const handleClick = (
    email,
    deposit,
    percent,
    name,
    username,
    user,
    coin,
    _id
  ) => {
    const pending = 'pending';
    if (window.confirm('Are you want to approve this investment?')) {
      dispatch(
        createTrans(
          email,
          deposit,
          percent,
          name,
          username,
          user,
          coin,
          pending,
          _id
        )
      );
    }
  };

  return (
    <div className=" user-pending-trans ">
      {loading || loadingC ? (
        <Loader />
      ) : (
        <>
          {error && <Message variant="danger">{error}</Message>}
          {success && <Message variant="success">{res?.message}</Message>}
          <Table
            striped
            bordered
            hover
            responsive
            className="table-sm trans-alert"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>USERNAME</th>
                <th>DEPOSIT</th>
                <th>PLAN</th>
                <th>CREATED_DATE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {trans?.length > 0 &&
                trans?.map((tran, index) => {
                  const {
                    username,
                    deposit,
                    percent,
                    createdAt,
                    _id,
                    name,
                    user,
                    coin,
                    email,
                  } = tran;

                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{username}</td>
                      <td>{deposit}</td>
                      <td>{percentHelper(percent)}</td>
                      <td>{createdAt}</td>
                      <td>
                        <Button
                          onClick={() =>
                            handleClick(
                              email,
                              deposit,
                              percent,
                              name,
                              username,
                              user,
                              coin,
                              _id
                            )
                          }
                          variant="outline-warning"
                        >
                          Approve
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </>
      )}
      {trans?.length === 0 && (
        <Message variant="danger">No Pending investment at the moment.</Message>
      )}
    </div>
  );
};

export default UserPendingTrans;
