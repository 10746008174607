import { variables } from '../../data/variables';

export const faqData = [
  {
    header: 'How can I invest with ' + variables.companyName,
    paragraph: `To make a investment you must first become a member of ${variables.companyName}. Once you are signed up, you can make your first deposit. All deposits must be made through the Members Area. You can login using the member email and password.`,
  },
  {
    header: `How do I open my ${variables.companyName} Account?`,
    paragraph: `It's quite easy and convenient. Follow this <a href="https://${variables.companyName}/register">link</a>, fill in the registration form and then press "Secure Sign Up".`,
  },
  {
    header: `Which e-currencies do you accept? `,
    paragraph: `We accept Bitcoin, Ethereum and Bitcoin Cash`,
  },
  {
    header: `How long does it take for my funds to be added to my wallet?`,
    paragraph: `Your account will be updated as fast, as your payment is confirmed..`,
  },
  {
    header: `When will the Wallet Funding should be activated?`,
    paragraph: `When will the Wallet Funding should be activated?`,
  },
  {
    header: `May I have several deposits at the same time?`,
    paragraph: `Yes, sure you may have as many deposits as you want.`,
  },
  {
    header: `Do you have any limits for an investment amount?`,
    paragraph: `Yes, The limits are according to the plan selected.`,
  },
  {
    header: `How can I withdraw funds?`,
    paragraph: `Login to your account using your email and password and check the Withdraw section.`,
  },
  {
    header: `How fast will my withdrawal be processed?`,
    paragraph: `Withdrawal is automatic, the site pays automatically directly to your wallet once contract or duration terminates or ends.`,
  },
  {
    header: `Are there risks involved?`,
    paragraph: `Yes. There are definitely risks involved in every form of capital management.`,
  },
  {
    header: `What are the expected ROI?`,
    paragraph: `Expected ROIs on fully managed portfolios range from 15% monthly to 50% monthly.`,
  },
  {
    header: `How can I change my e-mail address or password?`,
    paragraph: `Log into your ${variables.companyName} account and click on the "Account Information". You can change your e-mail address and password there.`,
  },
  {
    header: `What if I can't log into my account because I forgot my password?`,
    paragraph: `Click forgot password link, type your e-mail and you'll receive your account information.`,
  },
  {
    header: `How do you calculate the interest on my account?`,
    paragraph: `Depending on each plan. Interest on your ${variables.companyName} account is acquired Daily, Weekly, Bi-Weekly, Monthly and Yearly and credited to your available balance at the end of each day.
    `,
  },
  {
    header: `Can I do a direct deposit from my account balance?`,
    paragraph: `Yes, all deposits can only be made directly from your wallet`,
  },
  {
    header: `Can I make an additional deposit to ${variables.companyName} account has been opened?`,
    paragraph: `Yes, you can but all transactions are handled separately.`,
  },
  {
    header: `Do you have a referral program?`,
    paragraph: `Yes and Our company offers Referral Commission.`,
  },
  {
    header: `Should I have an active deposit to participate in the Referral program`,
    paragraph: `No, it is not necessary.`,
  },
  {
    header: `How can I make easy withdraw?`,
    paragraph: `You can make easy withdraw once you are authorized by making at least 3 referral downline or pay 10% trade commission on withdraw amount.`,
  },
];
