import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import { variables } from '../data/variables';

const Security = () => {
  return (
    <section className='py-10 pb-16 text-slate-600'>
      <Container>
        <Header text='Security' />

        <div>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>
              AES-256 ENCRYPTION
            </h2>
            <h3>COLD STORAGE</h3>
            <p>95% of all funds are stored in offline cold wallets</p>
            <p className='py-2'>
              Distributed across the world in hyper secure facilities in
              locations that specialize in physically securing valuable items
              Multi-signature validation means that no one can unilaterally
              access funds. Not our CEO. Not the Queen of England Private keys
              don’t leave Hardware Security Modules where funds are stored, a
              cryptographic best practice Responsive Desing Icon
            </p>
          </article>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>
              AES-256 ENCRYPTION
            </h2>

            <p>Secure software on desktop and mobile</p>
            <p className='py-2'>
              The Wallet and Exchange both support 2FA, use AES-256 encryption,
              and private keys for custodied funds are stored in FIPS 140-2
              certified devices {variables.companyNameFull} enforces TLS 1.2 for
              Internet-facing applications and audits support cypher
              periodically Strong cyphers are used to enforce storage encryption
              across the cloud and physical facilities Bitcoin Code Icon
            </p>
          </article>

          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>PRIVATE KEYS</h2>

            <p>The most-used cryptographic software in the world</p>
            <p className='py-2'>
              Client-side private key management is not unique to{' '}
              {variables.companyNameFull}, but at our scale is the most-used
              cryptographic tool in the hands of millions If you own your
              private keys, you own your crypto Zero knowledge proofs and
              private key management are the foundation of cryptocurrency Touch
              Id Lock Icon
            </p>
          </article>
          <article className='py-3'>
            <h2 className='font-medium text-xl md:text-2xl'>
              PRIVACY AND TRUST
            </h2>

            <p>We help you keep your crypto and your data secure</p>
            <p className='py-2'>
              We do not track non-custodial funds held in Private Key Wallets,
              we only keep track of funds in Accounts that are custodied by{' '}
              {variables.companyNameFull} All Personally Identifiable
              Information is stored in Google Cloud Platform, secured by best in
              class digital security. Our compliance team ensures that we follow
              and are compliant with AML and KYC regulations globally so that
              you can trust {variables.companyNameFull} and any counterparty Bug
              Bounty Logo Icon Bug bounty program
            </p>
          </article>

          <article className='py-4'>
            <h2 className='font-medium text-xl md:text-2xl'>
              Secure your {variables.companyNameFull} account
            </h2>
            <ul>
              <li className='list-disc'>Choose a unique password</li>
              {/* <li className="list-disc">Enable two-factor authentication</li> */}
              <li className='list-disc'>
                Write down your Secret Private Key Recovery Phrase, store
                offline, and don't share it with anyone
              </li>
              <li className='list-disc'>Swap - Exchanging Crypto</li>
              <li className='list-disc'>Wallet Functions & Security</li>
              <li className='list-disc'>Buying & Selling Crypto</li>
              <li className='list-disc'>
                Always follow security best practices
              </li>
            </ul>
          </article>

          <article>
            <h2 className='font-medium text-xl md:text-2xl'>
              Keep your information private
            </h2>

            <div>
              <ul>
                <li className='list-disc'>
                  Never give out your backup phrase, pairing code, or access to
                  your Wallet
                </li>
                <li className='list-disc'>
                  Protect your Secret Private Key Recovery Phrase
                </li>
                <li className='list-disc'>
                  Physically write down and secure your passwords
                </li>
                <li className='list-disc'>
                  Only reach out on verified channels
                </li>
                <li className='list-disc'>Twitter, Instagram, Medium</li>
                <li className='list-disc'>Bookmark our website</li>
                <li className='list-disc'>
                  Avoid clicking on links or attachments in suspicious emails
                </li>
              </ul>
            </div>
          </article>
        </div>
      </Container>
    </section>
  );
};

export default Security;
