import React from 'react';
import { Container } from 'react-bootstrap';
import { variables } from '../../data/variables';
import {
  fast,
  instant,
  recurring,
  referral,
  safe,
  support,
} from '../../images';

const list = [
  {
    image: safe,
    text: 'SAFE AND SECURE',
  },

  {
    image: instant,
    text: 'INSTANT TRADING',
  },
  {
    image: recurring,
    text: 'RECURRING PROFITS',
  },
  {
    image: fast,
    text: 'FAST PAYOUTS',
  },
  {
    image: support,
    text: '24/7 SUPPORT',
  },
  {
    image: referral,
    text: 'REFERRAL BONUS',
  },
];

const Features = () => {
  return (
    <section className='py-10 bg-slate-100'>
      <Container>
        <h2 className='text-xl sm:text-3xl font-bold text-blue-900 py-3'>
          Our Features
        </h2>
        <p className='text-lg md:text-xl text-slate-700'>
          Here at {variables.companyNameFull}, we offer equity guarantee and
          full capital insurance for each members funds
        </p>

        <div className='flex flex-wrap justify-center gap-2 py-10'>
          {list?.map((x) => {
            return (
              <div
                key={x.text}
                className='w-full max-w-[170px] h-[250px] flex items-center justify-center gap-4 border flex-col bg-white'
              >
                <div>
                  <img src={x.image} alt={x.text} />
                </div>
                <p>{x.text}</p>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Features;
