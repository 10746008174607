import React from 'react';
import { Container } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { person1, person2, person3, person4 } from '../../images';

const fadeImages = [
  {
    url: person1,
    caption:
      'I am impressed. This platform has the best customer support I have ever come accross',
  },
  {
    url: person2,
    caption:
      'Thanks to Gerald for introducing to this platform. I am so grateful',
  },
  {
    url: person3,
    caption:
      'The promptness and availability is what I enjoy on this platform. Payments are so fast',
  },
  {
    url: person4,
    caption:
      'Customer support is so amazing here. I will recommend it to my friends. ',
  },
];

const Testimonials = () => {
  return (
    <section className='py-10 bg-slate-400'>
      <Container>
        <h2 className='text-xl sm:text-3xl font-bold text-blue-900 py-3'>
          What our clients says
        </h2>
        <div>
          <Carousel
            // centerMode
            autoPlay
            className='w-full max-w-[450px] mx-auto'
            stopOnHover
            showStatus={false}
            renderIndicator={false}
            showThumbs={false}
          >
            {fadeImages.map((x) => {
              return (
                <div
                  key={x?.caption}
                  className='flex justify-center flex-col items-center gap-4'
                >
                  <div className='w-[150px] h-[150px] rounded-full '>
                    <img
                      src={x.url}
                      alt=''
                      className='w-full h-full rounded-full object-cover'
                    />
                  </div>
                  <div>{x?.caption}</div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </Container>
    </section>
  );
};

export default Testimonials;
